import { useNavigate, useParams } from 'react-router-dom';
import { NotificationTypes, useNotifications } from '../context/NotificationContext';
import { useSync } from '../context/SyncContext';
import { useTokens } from '../context/TokensContext';
import { Mixpanel } from '../Mixpanel';
import updateRemoteData from '../utils/updateRemoteData';
import useConfirmModal from './useConfirmModal';

export function useDeleteSet() {
  const { selectedTokenSet } = useParams();
  const { tokenData, setTokenData } = useTokens();
  const { addNotification } = useNotifications();
  const { pluginIsConnected, allowEdits } = useSync();
  const { confirm } = useConfirmModal();
  const navigate = useNavigate();

  const deleteSet = async (tokenSet: string) => {
    if (tokenData?.synced_data.sets) {
      if (!pluginIsConnected && !allowEdits) {
        addNotification(
          NotificationTypes.ERORR,
          'Cannot edit. Please make sure Tokens Studio plugin is opened and sync is turned on'
        );
      } else {
        const userConfirmation = await confirm({
          title: `Are you sure you want to delete '${tokenSet}' ?`,
          description: 'This will delete the set and tokens',
          cancelButtonText: 'Cancel',
          confirmButtonText: 'Delete',
          visible: true,
        });
        if (userConfirmation) {
          try {
            const newSets = tokenData.synced_data.sets;
            const isEmptySet = newSets[tokenSet].length === 0;
            delete newSets[tokenSet];

            const newData = {
              ...tokenData,
              synced_data: {
                ...tokenData.synced_data,
                sets: newSets,
              },
            };
            await updateRemoteData(newData);
            Mixpanel.track('Delete token set', { isEmptySet });

            setTokenData(newData);
            if (selectedTokenSet === tokenSet) {
              navigate('/');
            }
            addNotification(NotificationTypes.SUCCESS, 'Set was deleted succesfully');
          } catch (err) {
            const error = err as Error;
            addNotification(NotificationTypes.ERORR, error.message);
          }
        }
      }
    }
  };
  return { deleteSet };
}
