import React from 'react';
import Mentions from 'rc-mentions';
import Box from './Box';
import Flex from './Flex';
import './mentions.css';
import { TokenTypeOrProp } from '../../ft-utils/types/TokenTypeOrProp';

export interface SuggestionDataItem {
  id: string;
  display: string;
}

const { Option } = Mentions;
interface Props {
  name: string;
  placeholder: string;
  id?: string;
  data?: SuggestionDataItem[];
  tokenType: TokenTypeOrProp;
  autoFocus?: boolean;
}

export default function MentionsInput(props: Props) {
  const { name, placeholder, id, tokenType, data = [], autoFocus = false, ...rest } = props;

  return (
    <Flex css={{ width: '100%', gap: '$5', position: 'relative', padding: '$3' }}>
      <Box
        css={{
          flexGrow: 1,
        }}
      >
        <Mentions
          placeholder={placeholder}
          autoSize
          prefix={['{', '$']}
          placement="bottom"
          style={{ width: '100%' }}
          {...rest}
          autoFocus={autoFocus}
          spellCheck={false}
        >
          {data.map((itm) => (
            <Option key={(itm.id as string) || 'not-found'} value={itm.id as string} className="mentions-item">
              {itm.display}
            </Option>
          ))}
        </Mentions>
      </Box>
    </Flex>
  );
}
