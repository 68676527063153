import { useMemo } from 'react';
import { useTokensTableState } from '../context/TokensTableContext';
import useSelectedSetTokens from './useSelectedSetTokens';

export default () => {
  const { rowSelection } = useTokensTableState();
  const selectedSetTokens = useSelectedSetTokens();

  const selection = useMemo(() => Object.keys(rowSelection), [rowSelection]);

  return useMemo(
    () => selection.map((selectedTokenId) => selectedSetTokens?.find((tkn) => tkn.name === selectedTokenId)),
    [selection, selectedSetTokens]
  );
};
