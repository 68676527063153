import React, { useRef } from 'react';
import { SingleBoxShadowToken } from '../../../ft-utils/types/tokens';
import { getShadowTokenValues } from '../../../hooks/getShadowTokenValues';
import { Box } from '../../../shared/components';
import useResolvedTokenValue from '../../../hooks/useResolvedTokenValue';
import { TokenTypes } from '../../../ft-utils/constants/TokenTypes';
import { TokenBoxshadowValue } from '../../../ft-utils/types/values';
import useIsInView from '../../../hooks/useIsInView';

export default function BoxShadowResolvedValue(props: SingleBoxShadowToken) {
  const { value } = props;
  const tokenRef = useRef<HTMLDivElement>(null);
  const inView = useIsInView<HTMLDivElement>(tokenRef);

  const resolvedTokenValue =
    (useResolvedTokenValue(value, TokenTypes.BOX_SHADOW, inView) as TokenBoxshadowValue | TokenBoxshadowValue[]) ??
    value;

  const { resolvedValues } = getShadowTokenValues(resolvedTokenValue);
  return (
    <Box ref={tokenRef} css={{ padding: '$3 $5' }}>
      {resolvedValues?.map((val) => (
        <Box css={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} key={val}>
          {val}
        </Box>
      ))}
    </Box>
  );
}
