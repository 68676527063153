import React, { useRef } from 'react';
import { SingleBoxShadowToken } from '../../ft-utils/types/tokens';
import { TokenBoxshadowValue } from '../../ft-utils/types/values';
import useIsInView from '../../hooks/useIsInView';
import useResolvedTokenValue from '../../hooks/useResolvedTokenValue';
import { getShadowTokenValues } from '../../hooks/getShadowTokenValues';
import { Box, Flex } from '../../shared/components';
import RawValue from './partials/RawValue';
import ResolvedValueBox from './partials/ResolvedValue';
import TokenName from './partials/TokenName';
import TokenPreview from './partials/TokenPreview';

export default function ShadowToken(props: SingleBoxShadowToken) {
  const { name, value, type } = props;

  const tokenRef = useRef<HTMLDivElement>(null);
  const inView = useIsInView<HTMLDivElement>(tokenRef);

  const resolvedTokenValue = useResolvedTokenValue(value, type, inView) as TokenBoxshadowValue | TokenBoxshadowValue[];

  const { showRawValue, shadowRawValue, resolvedValues } = getShadowTokenValues(resolvedTokenValue);

  return (
    <>
      <TokenPreview>
        <Box
          ref={tokenRef}
          css={{
            width: '32px',
            height: '32px',
            border: '1px solid $borderMuted',
            background: '$bgSurface',
            marginBottom: '$5',
            boxShadow: resolvedValues?.reduce((acc, val, i) => `${acc} ${i > 0 ? ',' : ''} ${val.trim()}`, ''),
          }}
        />
      </TokenPreview>
      <Flex css={{ flexDirection: 'column', alignItems: 'flex-start', gap: '$3', overflow: 'hidden' }}>
        <TokenName name={name} />
        {showRawValue && typeof shadowRawValue === 'string' && <RawValue rawValue={shadowRawValue} />}
        {resolvedValues?.map((resolvedValue) => (
          <ResolvedValueBox key={`shadow-${name}-${resolvedValue}`} isAccent value={resolvedValue} />
        ))}
      </Flex>
    </>
  );
}
