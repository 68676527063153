import { MinusIcon, PlusIcon } from '@radix-ui/react-icons';
import { useMemo } from 'react';
import { useField, useFormState } from 'react-final-form';
import { IconButton } from '@tokens-studio/ui';
import { ColorModifierTypes } from '../../ft-utils/constants/ColorModifierTypes';
import { ColorSpaceTypes } from '../../ft-utils/constants/ColorSpaceTypes';
import { TokenTypes } from '../../ft-utils/constants/TokenTypes';
import { SingleToken } from '../../ft-utils/types/tokens';
import { Box, Flex } from '../../shared/components';
import ModifierInputs from './ModifierInputs';

export default function ColorModifier() {
  const { values } = useFormState<SingleToken>();

  const hasModifier = useMemo(() => !!values.$extensions?.['studio.tokens']?.modify, [values]);

  const { input } = useField('$extensions.[studio.tokens]');

  const toggleUseModifier = () => {
    if (hasModifier) {
      input.onChange({});
    } else {
      input.onChange({ modify: { type: ColorModifierTypes.LIGHTEN, space: ColorSpaceTypes.LCH } });
    }
  };

  if (values.type !== TokenTypes.COLOR) return null;

  return (
    <Box>
      <Flex css={{ justifyContent: 'space-between', padding: '$4 0' }}>
        <Box css={{ fontSize: '$base', fontWeight: '$sansBold', color: '$fgDefault' }}>Color modifier</Box>
        <IconButton
          variant="invisible"
          size="medium"
          tooltip={hasModifier ? 'Remove modifier' : 'Add modifier'}
          icon={hasModifier ? <MinusIcon /> : <PlusIcon />}
          onClick={toggleUseModifier}
        />
      </Flex>
      {hasModifier && <ModifierInputs />}
    </Box>
  );
}
