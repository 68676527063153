import { TokenData } from '../context/types';
import { supabase } from '../supabase';

export default async function updateActiveThemes(
  tokenState: TokenData,
  newActiveTheme: TokenData['synced_data']['activeTheme']
) {
  const newData: TokenData['synced_data'] = {
    ...tokenState.synced_data,
    activeTheme: newActiveTheme,
  };

  try {
    await supabase
      .from('tokens')
      .update({ synced_data: JSON.stringify(newData), last_updated_by: 'second-screen' })
      .eq('id', tokenState.id);
  } catch (error) {
    console.log(error);
  }
}
