import { Row } from '@tanstack/react-table';
import { SelectedCell } from '../../context/TokensTableContext';
import { TableTokenItem } from '../../ft-utils/types/TableTokenItem';

export default (
  columnSelection: SelectedCell[],
  sortedSubRows: Row<TableTokenItem>[],
  initialRowId: string,
  direction: number
) => {
  const columnType = columnSelection[0].colId;

  let newColSelection = structuredClone(columnSelection);
  const lastSelectedRowCell = newColSelection[newColSelection.length - 1];
  const { rowId: lastSelectedRowId } = lastSelectedRowCell;

  const currentRowIndex = sortedSubRows.findIndex(({ id }) => id === lastSelectedRowId);
  const selectionStart = sortedSubRows.findIndex((itm) => itm.id === initialRowId);

  let nextRowId = '';
  const nextRowIndex = currentRowIndex + direction;

  if (sortedSubRows[nextRowIndex]) {
    nextRowId = sortedSubRows[nextRowIndex].id;
  }

  // deselect last item if selection is above/below starting point
  if ((direction === 1 && nextRowIndex <= selectionStart) || (direction === -1 && nextRowIndex >= selectionStart)) {
    newColSelection = newColSelection.filter((itm) => {
      const { rowId } = itm;
      return rowId !== lastSelectedRowId;
    });
  }

  if (nextRowId) {
    const newSelectedCell = { colId: columnType, rowId: nextRowId } as SelectedCell;
    return [...newColSelection, newSelectedCell];
  }

  return newColSelection;
};
