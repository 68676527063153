import { RealtimeChannel } from '@supabase/supabase-js';
import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { UsedTokenSetsMap } from '../ft-utils/types';
import { supabase } from '../supabase';
import { useAuth } from './AuthContext';
import { Clients, Theme, TokenData, TokenSets } from './types';

interface TokensContextType {
  themes: Theme[] | undefined;
  sets: TokenSets | null;
  loading: boolean;
  tokenData: TokenData | null;
  activeTheme: Record<string, string>;
  usedTokenSets: UsedTokenSetsMap | null;
  setTokenData: React.Dispatch<React.SetStateAction<TokenData | null>>;
  setActiveTheme: React.Dispatch<React.SetStateAction<Record<string, string>>>;
}

export const defaultTokensState = {
  themes: [],
  sets: null,
  loading: false,
  activeTheme: {},
  tokenData: null,
  usedTokenSets: null,
  setTokenData: () => {},
  setActiveTheme: () => {},
};

const TokensContext = createContext<TokensContextType>(defaultTokensState);

function TokensContextProvider({ children }: { children: ReactNode }) {
  const { loggedUser } = useAuth();
  const [tokenData, setTokenData] = useState<TokenData | null>(null);
  const [fetching, setFetching] = useState(false);
  const [activeTheme, setActiveTheme] = useState<Record<string, string>>({});

  const userEmail = useMemo<string>(() => (loggedUser ? loggedUser.email : ''), [loggedUser]);

  useEffect(() => {
    let channel: RealtimeChannel | null = null;

    if (userEmail) {
      channel = supabase
        .channel('value-db-changes')
        .on(
          'postgres_changes',
          {
            event: '*',
            schema: 'public',
            table: 'tokens',
            filter: `owner_email=eq.${userEmail}`,
          },
          (payload) => {
            const updatedData = payload.new as TokenData;
            if (updatedData.last_updated_by !== Clients.SECOND_SCREEN) {
              if (JSON.stringify(updatedData.synced_data) !== JSON.stringify(tokenData?.synced_data)) {
                setTokenData(updatedData);
                setActiveTheme(updatedData.synced_data.activeTheme ?? {});
              }
            }
          }
        )
        .subscribe((status, err) => {
          if (err) {
            console.log(err);
          }
        });
    }

    return () => {
      if (channel) {
        supabase.removeChannel(channel);
      }
    };
  }, [userEmail, tokenData]);

  useEffect(() => {
    setFetching(true);
    async function getTokens() {
      if (userEmail) {
        const { data } = await supabase.from('tokens').select('*').eq('owner_email', userEmail);

        if (data && data[0] && data[0].synced_data) {
          const dbTokenData = {
            ...data[0],
            synced_data: JSON.parse(data[0].synced_data),
          } as TokenData;

          setTokenData(dbTokenData);
          if (dbTokenData.synced_data) {
            setActiveTheme(dbTokenData.synced_data.activeTheme ?? 'none');
          }

          setFetching(false);
        } else {
          const { data: dummyData } = await supabase.from('dummy').select('*');

          if (dummyData && dummyData[0]) {
            await supabase.from('tokens').insert({
              owner_email: userEmail,
              synced_data: dummyData[0].synced_data,
            });
          }
        }
      }
    }

    getTokens();
  }, [userEmail]);

  const sets = tokenData?.synced_data?.sets;
  const themes = tokenData?.synced_data?.themes;
  const usedTokenSets = tokenData?.synced_data?.usedTokenSets;

  const providerValue = useMemo(
    () => ({
      themes: themes ?? undefined,
      sets: sets ?? null,
      usedTokenSets: usedTokenSets || {},
      loading: fetching,
      activeTheme,

      tokenData,
      setTokenData,
      setActiveTheme,
    }),
    [themes, sets, usedTokenSets, fetching, activeTheme, tokenData, setActiveTheme]
  );

  return <TokensContext.Provider value={providerValue}>{children}</TokensContext.Provider>;
}

function useTokens() {
  const context = useContext(TokensContext);
  if (context === undefined) {
    throw new Error('useTokens must be used within a TokensContextProvider');
  }
  return context;
}

export { TokensContextProvider, useTokens };
