import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useUIState } from '../../context/UIState';
import { SingleToken } from '../../ft-utils/types/tokens';
import useInlineEdit from '../../hooks/useInlineEdit';
import { Box, Flex, Input } from '../../shared/components';
import { ColumnId } from '../../types/table';

export default function TokenName(props: SingleToken) {
  const { name } = props;
  const { nestingLevel } = useUIState();
  const { ref, setNewValue, editMode, setIsEditMode, onInputKeyDown, newValue } = useInlineEdit(props, ColumnId.NAME);

  const tokenName = useMemo(() => {
    const nameParts = name.split('.');
    return nameParts.splice(nestingLevel).join('.') || name;
  }, [name, nestingLevel]);

  const onNameDoubleClick = useCallback(() => {
    setIsEditMode(true);
  }, [setIsEditMode]);

  const onNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setNewValue(e.target.value);
    },
    [setNewValue]
  );

  return (
    <Flex
      css={{
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: '$3',
        height: '100%',
        padding: '$3 $5',
        width: '100%',
        border: `1px solid ${editMode ? '$accentEmphasis' : 'transparent'}`,
      }}
      onDoubleClick={onNameDoubleClick}
      ref={ref}
    >
      {editMode ? (
        <Input
          css={{ fontSize: '$xsmall', fontFamily: '$mono', border: 'none', height: '$5', paddingLeft: 0 }}
          value={newValue as string}
          autoFocus
          onKeyDown={onInputKeyDown}
          onChange={onNameChange}
        />
      ) : (
        <Link
          to={`${encodeURIComponent(name)}?panel=1`}
          style={{
            textDecoration: 'underline',
            color: 'inherit',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Box css={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{tokenName}</Box>
        </Link>
      )}
    </Flex>
  );
}
