import { useNavigate, useParams } from 'react-router-dom';
import { NotificationTypes, useNotifications } from '../context/NotificationContext';
import { useSync } from '../context/SyncContext';
import { useTokens } from '../context/TokensContext';
import { SingleToken } from '../ft-utils/types/tokens';
import { Mixpanel } from '../Mixpanel';
import { supabase } from '../supabase';
import { computeCompositionInputForSave } from '../utils/computeCompositionInputForSave';
import { useTokenSearchParams } from './useTokenSearchParams';

export function updateTokenPayloadToSingleToken(payload: SingleToken): SingleToken {
  return {
    name: payload.name,
    value: payload.value,
    type: payload.type,
    ...(payload.description
      ? {
          description: payload.description,
        }
      : {}),
    ...(payload.$extensions
      ? {
          $extensions: {
            'studio.tokens': {
              modify: payload.$extensions['studio.tokens']?.modify,
            },
          },
        }
      : {}),
  } as SingleToken;
}

export function useSaveToken() {
  const { selectedTokenSet, selectedToken } = useParams();
  const { tokenData } = useTokens();
  const { isNewToken } = useTokenSearchParams();
  const { addNotification } = useNotifications();
  const navigate = useNavigate();
  const { pluginIsConnected, allowEdits } = useSync();

  const saveToken = async (tokenValues: SingleToken) => {
    if (!pluginIsConnected && !allowEdits) {
      addNotification(
        NotificationTypes.ERORR,
        'Cannot edit token set. Please make sure Tokens Studio plugin is opened and sync is turned on'
      );
      return;
    }

    if (tokenData && selectedTokenSet) {
      const isUpdate = !!selectedToken;

      const values = structuredClone(tokenValues);

      if (values.type === 'composition') {
        // @ts-ignore
        values.value = computeCompositionInputForSave(values.value);
      }

      const finalValues = updateTokenPayloadToSingleToken(values);

      const data = structuredClone(tokenData);

      const newSets = data.synced_data.sets || {};

      if (isUpdate) {
        newSets[selectedTokenSet] = newSets[selectedTokenSet].map((token: SingleToken) =>
          token.name === selectedToken ? finalValues : token
        );
      } else {
        newSets[selectedTokenSet].push(finalValues);
      }

      data.synced_data.sets = newSets;

      const { error, status } = await supabase
        .from('tokens')
        .update({
          synced_data: JSON.stringify(data.synced_data),
          last_updated_by: 'second-screen',
        })
        .eq('id', tokenData.id);

      Mixpanel.track(isNewToken ? 'Create new token' : 'Edit token', { type: finalValues.type });

      if (error) {
        addNotification(
          NotificationTypes.ERORR,
          `Error ${isUpdate ? 'updating' : 'creating'} token: ${finalValues.name}`
        );
      }

      if (status === 204) {
        addNotification(
          NotificationTypes.SUCCESS,
          `Succesfully ${isUpdate ? 'updated' : 'created'} token: ${finalValues.name}`
        );

        navigate(`/${encodeURIComponent(selectedTokenSet)}`);
      }
    }
  };

  return { saveToken };
}
