import React, { ForwardedRef } from 'react';
import { Link } from 'react-router-dom';
import { Box, Flex } from '.';
import { styled } from '../../stitches.config';

interface NavItemProps {
  value: string;
  selected: boolean;
  to: string;
  icon: JSX.Element | null;
  level: number;
  hidden?: boolean;
}

const StyledLink = styled(Link);

const NavItem = React.forwardRef(
  ({ value, selected, to, icon, level, hidden }: NavItemProps, ref: ForwardedRef<HTMLAnchorElement>) => (
    <StyledLink
      ref={ref}
      to={to}
      css={{
        background: selected ? '$accentBg' : 'transparent',
        padding: '$3 $5 $3 0',
        borderRadius: '$medium',
        display: 'flex',
        width: '100%',
        textDecoration: 'none',
        opacity: hidden ? 0.5 : 1, // TODO: Change to token
        color: hidden ? '$fgSubtle' : '$fgDefault',
        position: 'relative',
        '&:hover': { backgroundColor: selected ? '$accentBg' : '$bgSubtle' },
        '&:focus-visible': {
          boxShadow: '$focus' },
      }}
    >
      <Flex
        css={{
          width: '100%',
          height: '100%',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          gap: 0,
          fontSize: '$small',
        }}
      >
        <Box css={{ width: '4px', position: 'absolute', left: 0, height: '50%', background: selected ? '$accentDefault' : 'transparent' }} />
        <Box css={{ paddingLeft: `${level * 12}px` }}>
          {value}
        </Box>
      </Flex>
      <Box css={{ width: '16px', height: '16px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{icon}</Box>
    </StyledLink>
  )
);

export default NavItem;
