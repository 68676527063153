import React, { useEffect } from 'react';
import { Form } from 'react-final-form';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { Heading, Link, Stack } from '@tokens-studio/ui';
import { Box, Flex } from '../shared/components';
import { useAuth } from '../context/AuthContext';
import SignupForm from '../components/Auth/SignupForm';
import { Mixpanel } from '../Mixpanel';

export default function Signup() {
  const { signUpWithEmailAndPassword, authError, loggedUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (loggedUser) {
      navigate('/');
    }
  }, [loggedUser, navigate]);

  const onSignupSubmit = async (values: { email: string; password: string }) => {
    const { email, password } = values;

    if (email && password) {
      const success = await signUpWithEmailAndPassword(email, password);

      if (success) {
        Mixpanel.track('Sign up');
        navigate('/');
      }
    }
  };

  const error = authError && (
    <Box
      css={{
        background: '$dangerBg',
        border: '1px solid $dangerBorder',
        fontSize: '$xsmall',
        borderRadius: '$medium',
        color: '$dangerFg',
        padding: '$5',
      }}
    >
      {authError}
    </Box>
  );

  return (
    <Flex
      css={{
        height: '100vh',
        background: '$bgCanvas',
      }}
    >
      <Stack
        direction="column"
        align="start"
        gap={5}
        css={{
          background: '$bgSurface',
          borderRadius: '$medium',
          border: '1px solid $borderSubtle',
          padding: '48px',
          maxWidth: '500px',
          width: '100%',
        }}
      >
        <Stack
          direction="column"
          align="start"
          gap={3}
        >
          <Heading bold size="large">Sign up</Heading>
          <Stack css={{ fontSize: '$small', color: '$fgSubtle' }} direction="row" gap={3}>
            Already have an account?
            <Link as={RouterLink} to="/login">
              Sign in
            </Link>
          </Stack>
        </Stack>
        {error}
        <Form
          onSubmit={onSignupSubmit}
          render={({ handleSubmit }) => <SignupForm onSubmit={handleSubmit} loading={false} />}
        />

      </Stack>
    </Flex>
  );
}
