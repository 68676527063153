import { Flex } from '../../../shared/components';

export function ComplexTokenProp({ children }: { children: React.ReactNode }) {
  return (
    <Flex
      css={{
        svg: { fill: '$fgDefault' },
        justifyContent: 'flex-start',
        gap: '$3',
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
    >
      {children}
    </Flex>
  );
}
