import { Box } from '@tokens-studio/ui';
import React, { useMemo, useRef } from 'react';
import { TokenTypes } from '../ft-utils/constants/TokenTypes';
import { SingleColorToken } from '../ft-utils/types/tokens';
import useIsInView from '../hooks/useIsInView';
import useModifiedColor from '../hooks/useModifiedColor';
import useModifiedDisplayColor from '../hooks/useModifiedDisplayColor';
import useResolvedTokenValue from '../hooks/useResolvedTokenValue';

interface ColorSwatchProps {
  $extensions: SingleColorToken['$extensions'];
  value: SingleColorToken['value'];
}

export default function ColorSwatch({ $extensions, value }: ColorSwatchProps) {
  const tokenRef = useRef<HTMLDivElement>(null);
  const inView = useIsInView<HTMLDivElement>(tokenRef);

  const resolvedColorValue = (useResolvedTokenValue(value, TokenTypes.COLOR, inView) as string) ?? value;

  const modifier = useMemo(() => $extensions?.['studio.tokens']?.modify, [$extensions]);

  const modifiedValue = useModifiedColor(resolvedColorValue, $extensions?.['studio.tokens']?.modify, inView);

  const modifiedDisplayColor = useModifiedDisplayColor(modifiedValue) ?? '';

  return (
    <Box
      ref={tokenRef}
      css={{
        width: '16px',
        height: '16px',
        background: modifier ? modifiedDisplayColor : resolvedColorValue,
        borderRadius: '$medium',
        border: '1px solid $borderMuted',
        flexShrink: 0,
      }}
    />
  );
}
