type AnyObject = Record<string, any>;

export const trimStrings = (obj: AnyObject | AnyObject[] | string): unknown => {
  if (typeof obj === 'string') {
    return obj.trim();
  }
  if (Array.isArray(obj)) {
    return obj.map(trimStrings);
  }
  if (obj !== null && typeof obj === 'object') {
    return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, trimStrings(value)]));
  }
  return obj;
};
