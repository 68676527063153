import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeftIcon } from '@radix-ui/react-icons';
import { Box, Stack, Button, ContextMenu } from '@tokens-studio/ui';
import { useTokens } from '../../context/TokensContext';
import { createTokensObject } from '../../utils/tokens/createTokenObj';
import { AccordionContent, AccordionItem, AccordionRoot, AccordionTrigger } from './TokensAccordion';
import TokenNavItem from './TokenNavItem';
import { useUIState } from '../../context/UIState';
import InlineTokenSwatch from '../InlineTokenSwatch';
import { TokenTypes } from '../../ft-utils/constants/TokenTypes';
import ColorSwatch from '../ColorSwatch';
import replaceGlobally from '../../utils/replaceGlobally';
import { useDeleteTokens } from '../../hooks/useDeleteTokens';
import CollapseSidebar from '../CollapseSidebar';
import useSelectedSetTokens from '../../hooks/useSelectedSetTokens';

export default function TokenNav() {
  const { sets } = useTokens();
  const { selectedTokenSet } = useParams();
  const navigate = useNavigate();
  const { nestingLevel } = useUIState();
  const deleteTokens = useDeleteTokens();

  const tokens = useSelectedSetTokens();

  const tokensObject = useMemo(() => {
    if (tokens) {
      return createTokensObject(tokens, nestingLevel);
    }
    return {};
  }, [tokens, nestingLevel]);

  if (!sets || !selectedTokenSet) {
    return null;
  }

  if (!tokensObject) {
    return null;
  }

  const getContent = (tokensObj: Record<string, any>, path?: string): any =>
    Object.keys(tokensObj).map((token) => {
      const tokenData = tokensObj[token];
      const key = path ? `${path}.${token}` : token;
      const level = key.split('.').length;

      if (!tokenData.type) {
        return (
          <AccordionRoot key={key} type="single" defaultValue={key} collapsible>
            <AccordionItem value={key}>
              <AccordionTrigger level={level}>{token}</AccordionTrigger>
              <AccordionContent>{getContent(tokenData, key)}</AccordionContent>
            </AccordionItem>
          </AccordionRoot>
        );
      }

      const doNotationKey = replaceGlobally(key, '/', '.');

      return (
        <ContextMenu key={key}>
          <ContextMenu.Trigger>
            <TokenNavItem
              tokenKey={key}
              key={key}
              level={level}
              icon={
                tokenData.type === TokenTypes.COLOR ? (
                  <ColorSwatch {...tokenData} />
                ) : (
                  <InlineTokenSwatch type={tokenData.type} />
                )
              }
            >
              {tokenData.name}
            </TokenNavItem>
          </ContextMenu.Trigger>
          <ContextMenu.Portal>
            <ContextMenu.Content>
              <ContextMenu.Item
                onClick={() => {
                  deleteTokens([doNotationKey]);
                }}
              >
                Delete
              </ContextMenu.Item>
            </ContextMenu.Content>
          </ContextMenu.Portal>
        </ContextMenu>
      );
    });

  return (
    <>
      <Stack direction="row" justify="between" align="center" css={{ padding: '0 $3' }}>
        <Stack direction="row" align="center">
          <Button
            variant="invisible"
            icon={<ArrowLeftIcon />}
            onClick={() => navigate(`/${encodeURIComponent(selectedTokenSet)}`)}
          >
            <Box css={{ fontWeight: '$sansBold' }}>{selectedTokenSet}</Box>
          </Button>
        </Stack>
        <CollapseSidebar isCollapsed={false} />
      </Stack>

      <Box css={{ padding: '0 $2' }}>{getContent(tokensObject)}</Box>
    </>
  );
}
