import { useMemo } from 'react';
import Color from 'colorjs.io';

export default (modifiedColor: string | null) =>
  useMemo(() => {
    if (modifiedColor) {
      const color = new Color(modifiedColor);
      return color.to('srgb').toString({ format: 'hex' });
    }
    return null;
  }, [modifiedColor]);
