import React, { useMemo, useRef } from 'react';
import { AliasRegex } from '../../ft-utils/constants/AliasRegex';
import { SingleColorToken } from '../../ft-utils/types/tokens';
import useIsInView from '../../hooks/useIsInView';
import useModifiedColor from '../../hooks/useModifiedColor';
import useModifiedDisplayColor from '../../hooks/useModifiedDisplayColor';
import useResolvedTokenValue from '../../hooks/useResolvedTokenValue';
import { Box, Flex } from '../../shared/components';
import ModifierString from '../ModifierString';
import RawValue from './partials/RawValue';
import ResolvedValueBox from './partials/ResolvedValue';
import TokenName from './partials/TokenName';
import TokenPreview from './partials/TokenPreview';

export default function ColorToken(props: SingleColorToken) {
  const { name, value, type, $extensions } = props;
  const hasAlias = Boolean(value?.match(AliasRegex));
  const tokenRef = useRef<HTMLDivElement>(null);
  const inView = useIsInView<HTMLDivElement>(tokenRef);
  const modifier = useMemo(() => $extensions?.['studio.tokens']?.modify, [$extensions]);

  const colorResolvedValue = (useResolvedTokenValue(value, type, inView) as SingleColorToken['value']) ?? value;

  const modifiedValue = useModifiedColor(colorResolvedValue, modifier, inView);
  const modifiedDisplayColor = useModifiedDisplayColor(modifiedValue) ?? '';

  return (
    <Box ref={tokenRef}>
      <TokenPreview>
        <Box
          css={{
            width: '64px',
            height: '32px',
            border: '1px solid $borderMuted',
            borderRadius: '$medium',
            marginBottom: '$3',
            background: modifier ? modifiedDisplayColor : colorResolvedValue,
          }}
        />
      </TokenPreview>
      <Flex css={{ flexDirection: 'column', alignItems: 'flex-start', gap: '$2', overflow: 'hidden' }}>
        <TokenName name={name} />
        <Box css={{ color: '$fgSubtle', fontSize: '$small' }}>
          <ModifierString modifier={modifier} />
        </Box>
        {hasAlias && value && <RawValue rawValue={value} />}
        <ResolvedValueBox isAccent={hasAlias} value={colorResolvedValue} />
      </Flex>
    </Box>
  );
}
