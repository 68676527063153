import { useRef } from 'react';
import { TokenTypes } from '../../../ft-utils/constants/TokenTypes';
import { SingleCompositionToken } from '../../../ft-utils/types/tokens';
import useResolvedTokenValue from '../../../hooks/useResolvedTokenValue';
import { Box, Flex, InlineBox } from '../../../shared/components';
import { ComplexTokenProp } from '../partials/ComplexTokenProp';
import useIsInView from '../../../hooks/useIsInView';

export default function CompositionResolvedValue(props: SingleCompositionToken) {
  const { value } = props;

  const tokenRef = useRef<HTMLDivElement>(null);
  const inView = useIsInView<HTMLDivElement>(tokenRef);
  const resolvedCompositionValue =
    (useResolvedTokenValue(value, TokenTypes.COMPOSITION, inView) as SingleCompositionToken['value']) ?? value;

  if (typeof resolvedCompositionValue === 'string') {
    return <Box css={{ padding: '$3 $5' }}>{resolvedCompositionValue}</Box>;
  }

  if (resolvedCompositionValue) {
    return (
      <Flex ref={tokenRef} css={{ padding: '$3 $5', flexDirection: 'column', gap: '$2', alignItems: 'flex-start' }}>
        {Object.keys(resolvedCompositionValue).map((key) => {
          const val = JSON.stringify(resolvedCompositionValue[key]);
          return (
            <ComplexTokenProp key={key}>
              <InlineBox css={{ color: '$fgSubtle' }}>{`${key}: `}</InlineBox>
              <InlineBox
                css={{
                  color: '$fgDefault',
                  flexGrow: 1,
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
                title={val}
              >
                {val}
              </InlineBox>
            </ComplexTokenProp>
          );
        })}
      </Flex>
    );
  }

  return null;
}
