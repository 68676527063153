import { Table } from '@tanstack/react-table';
import { useCallback, useMemo } from 'react';
import { useTokensTableClipboard } from '../context/TokensTableClipboardContext';
import { useTokensTableState } from '../context/TokensTableContext';
import { TableTokenItem } from '../ft-utils/types/TableTokenItem';
import { ColumnId, copiableCells } from '../types/table';
import getSortedSubRows from './getSortedSubRows';
import { useUpdateTokens } from './useUpdateTokens';

export default (table: Table<TableTokenItem>) => {
  const { selectedCells } = useTokensTableState();
  const { setCopiedItem, copiedData, copiedItem } = useTokensTableClipboard();
  const { updateTokens } = useUpdateTokens();

  const { rows } = table.getSortedRowModel();

  const sortedSubRows = getSortedSubRows(rows);

  const selectedCell = useMemo(() => selectedCells && selectedCells[0], [selectedCells]);

  const onCellCopy = useCallback(() => {
    if (selectedCell) {
      const { colId } = selectedCell;
      if (copiableCells.includes(colId)) {
        setCopiedItem(selectedCell);
      }
    }
  }, [selectedCell, setCopiedItem]);

  const onCellPaste = useCallback(() => {
    if (selectedCell && copiedData && copiedItem) {
      const { colId: copiedCellColId, rowId: copiedCellRowId } = copiedItem;

      const copiedCellRow = sortedSubRows.find((row) => row.id === copiedCellRowId);

      if (copiedCellRow && selectedCells) {
        const { type: copiedRowType } = copiedCellRow.original;

        let compatibleSelectedRowsIds = selectedCells.map((cell) => cell.rowId);

        if (copiedCellColId !== ColumnId.DESCRIPTION) {
          compatibleSelectedRowsIds = compatibleSelectedRowsIds.filter((rowId) => {
            const selectedCellRow = sortedSubRows.find((row) => row.id === rowId);
            if (selectedCellRow) {
              const { type: selectedRowType } = selectedCellRow.original;
              return selectedRowType === copiedRowType;
            }

            return false;
          });
        }

        if (compatibleSelectedRowsIds.length && selectedCells.every((cell) => cell.colId === copiedCellColId)) {
          if (copiedData.rawValue) {
            copiedData.value = copiedData.rawValue;
          }

          updateTokens(compatibleSelectedRowsIds, copiedData);
          setCopiedItem(null);
        }
      }
    }
  }, [selectedCell, copiedData, sortedSubRows, updateTokens, setCopiedItem, copiedItem, selectedCells]);

  return { onCellCopy, onCellPaste };
};
