import React, { useRef } from 'react';
import { AliasRegex } from '../../ft-utils/constants/AliasRegex';
import { SingleCompositionToken } from '../../ft-utils/types/tokens';
import useIsInView from '../../hooks/useIsInView';
import useResolvedTokenValue from '../../hooks/useResolvedTokenValue';
import { Box, Flex } from '../../shared/components';
import RawValue from './partials/RawValue';
import ResolvedValueBox from './partials/ResolvedValue';
import TokenName from './partials/TokenName';
import TokenPreview from './partials/TokenPreview';

export default function CompositionToken(props: SingleCompositionToken) {
  const { name, type, value } = props;

  const tokenRef = useRef<HTMLDivElement>(null);
  const inView = useIsInView<HTMLDivElement>(tokenRef);

  const resolvedTokenValue = useResolvedTokenValue(value, type, inView) as SingleCompositionToken['value'];

  function getTokenRow(key: string, val: string) {
    if (!resolvedTokenValue) return null;

    const resolvedValue = resolvedTokenValue[key];
    const hasAlias = Boolean(val?.match(AliasRegex));

    return (
      <Flex key={`composition-${name}-${key}`} css={{ flexDirection: 'row', alignItems: 'center', gap: '$3' }}>
        <Box css={{ fontWeight: '$sansRegular', fontSize: '$small', color: '$fgDefault' }}>{key}</Box>
        {hasAlias && val && <RawValue rawValue={val} />}
        <ResolvedValueBox
          isAccent={hasAlias}
          value={typeof resolvedValue === 'string' ? resolvedValue : JSON.stringify(resolvedValue)}
        />
      </Flex>
    );
  }

  return (
    <Box ref={tokenRef}>
      <TokenPreview>
        <Flex css={{ flexDirection: 'column', alignItems: 'flex-start', gap: '$3', overflow: 'hidden' }}>
          <TokenName name={name} />
          {Object.keys(value).map((token) => getTokenRow(token, value[token]))}
        </Flex>
      </TokenPreview>
    </Box>
  );
}
