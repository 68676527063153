import { Properties } from '../../../ft-utils/constants/Properties';
import ColorInput from './ColorInput';
import GenericInput from './GenericInput';

export default function CompositionTokenValue({
  property,
  valuePath,
  autoFocus = false,
}: {
  property: Properties;
  valuePath: string;
  autoFocus?: boolean;
}) {
  switch (property) {
    case Properties.fill:
      return <ColorInput valuePath={valuePath} showLabel={false} autoFocus={autoFocus} />;
    default:
      return (
        <GenericInput
          tokenTypeOrProp={property}
          valuePath={valuePath}
          showLabel={false}
          showIcon={false}
          autoFocus={autoFocus}
        />
      );
  }
}
