import { TokensIcon } from '@radix-ui/react-icons';
import React, { useCallback, useEffect, useState } from 'react';
import { useField } from 'react-final-form';
import { IconButton } from '@tokens-studio/ui';
import { TypographyValues } from '../../../ft-utils/constants/TypographyValues';
import { Box, Flex } from '../../../shared/components';
import GenericInput from './GenericInput';
import { TokenTypes } from '../../../ft-utils/constants/TokenTypes';
import { AliasRegex } from '../../../ft-utils/constants/AliasRegex';

export default function TypographyInput() {
  const valueField = useField('value');

  const {
    input: { value },
  } = valueField;

  const [useReference, setUseReference] = useState(false);

  useEffect(() => {
    if (value) {
      setUseReference(
        typeof value === 'string' || (typeof value === 'string' && Boolean(value.trim().match(AliasRegex)))
      );
    }
  }, [value]);

  const toggleUseReference = useCallback(() => {
    valueField.input.onChange('');
    setUseReference(!useReference);
  }, [useReference, valueField]);

  const typographyPropsInputs = (
    <Box>
      <Box
        css={{
          marginBottom: '$3',
          fontSize: '$base',
          fontWeight: '$sansMedium',
          color: '$fgDefault',
          userSelect: 'none',
        }}
      >
        Typography values
      </Box>
      {Object.values(TypographyValues).map((key) => (
        <Box key={key} css={{ display: 'flex', alignItems: 'center', gap: '$5', padding: '$3 0' }}>
          <GenericInput showLabel={false} valuePath={`value.${key}`} tokenTypeOrProp={key} />
        </Box>
      ))}
    </Box>
  );

  return (
    <Flex css={{ flexDirection: 'column', alignItems: 'stretch' }}>
      <Box css={{ alignSelf: 'flex-end' }}>
        <IconButton
          variant="invisible"
          size="medium"
          tooltip={useReference ? 'Add values' : 'Use reference'}
          icon={<TokensIcon />}
          selected={useReference}
          onClick={toggleUseReference}
        />
      </Box>
      {useReference ? <GenericInput tokenTypeOrProp={TokenTypes.TYPOGRAPHY} /> : typographyPropsInputs}
    </Flex>
  );
}
