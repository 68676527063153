import {
  BlendingModeIcon,
  BorderStyleIcon,
  BorderWidthIcon,
  BoxModelIcon,
  ColorWheelIcon,
  CornersIcon,
  FontFamilyIcon,
  FontSizeIcon,
  FontStyleIcon,
  ImageIcon,
  LayersIcon,
  LetterCaseCapitalizeIcon,
  LetterCaseToggleIcon,
  LetterSpacingIcon,
  LineHeightIcon,
  OpacityIcon,
  PinRightIcon,
  RowSpacingIcon,
  ShadowIcon,
  ShadowInnerIcon,
  SizeIcon,
  UnderlineIcon,
} from '@radix-ui/react-icons';
import React from 'react';
import { BorderValues } from '../ft-utils/constants/BorderValues';
import { BoxShadowValues } from '../ft-utils/constants/BoxShadowValues';
import { TokenTypes } from '../ft-utils/constants/TokenTypes';
import { TypographyValues } from '../ft-utils/constants/TypographyValues';
import { TokenTypeOrProp } from '../ft-utils/types/TokenTypeOrProp';

interface TokenIconProps {
  propertyKey: TokenTypeOrProp;
}

export default function TokenIcon({ propertyKey }: TokenIconProps) {
  switch (propertyKey) {
    case TypographyValues.FONT_FAMILY:
    case TokenTypes.FONT_FAMILIES:
      return <FontFamilyIcon style={{ flexShrink: 0 }} />;
    case TypographyValues.FONT_SIZE:
    case TokenTypes.FONT_SIZES:
      return <FontSizeIcon style={{ flexShrink: 0 }} />;
    case TypographyValues.FONT_WEIGHT:
    case TokenTypes.FONT_WEIGHTS:
      return <FontStyleIcon style={{ flexShrink: 0 }} />;
    case TypographyValues.LETTER_SPACING:
    case TokenTypes.LETTER_SPACING:
      return <LetterSpacingIcon style={{ flexShrink: 0 }} />;
    case TypographyValues.LINE_HEIGHT:
    case TokenTypes.LINE_HEIGHTS:
      return <LineHeightIcon style={{ flexShrink: 0 }} />;
    case TypographyValues.PARAGRAPH_INDENT:
      return <PinRightIcon style={{ flexShrink: 0 }} />;
    case TypographyValues.PARAGRAPH_SPACING:
      return <RowSpacingIcon style={{ flexShrink: 0 }} />;
    case TypographyValues.TEXT_CASE:
      return <LetterCaseCapitalizeIcon style={{ flexShrink: 0 }} />;
    case TypographyValues.TEXT_DECORATION:
      return <UnderlineIcon style={{ flexShrink: 0 }} />;
    case BoxShadowValues.X:
      return <BoxModelIcon style={{ flexShrink: 0 }} />;
    case BoxShadowValues.Y:
      return <BoxModelIcon style={{ flexShrink: 0 }} />;
    case BoxShadowValues.BLUR:
      return <ShadowIcon style={{ flexShrink: 0 }} />;
    case BoxShadowValues.SPREAD:
      return <SizeIcon style={{ flexShrink: 0 }} />;
    case BoxShadowValues.COLOR:
    case BorderValues.BORDER_COLOR:
      return <ColorWheelIcon style={{ flexShrink: 0 }} />;
    case BoxShadowValues.BLEND_MODE:
      return <BlendingModeIcon style={{ flexShrink: 0 }} />;
    case BoxShadowValues.TYPE:
      return <ShadowInnerIcon style={{ flexShrink: 0 }} />;
    case BorderValues.BORDER_WIDTH:
    case TokenTypes.BORDER:
      return <BorderWidthIcon style={{ flexShrink: 0 }} />;
    case BorderValues.BORDER_STYLE:
      return <BorderStyleIcon style={{ flexShrink: 0 }} />;
    case TokenTypes.TYPOGRAPHY:
      return <LetterCaseToggleIcon style={{ flexShrink: 0 }} />;
    case TokenTypes.BORDER_RADIUS:
    case TokenTypes.BORDER_WIDTH:
      return <CornersIcon style={{ flexShrink: 0 }} />;
    case TokenTypes.COMPOSITION:
      return <LayersIcon style={{ flexShrink: 0 }} />;
    case TokenTypes.BOX_SHADOW:
      return <ShadowIcon style={{ flexShrink: 0 }} />;
    case TokenTypes.ASSET:
      return <ImageIcon style={{ flexShrink: 0 }} />;
    case TokenTypes.OPACITY:
      return <OpacityIcon style={{ flexShrink: 0 }} />;
    case TokenTypes.SIZING:
    case TokenTypes.SPACING:
    case TokenTypes.DIMENSION:
      return <SizeIcon style={{ flexShrink: 0 }} />;
    default:
      return null;
  }
}
