import { Button, Label } from '@tokens-studio/ui';
import React, { useMemo } from 'react';
import { useTokensTableState } from '../../../context/TokensTableContext';
import { TokenTypes } from '../../../ft-utils/constants/TokenTypes';
import { useDeleteTokens } from '../../../hooks/useDeleteTokens';
import useSelectedTokens from '../../../hooks/useSelectedTokens';
import { Flex } from '../../../shared/components';
import { EditableTableColumns } from '../../../types/EditableTableColumns';

export default function BulkEditBar() {
  const { rowSelection, setRowSelection, setBulkEditPopoverVisible, bulkEditPopoverVisible } = useTokensTableState();
  const deleteTokens = useDeleteTokens();
  const selection = useMemo(() => Object.keys(rowSelection), [rowSelection]);

  const onDelete = async () => {
    await deleteTokens(selection);
    setRowSelection({});
  };

  const selectedTokens = useSelectedTokens();

  const allItemsHaveSameType = useMemo(
    () => selectedTokens.every((token, index, array) => array[0] && token && token.type === array[0].type),
    [selectedTokens]
  );

  const showChangeModifier = useMemo(
    () => selectedTokens[0] && selectedTokens[0].type === TokenTypes.COLOR && allItemsHaveSameType,
    [allItemsHaveSameType, selectedTokens]
  );

  if (selection.length < 2 || bulkEditPopoverVisible) return null;

  return (
    <Flex
      css={{
        position: 'absolute',
        bottom: '$6',
        left: '50%',
        width: 'max-content',
        flexShrink: 0,
        transform: 'translate(-50%, 0)',
        background: '$bgDefault',
        border: '1px solid $borderMuted',
        padding: '$5',
        borderRadius: '$medium',
        gap: '$5',
        boxShadow: '$contextMenu',
      }}
    >
      <Label css={{ flexShrink: 0, color: '$fgSubtle' }}>{`${selection.length} selected`}</Label>
      {showChangeModifier && (
        <Button
          size="medium"
          variant="secondary"
          onClick={() => setBulkEditPopoverVisible(true, EditableTableColumns.MODIFIER)}
        >
          Change modifier
        </Button>
      )}
      {allItemsHaveSameType && (
        <Button
          size="medium"
          variant="secondary"
          autoFocus
          onClick={() => setBulkEditPopoverVisible(true, EditableTableColumns.VALUE)}
        >
          Change value
        </Button>
      )}
      <Button
        size="medium"
        variant="secondary"
        autoFocus
        onClick={() => setBulkEditPopoverVisible(true, EditableTableColumns.DESCRIPTION)}
      >
        Change description
      </Button>
      <Button size="medium" variant="secondary" onClick={onDelete}>
        Delete
      </Button>
    </Flex>
  );
}
