import { BorderValues } from '../ft-utils/constants/BorderValues';
import { BoxShadowValues } from '../ft-utils/constants/BoxShadowValues';
import { Properties } from '../ft-utils/constants/Properties';
import { TokenTypes } from '../ft-utils/constants/TokenTypes';
import { TypographyValues } from '../ft-utils/constants/TypographyValues';
import { TokenTypeOrProp } from '../ft-utils/types/TokenTypeOrProp';

export function getTokenType(type: TokenTypeOrProp): TokenTypes {
  switch (type) {
    case BorderValues.BORDER_WIDTH:
      return TokenTypes.BORDER_WIDTH;
    case BorderValues.BORDER_COLOR:
      return TokenTypes.COLOR;
    case BorderValues.BORDER_STYLE:
      return TokenTypes.OTHER;
    case Properties.sizing:
    case Properties.height:
    case Properties.width:
    case BoxShadowValues.BLUR:
    case BoxShadowValues.SPREAD:
    case BoxShadowValues.X:
    case BoxShadowValues.Y:
      return TokenTypes.SIZING;
    case Properties.spacing:
    case Properties.itemSpacing:
    case Properties.verticalPadding:
    case Properties.horizontalPadding:
    case Properties.paddingTop:
    case Properties.paddingLeft:
    case Properties.paddingBottom:
    case Properties.paddingRight:
      return TokenTypes.SPACING;
    case Properties.borderRadiusTopLeft:
    case Properties.borderRadiusTopRight:
    case Properties.borderRadiusBottomLeft:
    case Properties.borderRadiusBottomRight:
    case Properties.borderRadius:
      return TokenTypes.BORDER_RADIUS;
    case Properties.fill:
      return TokenTypes.COLOR;
    case Properties.borderWidthTop:
    case Properties.borderWidthLeft:
    case Properties.borderWidthRight:
    case Properties.borderWidthBottom:
    case Properties.borderWidth:
      return TokenTypes.BORDER_WIDTH;
    case Properties.border:
      return TokenTypes.BORDER;
    case Properties.opacity:
      return TokenTypes.OPACITY;
    case Properties.boxShadow:
      return TokenTypes.BOX_SHADOW;
    case Properties.fontFamilies:
    case TypographyValues.FONT_FAMILY:
      return TokenTypes.FONT_FAMILIES;
    case Properties.fontWeights:
    case TypographyValues.FONT_WEIGHT:
      return TokenTypes.FONT_WEIGHTS;
    case Properties.lineHeights:
    case TypographyValues.LINE_HEIGHT:
      return TokenTypes.LINE_HEIGHTS;
    case Properties.fontSizes:
    case TypographyValues.FONT_SIZE:
      return TokenTypes.FONT_SIZES;
    case Properties.textCase:
    case TypographyValues.TEXT_CASE:
      return TokenTypes.TEXT_CASE;
    case Properties.typography:
      return TokenTypes.TYPOGRAPHY;
    case Properties.composition:
      return TokenTypes.COMPOSITION;
    case Properties.letterSpacing:
    case TypographyValues.LETTER_SPACING:
      return TokenTypes.LETTER_SPACING;
    case Properties.paragraphSpacing:
    case TypographyValues.PARAGRAPH_SPACING:
      return TokenTypes.PARAGRAPH_SPACING;
    case Properties.textDecoration:
    case TypographyValues.TEXT_DECORATION:
      return TokenTypes.TEXT_DECORATION;
    case Properties.asset:
      return TokenTypes.ASSET;
    case TypographyValues.PARAGRAPH_INDENT:
      return TokenTypes.PARAGRAPH_INDENT;
    case TokenTypes.OTHER:
      return TokenTypes.OTHER;
    default:
      return TokenTypes.OTHER;
  }
}
