import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import * as Toast from '@radix-ui/react-toast';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { BrowserRouter } from 'react-router-dom';
import { Tooltip } from '@tokens-studio/ui';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Box, ConfirmAlert, NotificationToast } from './shared/components';
import { ConfirmContextProvider } from './context/ConfirmContext';
import { NotificationContextProvider } from './context/NotificationContext';
import { AuthProvider } from './context/AuthContext';
import { UIContextProvider } from './context/UIState';
import { SyncContextProvider } from './context/SyncContext';
import { ThemeProvider } from './hooks/useTheme';
import { TokensTableContextProvider } from './context/TokensTableContext';
import { InlineEditContext } from './context/InlineEditContext';
import { TokensTableClipboardProvider } from './context/TokensTableClipboardContext';
import { TokensContextProvider } from './context/TokensContext';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

Sentry.init({
  dsn: '',
  integrations: [new BrowserTracing()],
  environment: process.env.NODE_ENV,
});

root.render(
  <Tooltip.Provider delayDuration={800} skipDelayDuration={500}>
    <NotificationContextProvider>
      <AuthProvider>
        <ThemeProvider>
          <SyncContextProvider>
            <BrowserRouter>
              <Box
                css={{
                  height: '100vh',
                }}
              >
                <React.StrictMode>
                  <NotificationContextProvider>
                    <ConfirmContextProvider>
                      <UIContextProvider>
                        <Toast.Provider>
                          <App />

                          <ConfirmAlert />
                          <NotificationToast />
                        </Toast.Provider>
                      </UIContextProvider>
                    </ConfirmContextProvider>
                  </NotificationContextProvider>
                </React.StrictMode>
              </Box>
            </BrowserRouter>
          </SyncContextProvider>
        </ThemeProvider>
      </AuthProvider>
    </NotificationContextProvider>
  </Tooltip.Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
