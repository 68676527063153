import { Box } from '@tokens-studio/ui';
import React, { useRef } from 'react';
import { SingleTypographyToken } from '../../../ft-utils/types/tokens';
import { Flex } from '../../../shared/components';
import useResolvedTokenValue from '../../../hooks/useResolvedTokenValue';
import { TokenTypes } from '../../../ft-utils/constants/TokenTypes';
import useIsInView from '../../../hooks/useIsInView';

export default function TypographyResolvedValue(props: SingleTypographyToken) {
  const { value } = props;

  const tokenRef = useRef<HTMLDivElement>(null);
  const inView = useIsInView<HTMLDivElement>(tokenRef);

  const resolvedCompositionValue =
    (useResolvedTokenValue(value, TokenTypes.TYPOGRAPHY, inView) as SingleTypographyToken['value']) ?? value;

  function getResolvedValue() {
    if (typeof resolvedCompositionValue === 'string') {
      return resolvedCompositionValue;
    }

    return `${resolvedCompositionValue.fontFamily} ${resolvedCompositionValue.fontWeight} ${resolvedCompositionValue.fontSize} / ${resolvedCompositionValue.lineHeight}`;
  }

  return (
    <Flex
      ref={tokenRef}
      css={{
        height: '100%',
        padding: '$3 $5',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      }}
    >
      <Box css={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{getResolvedValue()}</Box>
    </Flex>
  );
}
