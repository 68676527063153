import { ArrowDownIcon, ArrowUpIcon } from '@radix-ui/react-icons';
import { SortDirection } from '@tanstack/react-table';
import { Box } from '@tokens-studio/ui';
import React from 'react';

interface SortingIconProps {
  direction: SortDirection | false;
}

export default function SortingIcon({ direction }: SortingIconProps) {
  if (!direction) return null;

  return <Box css={{ display: 'flex' }}>{direction === 'asc' ? <ArrowUpIcon /> : <ArrowDownIcon />}</Box>;
}
