import { styled } from '../../stitches.config';

const Input = styled('input', {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '$small',
  padding: '0 $4',
  height: 34,
  fontSize: '$base',
  color: '$fgDefault',
  lineHeight: 1,
  width: '100%',
  variants: {
    variant: {
      white: {
        background: '$inputBg',
        border: '1px solid $inputBorderRest',
      },
      outline: {
        background: '$inputBg',
        border: '1px solid $inputBorderRest',
      },
    },
  },
  defaultVariants: {
    variant: 'white',
  },
});

export default Input;
