import { Button } from '@tokens-studio/ui';
import React from 'react';
import { Flex } from '../../../shared/components';

interface SubmitFooterProps {
  onCancel: () => void;
}

export default function SubmitFooter({ onCancel }: SubmitFooterProps) {
  return (
    <Flex css={{ justifyContent: 'space-between' }}>
      <Button onClick={onCancel}>Cancel</Button>
      <Button variant="primary" type="submit">
        Save
      </Button>
    </Flex>
  );
}
