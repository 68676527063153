import { useCallback, useMemo } from 'react';
import { Box, Button } from '@tokens-studio/ui';
import { CheckIcon } from '@radix-ui/react-icons';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { DropdownMenuPortal, DropdownMenuRadioGroup } from '@radix-ui/react-dropdown-menu';
import { useTokens } from '../../context/TokensContext';
import {
  DropdownMenu,
  DropdownMenuRadioItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuItemIndicator,
} from '../DropdownMenu';
import updateActiveThemes from '../../utils/updateActiveThemes';
import { NotificationTypes, useNotifications } from '../../context/NotificationContext';
import { useSync } from '../../context/SyncContext';

const INTERNAL_THEMES_NO_GROUP = 'internal_themes_no_group';
const INTERNAL_THEMES_NO_GROUP_LABEL = 'No group';

interface ThemeItem {
  id: string;
  label: string;
}

export default function ThemeSelector() {
  const { themes, activeTheme, setActiveTheme, tokenData } = useTokens();
  const { addNotification } = useNotifications();
  const { pluginIsConnected, allowEdits } = useSync();

  const activeThemeLabel = useMemo(() => {
    if (themes && activeTheme) {
      if (Object.keys(activeTheme).length === 0) return 'None';

      if (Object.keys(activeTheme).length === 1) {
        const themeOption = themes.find(({ id }) => id === Object.values(activeTheme)[0]);
        return themeOption ? themeOption.name : 'Unknown';
      }
      return `${Object.keys(activeTheme).length} active`;
    }
    return 'None';
  }, [activeTheme, themes]);

  const dropdowMenuThemesObject = useMemo(() => {
    if (!themes) return {};

    return themes.reduce((acc, val) => {
      const group = val.group || INTERNAL_THEMES_NO_GROUP;
      const item = { id: val.id, label: val.name };

      acc[group] = acc[group] || [];
      acc[group].push(item);

      return acc;
    }, {} as Record<string, ThemeItem[]>);
  }, [themes]);

  const handleSelect = useCallback(
    async (theme: ThemeItem, group: string) => {
      if (pluginIsConnected || allowEdits) {
        const newActiveThemesObject = structuredClone(activeTheme);

        if (newActiveThemesObject[group] === theme.id) {
          delete newActiveThemesObject[group];
        } else {
          newActiveThemesObject[group] = theme.id;
        }

        if (tokenData) {
          setActiveTheme(newActiveThemesObject);
          await updateActiveThemes(tokenData, newActiveThemesObject);
        }
      } else {
        addNotification(
          NotificationTypes.ERORR,
          'Cannot edit. Please make sure Tokens Studio plugin is opened and sync is turned on'
        );
      }
    },
    [activeTheme, setActiveTheme, tokenData, pluginIsConnected, allowEdits, addNotification]
  );

  if (!themes) {
    return <div />;
  }

  return (
    <DropdownMenu>
      <DropdownMenuPrimitive.Trigger asChild>
        <Button variant="secondary" size="medium">
          {`Theme: ${activeThemeLabel}`}
        </Button>
      </DropdownMenuPrimitive.Trigger>
      <DropdownMenuPortal>
        <DropdownMenuContent sideOffset={5}>
          {Object.keys(dropdowMenuThemesObject).map((group) => (
            <Box key={group}>
              <DropdownMenuLabel>
                {group === INTERNAL_THEMES_NO_GROUP ? INTERNAL_THEMES_NO_GROUP_LABEL : group}
              </DropdownMenuLabel>
              <DropdownMenuRadioGroup value={activeTheme[group] !== 'undefined' ? activeTheme[group] : ''}>
                {dropdowMenuThemesObject[group].map((theme) => (
                  <DropdownMenuRadioItem key={theme.id} value={theme.id} onSelect={() => handleSelect(theme, group)}>
                    <DropdownMenuItemIndicator>
                      <CheckIcon />
                    </DropdownMenuItemIndicator>
                    {theme.label}
                  </DropdownMenuRadioItem>
                ))}
              </DropdownMenuRadioGroup>
            </Box>
          ))}
        </DropdownMenuContent>
      </DropdownMenuPortal>
    </DropdownMenu>
  );
}
