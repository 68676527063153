import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { useTokens } from '../context/TokensContext';
import { useUIState } from '../context/UIState';
import { tokenHasAliasOrMath } from '../ft-utils/alias/hasAlias';
import { TokenSetStatus } from '../ft-utils/constants/TokenSetStatus';
import { TokenTypes } from '../ft-utils/constants/TokenTypes';
import { ResolveTokenValuesResult } from '../ft-utils/tokenHelpers';
import { SingleToken } from '../ft-utils/types/tokens';
import isEmptyObject from '../utils/isEmptyObject';
import { useTokenSetsStatus } from './useTokenSetsStatus';

const resolveWorker = new Worker(new URL('../resolveWorker.ts', import.meta.url));

export default (
  value: SingleToken['value'],
  type: TokenTypes,
  resolve?: boolean
): ResolveTokenValuesResult['value'] | undefined => {
  const { usedTokenSets, sets } = useTokens();
  const [resolving, setResolving] = useState(false);
  const [resolvedTokenValue, setResolvedTokenValue] = useState<ResolveTokenValuesResult['value']>();
  const { tokenView } = useUIState();
  const { selectedTokenSet } = useParams();
  const selectedTokenSetsStatus = useTokenSetsStatus();

  useEffect(() => {
    setResolvedTokenValue(undefined);
    // resolve again when value changes or theme/sets change
  }, [value, type, selectedTokenSetsStatus, usedTokenSets]);

  useEffect(() => {
    const shouldResolve = !resolvedTokenValue && resolve;

    if (shouldResolve && sets && !isEmptyObject(sets)) {
      if (selectedTokenSet && sets[selectedTokenSet]) {
        const hasAliasOrMath = tokenHasAliasOrMath(value, type);

        if (hasAliasOrMath) {
          const messageId = `${selectedTokenSet}${uuid()}`;
          setResolving(true);
          const selectedTokenSetActive = { [selectedTokenSet]: TokenSetStatus.ENABLED };

          resolveWorker.postMessage({
            type: 'resolveTokens',
            sets,
            selectedTokenSetActive,
            selectedTokenSets: selectedTokenSetsStatus,
            id: messageId,
            tokenValue: value,
            tokenType: type,
          });

          resolveWorker.addEventListener('message', (msg) => {
            if (msg.data.id === messageId) {
              const { resolvedToken } = msg.data;

              if (resolvedToken) {
                setResolvedTokenValue(resolvedToken.value);
                setResolving(false);
              }
            }
          });
        }
      }
    }
  }, [
    selectedTokenSetsStatus,
    usedTokenSets,
    sets,
    selectedTokenSet,
    tokenView,
    resolve,
    resolvedTokenValue,
    value,
    type,
  ]);

  return resolvedTokenValue;
};
