import React, { useCallback } from 'react';
import * as Popover from '@radix-ui/react-popover';
import { TriangleDownIcon } from '@radix-ui/react-icons';
import { IconButton } from '@tokens-studio/ui';
import { TokenTypes } from '../../ft-utils/constants/TokenTypes';
import { ResolveTokenValuesResult } from '../../ft-utils/tokenHelpers';
import BoxShadowValue from './Shadow/BoxShadowValue';
import GenericValue from './Generic/GenericTokenValue';
import TypographyValue from './Typography/TypographyValue';
import ColorTokenValue from './Color/ColorTokenValue';
import BorderValue from './Border/BorderValue';
import CompositionValue from './Composition/CompositionValue';
import useInlineEdit from '../../hooks/useInlineEdit';
import { ColumnId } from '../../types/table';
import { Box, Flex } from '../../shared/components';
import InlineEditForm from './InlineEditForm';

export default function TokenValue(props: ResolveTokenValuesResult) {
  const { editMode, ref, setIsEditMode } = useInlineEdit(props, ColumnId.RAW_VALUE);

  const setEditMode = useCallback(() => {
    setIsEditMode(true);
  }, [setIsEditMode]);

  const getValue = () => {
    const { type, value } = props;

    switch (type) {
      case TokenTypes.BOX_SHADOW:
        return <BoxShadowValue {...props} />;
      case TokenTypes.TYPOGRAPHY:
        return <TypographyValue {...props} />;
      case TokenTypes.BORDER:
        return <BorderValue {...props} />;
      case TokenTypes.COMPOSITION:
        return <CompositionValue {...props} />;
      case TokenTypes.COLOR:
        return <ColorTokenValue {...props} />;
      case TokenTypes.SIZING:
      case TokenTypes.OPACITY:
      case TokenTypes.BORDER_RADIUS:
      case TokenTypes.BORDER_WIDTH:
      case TokenTypes.SPACING:
      case TokenTypes.ASSET:
      default:
        return <GenericValue value={value} />;
    }
  };

  return (
    <Flex
      onDoubleClick={setEditMode}
      css={{ height: '100%', justifyContent: 'space-between', position: 'relative', overflow: 'hidden' }}
    >
      <Popover.Root open>
        <Popover.Anchor style={{ height: '100%', width: '100%' }}>{getValue()}</Popover.Anchor>
        {editMode && (
          <Popover.Portal>
            <Popover.Content sideOffset={5}>
              <Box
                css={{
                  background: '$bgDefault',
                  border: '1px solid $borderMuted',
                  borderRadius: '$medium',
                  padding: '$5',
                  boxShadow: '$contextMenu',
                  color: '$fgDefault',
                }}
                ref={ref}
              >
                <InlineEditForm {...props} />
              </Box>
            </Popover.Content>
          </Popover.Portal>
        )}
      </Popover.Root>
      <Flex
        css={{
          position: 'absolute',
          right: 0,
          top: 0,
          height: '100%',
        }}
      >
        <IconButton
          css={{ background: 'transparent' }}
          icon={<TriangleDownIcon />}
          variant="invisible"
          onClick={setEditMode}
        />
      </Flex>
    </Flex>
  );
}
