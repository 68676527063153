import { useState } from 'react';
import { NotificationTypes, useNotifications } from '../context/NotificationContext';

type CopyFn = (text: string) => Promise<boolean>; // Return success

function useCopyToClipboard(): [CopyFn] {
  const { addNotification } = useNotifications();

  const copy: CopyFn = async (text) => {
    if (!navigator?.clipboard) {
      addNotification(NotificationTypes.ERORR, 'Clipboard not supported');
      return false;
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text);
      return true;
    } catch (error) {
      return false;
    }
  };

  return [copy];
}

export default useCopyToClipboard;
