import { RealtimeChannel } from '@supabase/supabase-js';
import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';
import { supabase } from '../supabase';
import { useAuth } from './AuthContext';

export interface SyncContextType {
  pluginIsConnected: boolean;
  allowEdits: boolean;
}

const SyncContext = createContext<SyncContextType | undefined>(undefined);

function SyncContextProvider({ children }: { children: ReactNode }) {
  const { loggedUser } = useAuth();
  const [allowEdits] = useLocalStorage('allowEdits', false);
  const [pluginIsConnected, setPluginIsConnected] = useState(false);

  useEffect(() => {
    let channel: RealtimeChannel | null = null;
    if (loggedUser) {
      console.log('CHANNEL name', `${loggedUser.id}`);
      channel = supabase.channel(`${loggedUser.id}`, {
        config: {
          presence: {
            key: `second-screen-${loggedUser.email}`,
          },
        },
      });

      channel.on('presence', { event: 'sync' }, () => {
        const connectedClients = channel?.presenceState();
        const pluginKey = `plugin-${loggedUser.email}`;

        console.log('Connected clients: ', connectedClients);

        if (connectedClients && connectedClients[pluginKey]) {
          setPluginIsConnected(true);
        } else {
          setPluginIsConnected(false);
        }
      });

      channel.on('presence', { event: 'join' }, ({ newPresences }) => {
        console.log('New users have joined: ', newPresences);
      });

      channel.on('presence', { event: 'leave' }, ({ leftPresences }) => {
        console.log('Users have left: ', leftPresences);
      });

      channel.subscribe(async (status) => {
        console.log(status);
        if (status === 'SUBSCRIBED' && channel) {
          try {
            await channel.track({ online_at: new Date().toISOString() });
          } catch (error) {
            console.log('channel track error', error);
          }
        }
      });
    }

    return () => {
      if (channel) supabase.removeChannel(channel);
    };
  }, [loggedUser]);

  const providerValue = useMemo(
    () => ({
      pluginIsConnected,
      allowEdits,
    }),
    [pluginIsConnected, allowEdits]
  );
  return <SyncContext.Provider value={providerValue}>{children}</SyncContext.Provider>;
}

function useSync() {
  const context = useContext(SyncContext);
  if (context === undefined) {
    throw new Error('useSync must be used within a SyncContextProvider');
  }
  return context;
}

export { SyncContextProvider, useSync };
