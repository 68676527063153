import React from 'react';
import { Field, FieldRenderProps, useForm } from 'react-final-form';
import { TokenTypes } from '../../ft-utils/constants/TokenTypes';
import tokenTypes from '../../ft-utils/constants/tokenTypesSchema';
import { useTokenSearchParams } from '../../hooks/useTokenSearchParams';
import { Box, Select } from '../../shared/components';

export default function TokenTypeSelector() {
  const { reset, getState } = useForm();
  const { isNewToken } = useTokenSearchParams();

  if (!isNewToken) {
    return null;
  }

  const onTypeChange = (value: TokenTypes, fieldProps: FieldRenderProps<TokenTypes>) => {
    const { initialValues, values: currentValues } = getState();
    const { name, description } = currentValues;

    reset({ ...initialValues, value: null, name, description });
    fieldProps.input.onChange(value);
  };

  const items = Object.keys(tokenTypes).map((val) => ({ value: val, label: tokenTypes[val].label, id: val }));

  return (
    <Box>
      <Field
        name="type"
        render={(fieldProps) => (
          <Select
            {...fieldProps.input}
            items={items || []}
            label="Type:"
            onChange={(value) => onTypeChange(value, fieldProps)}
          />
        )}
      />
    </Box>
  );
}
