import React, { useEffect } from 'react';
import { Form } from 'react-final-form';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { Heading, Link, Stack } from '@tokens-studio/ui';
import { Box, Flex } from '../shared/components';
import { LoginForm } from '../components';
import { useAuth } from '../context/AuthContext';

export default function Login() {
  const { signInWithEmailAndPassword, authError, loggedUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (loggedUser) {
      navigate('/');
    }
  }, [loggedUser, navigate]);

  const onLoginSubmit = async (values: { email: string; password: string }) => {
    const { email, password } = values;
    if (email && password) {
      const success = await signInWithEmailAndPassword(email, password);

      if (success) {
        navigate('/');
      }
    }
  };

  const error = authError && (
    <Box
      css={{
        background: '$dangerBg',
        border: '1px solid $dangerBorder',
        fontSize: '$xsmall',
        borderRadius: '$medium',
        color: '$dangerFg',
        padding: '$5',
      }}
    >
      {authError}
    </Box>
  );

  return (
    <Flex
      css={{
        height: '100vh',
        background: '$bgCanvas',
      }}
    >
      <Stack
        direction="column"
        align="start"
        gap={5}
        css={{
          background: '$bgSurface',
          borderRadius: '$medium',
          border: '1px solid $borderSubtle',
          padding: '48px',
          maxWidth: '500px',
          width: '100%',
        }}
      >
        <Stack
          direction="column"
          align="start"
          gap={3}
        >
          <Heading bold size="large">Sign in</Heading>
          <Stack css={{ fontSize: '$small', color: '$fgSubtle' }} direction="row" gap={3}>
            Don’t have an account yet?
            <Link as={RouterLink} to="/signup">
              Sign up
            </Link>
          </Stack>
        </Stack>
        {error}
        <Form
          onSubmit={onLoginSubmit}
          render={({ handleSubmit }) => <LoginForm onSubmit={handleSubmit} loading={false} />}
        />
        <Box css={{ fontSize: '$small' }}>
          <Link as={RouterLink} to="/password-recovery">
            Forgot your password?
          </Link>
        </Box>
      </Stack>
    </Flex>
  );
}
