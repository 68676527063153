import React, { useEffect } from 'react';
import { Form } from 'react-final-form';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { Heading, Link, Stack } from '@tokens-studio/ui';
import { Box, Flex } from '../shared/components';
import { useAuth } from '../context/AuthContext';
import { NotificationTypes, useNotifications } from '../context/NotificationContext';
import PasswordRecoveryForm from '../components/Auth/PasswordRecoveryForm';

export default function PasswordRecovery() {
  const { sendPasswordRecoveryEmail, authError, loggedUser } = useAuth();
  const navigate = useNavigate();
  const { addNotification } = useNotifications();

  useEffect(() => {
    if (loggedUser) {
      navigate('/');
    }
  }, [loggedUser, navigate]);

  const onPasswordRecoverySubmit = async (values: { email: string }) => {
    const { email } = values;
    if (email) {
      const success = await sendPasswordRecoveryEmail(email);

      if (success) {
        addNotification(NotificationTypes.SUCCESS, 'Password recovery email successfully sent!');
      }
    }
  };

  const error = authError && (
    <Box
      css={{
        marginBottom: '$5',
        textAlign: 'center',
        background: '$dangerBg',
        border: '1px solid $dangerBorder',
        fontSize: '$xsmall',
        borderRadius: '$medium',
        color: '$dangerFg',
        padding: '$5',
      }}
    >
      {authError}
    </Box>
  );

  return (
    <Flex
      css={{
        height: '100vh',
        background: '$bgCanvas',
      }}
    >
      <Stack
        direction="column"
        align="start"
        gap={5}
        css={{
          background: '$bgSurface',
          borderRadius: '$medium',
          border: '1px solid $borderSubtle',
          padding: '48px',
          maxWidth: '500px',
          width: '100%',
        }}
      >
        <Stack
          direction="column"
          align="start"
          gap={3}
        >
          <Heading bold size="large">Password recovery</Heading>
        </Stack>
        {error}
        <Form
          onSubmit={onPasswordRecoverySubmit}
          render={({ handleSubmit }) => <PasswordRecoveryForm onSubmit={handleSubmit} loading={false} />}
        />
        <Box css={{ fontSize: '$small' }}>
          <Link as={RouterLink} to="/login">
            Sign in
          </Link>
        </Box>
      </Stack>
    </Flex>
  );
}
