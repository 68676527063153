import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTokens } from '../context/TokensContext';

export default () => {
  const { sets } = useTokens();
  const { selectedTokenSet } = useParams();

  return useMemo(() => {
    if (!sets || !selectedTokenSet) return null;
    return sets[selectedTokenSet];
  }, [sets, selectedTokenSet]);
};
