import { Box } from '@tokens-studio/ui';
import React, { useMemo } from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { useTokensTableState } from '../../../context/TokensTableContext';
import { TokenTypes } from '../../../ft-utils/constants/TokenTypes';
import { SingleToken } from '../../../ft-utils/types/tokens';
import useSelectedTokens from '../../../hooks/useSelectedTokens';
import { useUpdateTokens } from '../../../hooks/useUpdateTokens';
import { Flex } from '../../../shared/components';
import BorderInput from '../../AddEditToken/TokenValue/BorderInput';
import ColorInput from '../../AddEditToken/TokenValue/ColorInput';
import CompositionInput from '../../AddEditToken/TokenValue/CompositionInput';
import GenericInput from '../../AddEditToken/TokenValue/GenericInput';
import TypographyInput from '../../AddEditToken/TokenValue/TypographyInput';
import DescriptionForm from './DescriptionInput';
import EditPopover from './EditPopover';
import SubmitFooter from './SubmitFooter';
import BoxShadowInput from '../../AddEditToken/TokenValue/BoxShadowInput';
import Scroll from '../../../shared/components/Scroll';
import { EditableTableColumns } from '../../../types/EditableTableColumns';
import ModifierInputs from '../../AddEditToken/ModifierInputs';

export default function BulkEditForm() {
  const { bulkEditPopoverVisible, setBulkEditPopoverVisible, editedProperty, rowSelection, setRowSelection } =
    useTokensTableState();
  const { updateTokens } = useUpdateTokens();

  const selection = useMemo(() => Object.keys(rowSelection), [rowSelection]);

  const onSubmit = async (values: SingleToken) => {
    await updateTokens(selection, values);
    setBulkEditPopoverVisible(false);
    setRowSelection({});
  };

  const selectedTokens = useSelectedTokens();

  const selectedTokensType = useMemo(() => (selectedTokens[0] ? selectedTokens[0].type : null), [selectedTokens]);

  if (!bulkEditPopoverVisible) return null;

  const getInputs = () => {
    if (editedProperty === EditableTableColumns.DESCRIPTION) {
      return (
        <Box css={{ width: '100%' }}>
          <DescriptionForm />
        </Box>
      );
    }

    if (editedProperty === EditableTableColumns.VALUE && selectedTokensType) {
      switch (selectedTokensType) {
        case TokenTypes.BOX_SHADOW:
          return (
            <Scroll height="100%">
              <Box css={{ paddingRight: '$6' }}>
                <BoxShadowInput />
              </Box>
            </Scroll>
          );
        case TokenTypes.TYPOGRAPHY:
          return (
            <Box css={{ width: '100%' }}>
              <TypographyInput />
            </Box>
          );
        case TokenTypes.BORDER:
          return (
            <Box css={{ width: '100%' }}>
              <BorderInput />
            </Box>
          );
        case TokenTypes.COMPOSITION:
          return (
            <Scroll height="100%">
              <Box css={{ paddingRight: '$6' }}>
                <Box css={{ fontSize: '$small', fontWeight: '$sansBold', marginBottom: '$5' }}>Composition values</Box>
                <CompositionInput />
              </Box>
            </Scroll>
          );
        case TokenTypes.COLOR:
          return (
            <Box css={{ width: '100%' }}>
              <Box css={{ fontSize: '$small', fontWeight: '$sansBold', marginBottom: '$5' }}>Color value</Box>
              <ColorInput showLabel={false} />
            </Box>
          );
        case TokenTypes.SIZING:
        case TokenTypes.OPACITY:
        case TokenTypes.BORDER_RADIUS:
        case TokenTypes.BORDER_WIDTH:
        case TokenTypes.SPACING:
        default:
          return (
            <Box css={{ width: '100%' }}>
              <Box css={{ fontSize: '$small', fontWeight: '$sansBold', marginBottom: '$5' }}>Value</Box>
              <GenericInput showLabel={false} tokenTypeOrProp={selectedTokensType} />
            </Box>
          );
      }
    }

    if (editedProperty === EditableTableColumns.MODIFIER) {
      return (
        <Box css={{ width: '100%' }}>
          <Box css={{ fontSize: '$small', fontWeight: '$sansBold', marginBottom: '$5' }}>Modifier</Box>
          <ModifierInputs />
        </Box>
      );
    }

    return null;
  };

  return (
    <Box css={{ position: 'absolute', bottom: '100px', left: '50%', transform: 'translate(-50%, 0px)' }}>
      <EditPopover>
        <Form
          onSubmit={onSubmit}
          mutators={{ ...arrayMutators }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Flex
                css={{
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '$4',
                  paddingBottom: '$5',
                  minWidth: selectedTokensType === TokenTypes.COMPOSITION ? '450px' : '400px',
                  maxHeight: '500px',
                }}
              >
                {getInputs()}
              </Flex>
              <SubmitFooter onCancel={() => setBulkEditPopoverVisible(false)} />
            </form>
          )}
        />
      </EditPopover>
    </Box>
  );
}
