import React from 'react';
import { SingleOpacityToken } from '../../ft-utils/types/tokens';
import { Box } from '../../shared/components';
import GenericToken from './GenericToken';
import TokenPreview from './partials/TokenPreview';

export default function OpacityToken(props: SingleOpacityToken) {
  const { value } = props;
  return (
    <>
      <TokenPreview>
        <Box
          css={{
            width: '64px',
            height: '64px',
            border: '1px solid $borderMuted',
            borderRadius: '$medium',
            background: `rgba(255, 255, 255, ${value})`,
            marginBottom: '$3',
          }}
        />
      </TokenPreview>
      <GenericToken {...props} />
    </>
  );
}
