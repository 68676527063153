import { DotFilledIcon, EyeClosedIcon, LinkNone1Icon } from '@radix-ui/react-icons';
import { ContextMenu } from '@tokens-studio/ui';
import React from 'react';
import { useParams } from 'react-router-dom';
import { NotificationTypes, useNotifications } from '../../context/NotificationContext';
import { useSync } from '../../context/SyncContext';
import { useTokens } from '../../context/TokensContext';
import { TokenSetStatus } from '../../ft-utils/constants/TokenSetStatus';
import { useDeleteSet } from '../../hooks/useDeleteSet';
import NavItem from '../../shared/components/NavItem';
import updateUsedTokenSets from '../../utils/updateUsedTokenSets';

const contextMenuItems = [
  {
    label: 'Enabled',
    value: 'enabled',
  },
  {
    label: 'Disabled',
    value: 'disabled',
  },
  {
    label: 'Source',
    value: 'source',
  },
];

interface TokenSetsNavItemProps {
  setPath: string;
  tokenSetState: TokenSetStatus;
  level: number;
}

export default function TokenSetsNavItem({ setPath, tokenSetState, level }: TokenSetsNavItemProps) {
  const { selectedTokenSet } = useParams();
  const { addNotification } = useNotifications();
  const { tokenData } = useTokens();
  const { pluginIsConnected, allowEdits } = useSync();
  const pathArr = setPath.split('/');
  const name = pathArr[pathArr.length - 1];
  const { deleteSet } = useDeleteSet();

  if (!tokenData) {
    return null;
  }

  const getStateIcon = () => {
    switch (tokenSetState) {
      case TokenSetStatus.ENABLED:
        return null;
      case TokenSetStatus.DISABLED:
        return <EyeClosedIcon />;
      case TokenSetStatus.SOURCE:
        return <LinkNone1Icon />;
      default:
        return <EyeClosedIcon />;
    }
  };

  const onTokenSetStateChange = (tokenSet: string, value: string) => {
    if (pluginIsConnected || allowEdits) {
      const newTokensetState = value as TokenSetStatus;

      updateUsedTokenSets(tokenData, tokenSet, newTokensetState);
    } else {
      addNotification(
        NotificationTypes.ERORR,
        'Cannot edit. Please make sure Tokens Studio plugin is opened and sync is turned on'
      );
    }
  };

  return (
    <ContextMenu key={setPath}>
      <ContextMenu.Trigger>
        <NavItem
          to={`/${encodeURIComponent(setPath)}`}
          value={name}
          selected={selectedTokenSet === setPath}
          icon={getStateIcon()}
          level={level}
          hidden={tokenSetState === TokenSetStatus.DISABLED || !tokenSetState}
        />
      </ContextMenu.Trigger>
      <ContextMenu.Portal>
        <ContextMenu.Content>
          <ContextMenu.RadioGroup
            value={tokenSetState}
            onValueChange={(val) => {
              onTokenSetStateChange(setPath, val);
            }}
          >
            {contextMenuItems.map((itm) => (
              <ContextMenu.RadioItem key={itm.value} value={itm.value}>
                <ContextMenu.ItemIndicator>
                  <DotFilledIcon />
                </ContextMenu.ItemIndicator>
                {itm.label}
              </ContextMenu.RadioItem>
            ))}
          </ContextMenu.RadioGroup>
          <ContextMenu.Separator css={{ background: '$borderMuted' }} />
          <ContextMenu.Item
            onClick={() => {
              deleteSet(setPath);
            }}
          >
            Delete
          </ContextMenu.Item>
        </ContextMenu.Content>
      </ContextMenu.Portal>
    </ContextMenu>
  );
}
