import { MoonIcon, SunIcon, Link1Icon, LinkBreak1Icon, ExitIcon } from '@radix-ui/react-icons';
import { useCallback } from 'react';
import { Stack, Box, Button } from '@tokens-studio/ui';
import { useSync } from '../context/SyncContext';
import { useTheme } from '../hooks/useTheme';
import { useAuth } from '../context/AuthContext';

export default function UserConnectionArea() {
  const { pluginIsConnected } = useSync();
  const { signOut, loggedUser } = useAuth();

  const {
    dispatch,
    state: { theme },
  } = useTheme();
  const onToggleTheme = useCallback(() => {
    dispatch({ type: 'toggle' });
  }, [dispatch]);

  return (
    <Stack direction="column" gap={1} css={{ padding: '$4', borderTop: '1px solid $borderMuted' }}>
      <Stack
        direction="column"
        gap={1}
        css={{
          backgroundColor: pluginIsConnected ? '$successBg' : '$dangerBg',
          padding: '$3 $5',
          fontSize: '$xsmall',
          borderRadius: '$medium',
          color: pluginIsConnected ? '$successFg' : '$dangerFg',
          marginBottom: '$3',
        }}
      >
        <Stack direction="row" align="center" gap={3} css={{ fontWeight: '$sansBold' }}>
          {pluginIsConnected ? <Link1Icon /> : <LinkBreak1Icon />}
          {pluginIsConnected ? 'Connected' : 'Not connected'}
        </Stack>
        {pluginIsConnected ? 'Realtime-sync with plugin enabled' : 'Cannot reach plugin'}
        {loggedUser && (
          <Box css={{ fontWeight: '$sansRegular', fontSize: '$xsmall', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {loggedUser.email}
          </Box>
        )}
      </Stack>
      <Button
        icon={theme === 'light' ? <MoonIcon /> : <SunIcon />}
        variant="invisible"
        size="small"
        onClick={onToggleTheme}
      >
        {theme === 'light' ? 'Switch to dark theme' : 'Switch to light theme'}
      </Button>
      <Button variant="invisible" size="small" icon={<ExitIcon />} onClick={signOut}>
        Sign out
      </Button>
    </Stack>
  );
}
