import { Stack } from '@tokens-studio/ui';
import React, { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Box } from '../../shared/components';
import { styled } from '../../stitches.config';
import replaceGlobally from '../../utils/replaceGlobally';

interface TokenNavItemProps {
  children: React.ReactNode;
  tokenKey: string;
  level: number;
  icon?: React.ReactNode;
}

const StyledLink = styled(Link, {
  textDecoration: 'none',
  display: 'flex',
  padding: '$3 $5 $3 0',
  alignItems: 'center',
  borderRadius: '$medium',
  position: 'relative',
  '&:hover': {
    backgroundColor: '$bgSubtle',
  },
  '&:focus-visible': {
    boxShadow: '$focus',
  }
});

export default function TokenNavItem({ children, tokenKey, level, icon }: TokenNavItemProps) {
  const { selectedTokenSet, selectedToken } = useParams();
  const safeTokenKey = useMemo(() => replaceGlobally(tokenKey, '/', '.'), [tokenKey]);

  return (
    <StyledLink
      to={`/${encodeURIComponent(selectedTokenSet ?? '')}/${encodeURIComponent(safeTokenKey)}`}
      key={tokenKey}
      css={{ background: selectedToken === safeTokenKey ? '$accentBg' : 'transparent' }}
    >
      <Box css={{ width: '4px', position: 'absolute', left: 0, height: '50%', background: selectedToken === safeTokenKey ? '$accentDefault' : 'transparent' }} />

      <Stack
        direction="row"
        gap={1}
        justify="between"
        css={{
          width: '100%',
          color: '$fgDefault',
          fontSize: '$small',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          minWidth: 0,
          paddingLeft: `${level * 12}px`
        }}
      >
        {children}
        {icon && <Box css={{ width: '16px', height: '16px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{icon}</Box>}
      </Stack>
    </StyledLink>
  );
}
