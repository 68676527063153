import React from 'react';
import { useUIState } from '../../context/UIState';
import { Select } from '../../shared/components';
import { SelectItemValue } from '../../shared/components/Select';
import { GroupBy } from '../../types/table';

const selectItems: SelectItemValue[] = [
  { value: GroupBy.TOKEN_TYPE, label: 'Token type', id: GroupBy.TOKEN_TYPE },
  { value: GroupBy.TOKEN_PATH, label: 'Token path', id: GroupBy.TOKEN_PATH },
  { value: GroupBy.NO_GROUPING, label: 'No grouping', id: GroupBy.NO_GROUPING },
];

export default function GroupBySelector() {
  const { tableGroupBy, setTableGroupBy, setNestingLevel } = useUIState();

  const onChange = (value: string) => {
    if (value === GroupBy.TOKEN_TYPE) {
      setNestingLevel(0);
    } else if (value === GroupBy.TOKEN_PATH) {
      setNestingLevel(2);
    }

    setTableGroupBy(value !== GroupBy.NO_GROUPING ? [value] : []);
  };

  return (
    <Select
      items={selectItems}
      label="Group rows"
      placeHolder=""
      value={tableGroupBy[0] || GroupBy.NO_GROUPING}
      onChange={onChange}
    />
  );
}
