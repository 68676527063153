import { useMemo } from 'react';
import { useField, useFormState } from 'react-final-form';
import { Tooltip } from '@tokens-studio/ui';
import { useTokens } from '../../context/TokensContext';
import { SingleColorToken, SingleToken } from '../../ft-utils/types/tokens';
import useModifiedColor from '../../hooks/useModifiedColor';
import { Box, Flex, Label } from '../../shared/components';
import useModifiedDisplayColor from '../../hooks/useModifiedDisplayColor';
import useResolvedTokenValue from '../../hooks/useResolvedTokenValue';
import { TokenTypes } from '../../ft-utils/constants/TokenTypes';

export default function ModifiedColor() {
  const { loading } = useTokens();
  const { values } = useFormState<SingleToken>();
  const {
    input: { value },
  } = useField('value');

  const modifier = useMemo(() => values.$extensions?.['studio.tokens']?.modify, [values]);
  const colorResolvedValue =
    (useResolvedTokenValue(value, TokenTypes.COLOR, true) as SingleColorToken['value']) ?? value;

  const modifiedColor = useModifiedColor(colorResolvedValue, modifier, true);

  const displayColor = useModifiedDisplayColor(modifiedColor);

  if (loading || !modifier) {
    return null;
  }

  return displayColor && modifiedColor ? (
    <Flex css={{ gap: '$4', width: '100%', justifyContent: 'flex-start' }}>
      <Box css={{ fontSize: '$base', fontWeight: '$sansBold', color: '$fgDefault' }}>Result:</Box>
      <Box
        css={{
          fontWeight: '$sansRegular',
          fontSize: '$small',
          backgroundColor: '$accentBg',
          padding: '$1 $2',
          border: '1px solid $accentBorder',
          borderRadius: '$medium',
          color: '$accentDefault',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '$2',
        }}
      >
        <Box
          css={{
            width: '16px',
            height: '16px',
            background: displayColor || '',
            borderRadius: '$small',
            border: '1px solid $borderMuted',
            flexShrink: 0,
          }}
        />

        <Tooltip side="bottom" label={modifiedColor || ''}>
          <Label
            regular
            css={{ color: '$accentDefault', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
          >
            {modifiedColor}
          </Label>
        </Tooltip>
      </Box>
    </Flex>
  ) : null;
}
