import { TokenTypes } from './TokenTypes';

const tokenTypes = {
  sizing: {
    label: 'Size',
    property: 'Size',
    type: TokenTypes.SIZING,
    schema: {
      options: {
        description: '',
      },
    },
  },
  spacing: {
    label: 'Space',
    property: 'Value',
    type: TokenTypes.SPACING,
    schema: {
      options: {
        description: '',
      },
    },
  },
  color: {
    label: 'Color',
    property: 'Color',
    type: TokenTypes.COLOR,
    schema: {
      value: 'color',
      options: {
        description: '',
      },
    },
    help: "If a (local) style is found with the same name it will match to that, if not, will use hex value. Use 'Create Style' to batch-create styles from your tokens (e.g. in your design library). In the future we'll load all 'remote' styles and reference them inside the JSON.",
  },
  borderRadius: {
    label: 'Border Radius',
    property: 'Border Radius',
    type: TokenTypes.BORDER_RADIUS,
    schema: {
      options: {
        description: '',
      },
    },
  },
  borderWidth: {
    label: 'Border Width',
    property: 'Border Width',
    type: TokenTypes.BORDER_WIDTH,
    explainer: 'Enter as a number, e.g. 4',
    schema: {
      options: {
        description: '',
      },
    },
  },
  opacity: {
    label: 'Opacity',
    property: 'Opacity',
    type: TokenTypes.OPACITY,
    explainer: 'Set as 50% or 0.5',
    schema: {
      options: {
        description: '',
      },
    },
  },
  boxShadow: {
    label: 'Box Shadow',
    property: 'Box Shadow',
    type: TokenTypes.BOX_SHADOW,
    schema: {
      value: {
        x: '0',
        y: '0',
        blur: '0',
        spread: '0',
        color: '#000000',
        type: 'dropShadow',
      },
      options: {
        description: '',
      },
    },
  },
  typography: {
    label: 'Typography',
    property: 'Typography',
    type: TokenTypes.TYPOGRAPHY,
    schema: {
      value: {
        fontFamily: 'Inter',
        fontWeight: 'Regular',
        lineHeight: 'AUTO',
        fontSize: '18',
        letterSpacing: '0%',
        paragraphSpacing: '0',
        paragraphIndent: '0',
        textDecoration: 'none',
        textCase: 'none',
      },
      options: {
        description: '',
      },
    },
    help: "If a (local) style is found with the same name it will match to that, if not, will use raw font values. Use 'Create Style' to batch-create styles from your tokens (e.g. in your design library). In the future we'll load all 'remote' styles and reference them inside the JSON.",
  },
  fontFamilies: {
    help: 'Only works in combination with a Font Weight',
    label: 'Font Family',
    property: 'Font Family',
    type: TokenTypes.FONT_FAMILIES,
    schema: {
      options: {
        description: '',
      },
    },
  },
  fontWeights: {
    help: 'Only works in combination with a Font Family',
    label: 'Font Weight',
    property: 'Font Weight',
    type: TokenTypes.FONT_WEIGHTS,
    schema: {
      options: {
        description: '',
      },
    },
  },
  lineHeights: {
    label: 'Line Height',
    explainer: 'e.g. 100% or 14',
    property: 'Line Height',
    type: TokenTypes.LINE_HEIGHTS,
    schema: {
      options: {
        description: '',
      },
    },
  },
  fontSizes: {
    label: 'Font Size',
    property: 'Font Size',
    type: TokenTypes.FONT_SIZES,
    schema: {
      options: {
        description: '',
      },
    },
  },
  letterSpacing: {
    label: 'Letter Spacing',
    property: 'Letter Spacing',
    type: TokenTypes.LETTER_SPACING,
    schema: {
      options: {
        description: '',
      },
    },
  },
  paragraphSpacing: {
    label: 'Paragraph Spacing',
    property: 'ParagraphSpacing',
    type: TokenTypes.PARAGRAPH_SPACING,
    schema: {
      options: {
        description: '',
      },
    },
  },
  textCase: {
    label: 'Text Case',
    property: 'TextCase',
    type: TokenTypes.TEXT_CASE,
    schema: {
      options: {
        description: '',
      },
    },
    explainer: 'none | uppercase | lowercase | capitalize',
  },
  textDecoration: {
    label: 'Text Decoration',
    property: 'TextDecoration',
    type: TokenTypes.TEXT_DECORATION,
    schema: {
      options: {
        description: '',
      },
    },
    explainer: 'none | underline | line-through',
  },
  composition: {
    label: 'Composition',
    property: 'Composition',
    type: TokenTypes.COMPOSITION,
    schema: {
      value: {},
      options: {
        description: '',
      },
    },
  },
  border: {
    label: 'Border',
    property: 'Border',
    type: TokenTypes.BORDER,
    schema: {
      options: {
        description: '',
      },
    },
  },
  asset: {
    label: 'Assets',
    property: 'Asset',
    type: TokenTypes.ASSET,
    explainer: 'Public URL of your asset',
    schema: {
      options: {
        description: '',
      },
    },
  },
  dimension: {
    label: 'Dimension',
    property: 'Dimension',
    type: TokenTypes.DIMENSION,
    schema: {
      options: {
        description: '',
      },
    },
  },
  other: {
    label: 'Other',
    property: 'other',
    type: TokenTypes.OTHER,
    schema: {
      options: {
        description: '',
      },
    },
  },
};
export default tokenTypes;
