import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Editor, { Monaco } from '@monaco-editor/react';
import { useParams } from 'react-router-dom';
import { Button } from '@tokens-studio/ui';
import stringifyTokens from '../ft-utils/stringifyTokens';
import { AnyTokenList } from '../ft-utils/types/tokens';
import monacoTheme from '../utils/monacoTheme';
import { Box, Flex } from '../shared/components';
import updateTokenSet from '../utils/updateTokenSet';
import { useTokens } from '../context/TokensContext';
import { NotificationTypes, useNotifications } from '../context/NotificationContext';
import { useSync } from '../context/SyncContext';
import { Mixpanel } from '../Mixpanel';

interface TokenCodeViewProps {
  tokens: AnyTokenList;
}

export default function TokenCodeView({ tokens }: TokenCodeViewProps) {
  const { tokenData } = useTokens();
  const [saving, setSaving] = useState(false);
  const { addNotification } = useNotifications();
  const { selectedTokenSet } = useParams();
  const [content, setContent] = useState(stringifyTokens(tokens));
  const { pluginIsConnected, allowEdits } = useSync();

  const onChange = useCallback((value: string | undefined) => {
    if (value) {
      setContent(value);
    }
  }, []);

  useEffect(() => {
    if (tokens) {
      setContent(stringifyTokens(tokens));
    }
  }, [tokens]);

  function handleEditorWillMount(monaco: Monaco) {
    // @ts-ignore
    monaco.editor.defineTheme('my-theme', monacoTheme);
  }

  const onSaveTokens = async () => {
    if (pluginIsConnected || allowEdits) {
      if (tokenData && selectedTokenSet) {
        setSaving(true);
        try {
          await updateTokenSet(tokenData, selectedTokenSet, content);
          Mixpanel.track('Update from JSON');
          addNotification(NotificationTypes.SUCCESS, 'Set updated succesfully!');
        } catch (error) {
          addNotification(NotificationTypes.ERORR, `Error saving JSON file: ${JSON.stringify(error)}`);
        }
        setSaving(false);
      }
    } else {
      addNotification(
        NotificationTypes.ERORR,
        'Cannot edit token set. Please make sure Tokens Studio plugin is opened and sync is turned on'
      );
    }
  };

  const currentTokensString = useMemo(() => stringifyTokens(tokens), [tokens]);

  const hasChanges = useMemo(
    () => content && currentTokensString && content !== currentTokensString,
    [content, currentTokensString]
  );

  const saveChangesButton = (
    <Flex
      css={{
        width: '100%',
        background: 'transparent',
        justifyContent: 'flex-end',
        padding: '$5',
        opacity: hasChanges ? 1 : 0,
        transition: 'opacity 300ms ease',
      }}
    >
      <Button loading={saving} variant="primary" onClick={onSaveTokens}>
        Save token set
      </Button>
    </Flex>
  );

  return (
    <Flex css={{ height: '100%', flexDirection: 'column' }}>
      <Box css={{ width: '100%', flexGrow: 1 }}>
        <Editor
          defaultLanguage="json"
          value={content}
          theme="my-theme"
          beforeMount={handleEditorWillMount}
          onChange={onChange}
        />
      </Box>
      {saveChangesButton}
    </Flex>
  );
}
