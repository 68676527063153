// stitches.config.ts
import { createStitches } from '@stitches/react';
import { lightTheme, darkTheme, core } from '@tokens-studio/tokens';

export const stitchesInstance = createStitches({
  theme: {
    ...darkTheme,
    ...core,
  },
});

const { styled, createTheme, css, keyframes, theme } = stitchesInstance;

const lightThemeMode = createTheme('light-theme', {
  ...lightTheme,
});
const darkThemeMode = createTheme('dark-theme', {
  ...darkTheme,
});

export { styled, css, keyframes, theme, lightThemeMode, darkThemeMode };
