import React, { Fragment } from 'react';
import { Table, flexRender } from '@tanstack/react-table';
import { Token } from '../../context/types';
import { Box, Flex } from '../../shared/components';
import SortingIcon from './SortingIcon';
import { ColumnId } from '../../types/table';

interface TableHeaderProps {
  table: Table<Token>;
}

export default function TableHeader({ table }: TableHeaderProps) {
  return (
    <Flex css={{ backgroundColor: '$bgDefault', justifyContent: 'flex-start', borderBottom: '1px solid $borderMuted' }}>
      {table.getHeaderGroups().map((headerGroup) => (
        <Fragment key={headerGroup.id}>
          {table.getAllColumns().map((col) => {
            const header = headerGroup.headers.find((itm) => itm.column.id === col.id);
            if (header) {
              if (header.column.id === ColumnId.PATH) return null;

              return (
                <Box
                  css={{
                    position: 'relative',
                    display: 'inline-block',
                    fontWeight: '$sansBold',
                    width: header.getSize(),
                    maxWidth: header.getSize(),
                    borderRight: '1px solid $borderMuted',
                  }}
                  key={header.id}
                >
                  <Flex css={{ justifyContent: 'flex-start', padding: '$3 $5' }}>
                    <Box
                      style={{
                        cursor: 'pointer',
                        fontSize: 'small',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <Box style={{ userSelect: 'none' }} onClick={header.column.getToggleSortingHandler()}>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </Box>
                    </Box>
                    <SortingIcon direction={header.column.getIsSorted()} />
                  </Flex>
                  <Box
                    css={{
                      height: '100%',
                      width: '5px',
                      background: header.column.getIsResizing() ? '$accentEmphasis' : 'transparent',
                      position: 'absolute',
                      right: 0,
                      top: 0,
                      cursor: 'col-resize',
                      '&:hover': {
                        background: '$accentEmphasis',
                      },
                    }}
                    onMouseDown={header.getResizeHandler()}
                    onTouchStart={header.getResizeHandler()}
                  />
                </Box>
              );
            }

            return null;
          })}
        </Fragment>
      ))}
    </Flex>
  );
}
