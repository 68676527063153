import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { SingleToken } from '../ft-utils/types/tokens';
import useCopyToClipboard from '../hooks/useCopyToClipboard';
import useSelectedSetTokens from '../hooks/useSelectedSetTokens';
import { ColumnId } from '../types/table';
import { SelectedCell } from './TokensTableContext';

type TokensTableClipboardContextType = {
  copiedItem: SelectedCell | null;
  setCopiedItem: (item: SelectedCell | null) => void;
  copiedData: SingleToken | null;
};

const ClipboardContext = createContext<TokensTableClipboardContextType | undefined>(undefined);

function TokensTableClipboardProvider({ children }: { children: React.ReactNode }) {
  const [copiedItem, setItem] = useState<SelectedCell | null>(null);
  const [copiedData, setCopiedData] = useState<SingleToken | null>(null);

  const tokenArray = useSelectedSetTokens();

  const [copy] = useCopyToClipboard();

  const setCopiedItem = useCallback(
    (item: SelectedCell | null) => {
      if (item) {
        setItem(item);
        const { colId, rowId } = item;
        const token = tokenArray?.find((itm) => itm.name === rowId);
        const copiedValue = {} as SingleToken;

        if (token) {
          if (colId === ColumnId.RAW_VALUE) {
            copiedValue.rawValue = token.value;
          } else {
            copiedValue[colId] = token[colId] ?? (colId === ColumnId.MODIFIER ? {} : '');
          }
        }

        setCopiedData(copiedValue);

        copy(JSON.stringify(copiedValue));
      } else {
        setItem(null);
        copy('');
      }
    },
    [copy, tokenArray]
  );

  const providerValue = useMemo(
    () => ({
      copiedItem,
      setCopiedItem,
      copiedData,
    }),
    [copiedItem, setCopiedItem, copiedData]
  );

  return <ClipboardContext.Provider value={providerValue}>{children}</ClipboardContext.Provider>;
}

function useTokensTableClipboard() {
  const context = useContext(ClipboardContext);

  if (context === undefined) {
    throw new Error('useTokensTableClipboard must be used within a TokensTableClipboardProvider');
  }
  return context;
}

export { TokensTableClipboardProvider, useTokensTableClipboard };
