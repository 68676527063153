import { Field, useField } from 'react-final-form';
import { TokenTypeOrProp } from '../../../ft-utils/types/TokenTypeOrProp';
import { getTokenType } from '../../../hooks/getTokenType';
import useReferences from '../../../hooks/useReferences';
import { Box, Flex, Label } from '../../../shared/components';
import MentionsInput from '../../../shared/components/MentionsInput';
import TokenIcon from '../../TokenIcon';
import TokenResolvedValue from '../TokenResolvedValue/TokenResolvedValue';

interface GenericInputProps {
  tokenTypeOrProp: TokenTypeOrProp;
  showLabel?: boolean;
  label?: string;
  showIcon?: boolean;
  valuePath?: string;
  autoFocus?: boolean;
}

export default function GenericInput({
  tokenTypeOrProp,
  showLabel = true,
  label = 'Value',
  showIcon = true,
  valuePath = 'value',
  autoFocus = false,
}: GenericInputProps) {
  const references = useReferences(tokenTypeOrProp);

  const {
    input: { value },
  } = useField(valuePath);

  return (
    <Box css={{ display: 'flex', flexDirection: 'column', gap: '$3', width: '100%' }}>
      {showLabel && <Label htmlFor={`${tokenTypeOrProp}-generic-value`}>{label}</Label>}
      <Flex css={{ gap: '$4' }}>
        {showIcon && tokenTypeOrProp && <TokenIcon propertyKey={tokenTypeOrProp} />}
        <Box
          css={{
            flexGrow: 1,
            border: '1px solid $borderMuted',
            borderRadius: '$medium',
            backgroundColor: '$inputBg',
            padding: '0 $3',
            gap: '$2',
          }}
        >
          <Field
            name={valuePath}
            render={(fieldProps) => (
              <MentionsInput
                id={`${tokenTypeOrProp}-generic-value`}
                {...fieldProps.input}
                placeholder={`Add ${tokenTypeOrProp ? `${tokenTypeOrProp} or` : ''} {alias}`}
                data={references}
                tokenType={tokenTypeOrProp}
                autoFocus={autoFocus}
              />
            )}
          />
        </Box>

        <TokenResolvedValue type={getTokenType(tokenTypeOrProp)} value={value} />
      </Flex>
    </Box>
  );
}
