type CompositionInputValues = {
  property: string;
  value: string;
};

export function computeCompositionInputForSave(values: CompositionInputValues[]): Record<string, string> {
  if (!Array.isArray(values)) {
    return {};
  }

  return values.reduce((acc, val) => ({ ...acc, [val.property]: val.value }), {});
}
