import { useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { PageHeader, Stack } from '@tokens-studio/ui';
import { useUIState, ViewTypes } from '../context/UIState';
import { Box } from '../shared/components';
import Scroll from '../shared/components/Scroll';
import { createTokensObject } from '../utils/tokens/createTokenObj';
import Filters from './Filters/ViewTypesSelector';
import LevelsSelector from './Filters/LevelsSelector';
import TokenCodeView from './TokenCodeView';
import TokenListView from './TokensListView/TokenListView';
import TokensEmptyScreen from './TokensEmptyScreen';
import CreateNewTokenButton from './CreateNewTokenButton';
import TokenSetName from './TokenSetName';
import TokensTable from './TokensTableView/TokensTable';
import CollapseSidebar from './CollapseSidebar';
import BulkEditBar from './TokensTableView/edit/BulkEditBar';
import BulkEditForm from './TokensTableView/edit/BulkEditForm';
import GroupBySelector from './TokensTableView/GroupBySelector';
import { TokensTableClipboardProvider } from '../context/TokensTableClipboardContext';
import { InlineEditContext } from '../context/InlineEditContext';
import useSelectedSetTokens from '../hooks/useSelectedSetTokens';
import { GroupBy } from '../types/table';

export default function TokenList() {
  const { tokenView, nestingLevel, sidebarCollapsed, tableGroupBy } = useUIState();
  const { selectedTokenSet } = useParams();
  const tokenListContainerRef = useRef<HTMLDivElement>(document.createElement('div'));

  const tokenArray = useSelectedSetTokens();

  const tokensObject = useMemo(() => {
    if (tokenArray) {
      return createTokensObject(tokenArray, nestingLevel, tableGroupBy[0] as GroupBy);
    }
    return {};
  }, [tokenArray, nestingLevel, tableGroupBy]);

  const showEmptyScreen = useMemo(
    () => tokenArray && tokenArray.length === 0 && tokenView !== ViewTypes.JSON,
    [tokenView, tokenArray]
  );

  if (!tokenArray) {
    return null;
  }

  const getTokenListing = () => {
    if (showEmptyScreen) {
      return (
        <Box css={{ height: '100%' }}>
          <TokensEmptyScreen />
        </Box>
      );
    }

    switch (tokenView) {
      case ViewTypes.LIST:
        return (
          tokensObject && (
            <Scroll height="100%">
              <Box css={{ padding: '$7' }}>
                <TokenListView tokensObject={tokensObject} />
              </Box>
            </Scroll>
          )
        );

      case ViewTypes.TABLE:
        return (
          <TokensTableClipboardProvider>
            <InlineEditContext>
              <Scroll width="100%" height="100%" ref={tokenListContainerRef}>
                <TokensTable getParentRef={() => tokenListContainerRef} />
              </Scroll>
              <BulkEditForm />
              <BulkEditBar />
            </InlineEditContext>
          </TokensTableClipboardProvider>
        );
      case ViewTypes.JSON:
        return (
          <Box css={{ height: 'calc(100% - 67px)' }}>
            <TokenCodeView tokens={tokenArray} />
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Stack
      direction="column"
      css={{
        width: '100%',
        overflow: 'hidden',
        height: '100%',
        userSelect: 'none',
      }}
    >
      <PageHeader>
        <PageHeader.Title>
          <Stack direction="row" align="center">
            {sidebarCollapsed && <CollapseSidebar isCollapsed />}
            <TokenSetName tokenSetName={selectedTokenSet ?? ''} />
          </Stack>
        </PageHeader.Title>
        <PageHeader.Actions>
          <Stack direction="row" gap={4} css={{ alignItems: 'center' }}>
            {!showEmptyScreen && tableGroupBy[0] === GroupBy.TOKEN_PATH && <LevelsSelector />}
            {!showEmptyScreen && <GroupBySelector />}
            <Filters />
            {!showEmptyScreen && <CreateNewTokenButton />}
          </Stack>
        </PageHeader.Actions>
      </PageHeader>

      {getTokenListing()}
    </Stack>
  );
}
