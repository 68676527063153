import { SortingFn } from '@tanstack/react-table';
import { TableTokenItem } from '../../ft-utils/types/TableTokenItem';

const valueSortFn: SortingFn<TableTokenItem> = (rowA, rowB) => {
  const valueA = rowA.original.value;
  const valueB = rowB.original.value;

  if (typeof valueA !== 'object' && typeof valueB !== 'object') {
    // Sort alphanumeric for primitive values
    return valueA.localeCompare(valueB);
  }

  if (typeof valueA !== 'object') {
    // Place non-primitive values after primitive values
    return 1;
  }

  if (typeof valueB !== 'object') {
    // Place non-primitive values after primitive values
    return -1;
  }

  return 0;
};

export default valueSortFn;
