import React from 'react';
import { TokenTypes } from '../ft-utils/constants/TokenTypes';
import { Box } from '../shared/components';
import TokenIcon from './TokenIcon';

interface InlineTokenProps {
  type: TokenTypes;
}

export default function InlineTokenSwatch({ type }: InlineTokenProps) {
  return (
    <Box css={{ color: '$fgDefault' }}>
      <TokenIcon propertyKey={type} />
    </Box>
  );
}
