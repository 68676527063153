import React, { createContext, useContext, useEffect, useState } from 'react';
import { Mixpanel } from '../Mixpanel';
import { supabase } from '../supabase';

type User = Record<string, any>;

function identify(userData: User) {
  Mixpanel.identify(userData.id);
  Mixpanel.people.set({
    $email: userData.email,
  });
}

export type AuthContextData = {
  authError: string;
  loggedUser: User | null;
  signInWithLink: (email: string) => Promise<boolean>;
  signInWithEmailAndPassword: (email: string, password: string) => Promise<boolean>;
  signUpWithEmailAndPassword: (email: string, password: string) => Promise<boolean>;
  sendPasswordRecoveryEmail: (email: string) => Promise<boolean>;
  updatePassword: (password: string) => Promise<boolean>;
  signOut: () => Promise<void>;
  fetchingUser: boolean;
};

export const AuthContext = createContext({});

function useAuthProvider() {
  const [loggedUser, setUser] = useState<User | null>(null);
  const [authError, setAuthError] = useState('');
  const [fetchingUser, setFetchingUser] = useState(true);

  useEffect(() => {
    const cleanup = () => {
      Mixpanel.track('Closed second screen - (Page close)');
    };
    Mixpanel.track('Opened second screen - (Page load)');
    window.addEventListener('beforeunload', cleanup);

    return () => {
      window.removeEventListener('beforeunload', cleanup);
    };
  }, []);

  const signInWithLink: AuthContextData['signInWithLink'] = async (email: string): Promise<boolean> => {
    const { error } = await supabase.auth.signInWithOtp({
      email,
    });
    if (!error) {
      return true;
    }
    setAuthError(error.message);
    return false;
  };

  const signInWithEmailAndPassword: AuthContextData['signInWithEmailAndPassword'] = async (email, password) => {
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error || !data) {
      setAuthError(error?.message || 'Error');
      return false;
    }

    setUser(data.user);
    if (data.user?.id) {
      identify(data.user);
    }
    setFetchingUser(false);

    return true;
  };

  const signUpWithEmailAndPassword: AuthContextData['signUpWithEmailAndPassword'] = async (email, password) => {
    const { data, error } = await supabase.auth.signUp({
      email,
      password,
    });

    if (error || !data) {
      setAuthError(error?.message || 'Error');
      return false;
    }

    setUser(data.user);
    if (data.user?.id) {
      identify(data.user);
    }
    setFetchingUser(false);

    return true;
  };

  const sendPasswordRecoveryEmail: AuthContextData['sendPasswordRecoveryEmail'] = async (email) => {
    const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${process.env.REACT_APP_SITE_URL}/reset-password`,
    });

    if (error || !data) {
      setAuthError(error?.message || 'Error');
      return false;
    }

    return true;
  };

  const updatePassword: AuthContextData['updatePassword'] = async (password: string) => {
    const { data, error } = await supabase.auth.updateUser({
      password,
    });

    if (error || !data) {
      setAuthError(error?.message || 'Error');
      return false;
    }

    setUser(data.user);
    return true;
  };

  const signOut: AuthContextData['signOut'] = async () => {
    await supabase.auth.signOut();
    setUser(null);
  };

  useEffect(() => {
    async function setSession() {
      // get session data if there is an active session

      const {
        data: { user },
      } = await supabase.auth.getUser();

      if (user) {
        setUser(user ?? null);
        if (user.id) {
          identify(user);
        }

        // listen for changes to auth
        supabase.auth.onAuthStateChange((event, session) => {
          setUser(session?.user ?? null);
          if (session?.user.id) {
            identify(session.user);
          }
          setFetchingUser(false);
        });
      }

      setFetchingUser(false);
    }

    setSession();

    return () => {};
  }, []);

  return {
    loggedUser,
    signOut,
    signInWithLink,
    signInWithEmailAndPassword,
    signUpWithEmailAndPassword,
    sendPasswordRecoveryEmail,
    updatePassword,
    authError,
    fetchingUser,
  } as AuthContextData;
}

export function AuthProvider({ children }: { children: JSX.Element }) {
  const authApp = useAuthProvider();

  return <AuthContext.Provider value={authApp}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext) as AuthContextData;
}
