import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@tokens-studio/ui';
import { Flex } from '../../shared/components';

export default function TokenFormFooter() {
  const { selectedTokenSet } = useParams();
  const navigate = useNavigate();

  const onCancel = useCallback(() => {
    if (selectedTokenSet) {
      navigate(`/${encodeURIComponent(selectedTokenSet)}`);
    }
  }, [selectedTokenSet, navigate]);
  return (
    <Flex css={{ marginTop: '$8' }}>
      <Flex css={{ gap: '$3', justifyContent: 'space-between', width: '100%' }}>
        <Button size="large" onClick={onCancel} variant="secondary">
          Cancel
        </Button>
        <Button variant="primary" size="large" type="submit">
          Save
        </Button>
      </Flex>
    </Flex>
  );
}
