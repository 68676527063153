import { useEffect, useRef } from 'react';

export default <T extends HTMLElement>(callback: () => void, active: boolean = false) => {
  const ref = useRef<T>(null);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      // TODO: fix me please. target.nodeName workaround for radix select with portal - it detects click outside falsely, same is true for rc-mentions
      if (ref.current && !ref.current.contains(target) && target.nodeName !== 'BODY' && target.nodeName !== 'HTML') {
        if (target.classList.contains('mentions-item')) {
          return;
        }

        callback();
      }
    };

    if (active) {
      document.addEventListener('click', handleClick, true);
    }

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [callback, ref, active]);

  return ref;
};
