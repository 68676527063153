import { Stack } from '@tokens-studio/ui';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import set from 'set-value';
import { useTokens } from '../../context/TokensContext';
import { Box } from '../../shared/components';
import isEmptyObject from '../../utils/isEmptyObject';
import CollapseSidebar from '../CollapseSidebar';
import CreateNewSet from './CreateNewSet';
import ThemeSelector from './ThemeSelector';
import { AccordionContent, AccordionItem, AccordionRoot, AccordionTrigger } from './TokensAccordion';
import TokenSetsNavItem from './TokenSetsNavItem';
import { useTokenSetsStatus } from '../../hooks/useTokenSetsStatus';

type NestedNavStructure = Record<string, any>;

export default function TokenSetsNav() {
  const { selectedTokenSet } = useParams();
  const { sets, themes, tokenData } = useTokens();

  const navigate = useNavigate();

  useEffect(() => {
    if (sets && !isEmptyObject(sets) && !selectedTokenSet) {
      navigate(`/${encodeURIComponent(Object.keys(sets)[0])}`);
    }
  }, [selectedTokenSet, sets, navigate]);

  const usedTokenSetsData = useTokenSetsStatus();

  const setKeys = useMemo(() => (sets ? Object.keys(sets) : []), [sets]);

  const nestedNavStructure = useMemo<NestedNavStructure>(
    () =>
      setKeys.reduce((acc, current) => {
        const path = current.replaceAll('/', '.');

        set(acc, path, 'set');
        return acc;
      }, {}),
    [setKeys]
  );

  if (!tokenData) {
    return null;
  }

  const getContent = (navObject: NestedNavStructure, path?: string) =>
    Object.keys(navObject).map((key) => {
      const child = navObject[key];
      const setPath = path ? `${path}/${key}` : key;
      const level = setPath.split('/').length;

      if (typeof child === 'object') {
        return (
          <AccordionRoot type="single" defaultValue={key} collapsible key={setPath}>
            <AccordionItem value={key}>
              <AccordionTrigger level={level}>{key}</AccordionTrigger>
              <AccordionContent>{getContent(child, setPath)}</AccordionContent>
            </AccordionItem>
          </AccordionRoot>
        );
      }
      if (typeof child === 'string') {
        return (
          <TokenSetsNavItem level={level} tokenSetState={usedTokenSetsData[setPath]} setPath={setPath} key={setPath} />
        );
      }
      return null;
    });

  return (
    <Box css={{ width: '100%', flexGrow: 1 }}>
      <Stack direction="row" justify="between" align="center" css={{ padding: '0 $3' }}>
        <Stack direction="row" justify="start" css={{ paddingLeft: '$7' }}>
          <ThemeSelector />
        </Stack>
        {themes && <CollapseSidebar isCollapsed={false} />}
      </Stack>
      <Box css={{ padding: '0 $2' }}>{getContent(nestedNavStructure)}</Box>
      <CreateNewSet />
    </Box>
  );
}
