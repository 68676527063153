import { useSearchParams } from 'react-router-dom';

export function useTokenSearchParams() {
  const [searchParams] = useSearchParams();

  const isNewToken = searchParams.get('new') === '1';
  const tokenPrefix = searchParams.get('prefix');
  const isPanelOpen = searchParams.get('panel') === '1';
  const token = searchParams.get('token');

  return {
    isNewToken,
    tokenPrefix,
    isPanelOpen,
    token,
  };
}
