import { SingleCompositionToken } from '../../../ft-utils/types/tokens';
import { Box, Flex, InlineBox } from '../../../shared/components';
import { ComplexTokenProp } from '../partials/ComplexTokenProp';

export default function CompositionValue(props: SingleCompositionToken) {
  const { value } = props;

  if (typeof value === 'string') {
    return <Box css={{ padding: '$3 $5' }}>{value}</Box>;
  }

  if (value) {
    return (
      <Flex
        css={{
          flexDirection: 'column',
          gap: '$2',
          alignItems: 'flex-start',
          padding: '$3 $5',
        }}
      >
        {Object.keys(value).map((key) => {
          const val = value[key];
          return (
            <ComplexTokenProp key={key}>
              <InlineBox
                css={{ color: '$fgSubtle', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
              >
                {`${key}: `}
              </InlineBox>
              <InlineBox
                css={{ color: '$fgDefault', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
              >
                {val}
              </InlineBox>
            </ComplexTokenProp>
          );
        })}
      </Flex>
    );
  }

  return null;
}
