import { Column, ColumnSizingState } from '@tanstack/react-table';
import { Token } from '../../context/types';
import { ColumnId } from '../../types/table';

const defaultSizing = {
  [ColumnId.SELECT]: 80,
  [ColumnId.NAME]: 150,
  [ColumnId.VALUE]: 150,
  [ColumnId.RAW_VALUE]: 200,
  [ColumnId.TYPE]: 80,
  [ColumnId.OPTIONS]: 55,
  [ColumnId.PATH]: 0,
};

export default function calculateColumnSizing(
  containerWidth: number,
  savedSizing: ColumnSizingState,
  columns: Column<Token, unknown>[]
): { [key: string]: number } {
  // Merge default sizing with saved sizing
  const sizing = { ...defaultSizing, ...savedSizing };

  // Calculate available width by subtracting the width of columns with defined size
  let availableWidth = containerWidth;
  for (const [columnId, size] of Object.entries(sizing)) {
    availableWidth -= size;
  }

  // Calculate size for columns with no defined size by dividing the available width equally among them
  const columnsWithDefinedSize = Object.keys(sizing);
  const columnsWithNoSize = columns
    .map((col) => col.id)
    .filter((columnId) => !columnsWithDefinedSize.includes(columnId));

  const widthPerColumn = availableWidth / columnsWithNoSize.length;
  for (const colId of columnsWithNoSize) {
    sizing[colId] = widthPerColumn;
  }

  return sizing;
}
