import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { TokenTypes } from '../../ft-utils/constants/TokenTypes';
import ColorToken from './ColorToken';
import SimpleBorderToken from './SimpleBorderToken';
import OpacityToken from './OpacityToken';
import GenericToken from './GenericToken';
import DimensionToken from './DimensionToken';
import ShadowToken from './ShadowToken';
import TypographyToken from './TypographyToken';
import BorderToken from './BorderToken';
import CompositionToken from './CompositionToken';
import { styled } from '../../stitches.config';
import { LIST_VIEW_TOKEN_TYPOGRAPHY_WIDTH, LIST_VIEW_TOKEN_WIDTH } from '../../constants';
import { SingleToken } from '../../ft-utils/types/tokens';

const StyledLink = styled(Link, {
  display: 'flex',
});

export default function Token({ to, tokenData }: { to: string; tokenData: SingleToken }) {
  const { type } = tokenData;

  const tokenRef = useRef<HTMLAnchorElement>(null);

  function getToken() {
    switch (type) {
      case TokenTypes.COLOR:
        return <ColorToken {...tokenData} />;
      case TokenTypes.BORDER_RADIUS:
      case TokenTypes.BORDER_WIDTH:
        return <SimpleBorderToken {...tokenData} />;
      case TokenTypes.OPACITY:
        return <OpacityToken {...tokenData} />;
      case TokenTypes.SIZING:
      case TokenTypes.SPACING:
        return <DimensionToken {...tokenData} />;
      case TokenTypes.BOX_SHADOW:
        return <ShadowToken {...tokenData} />;
      case TokenTypes.TYPOGRAPHY:
        return <TypographyToken {...tokenData} />;
      case TokenTypes.BORDER:
        return <BorderToken {...tokenData} />;
      case TokenTypes.COMPOSITION:
        return <CompositionToken {...tokenData} />;
      default:
        return <GenericToken {...tokenData} />;
    }
  }

  return (
    <StyledLink
      to={to}
      css={{
        background: '$bgSurface',
        borderRadius: '$medium',
        padding: '$5',
        flexDirection: 'column',
        aspectRatio: type === TokenTypes.TYPOGRAPHY ? '2 / 1' : '1 / 1',
        width: type === TokenTypes.TYPOGRAPHY ? `${LIST_VIEW_TOKEN_TYPOGRAPHY_WIDTH}px` : `${LIST_VIEW_TOKEN_WIDTH}px`,
        cursor: 'pointer',
        transition: 'background .1s',
        textDecoration: 'none',
        '&:hover': {
          background: '$bgSubtle',
        },
        '&:focus-visible': {
          boxShadow: '$focus',
        },
      }}
      ref={tokenRef}
    >
      {getToken()}
    </StyledLink>
  );
}
