import { ContextMenu } from '@tokens-studio/ui';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useDeleteTokens } from '../../hooks/useDeleteTokens';
import { Box } from '../../shared/components';
import replaceGlobally from '../../utils/replaceGlobally';
import Token from './Token';
import TokensHeader from './TokensHeader';

interface TokenListViewProps {
  tokensObject: Record<string, any>;
}

export default function TokenListView({ tokensObject }: TokenListViewProps) {
  const { selectedTokenSet } = useParams();
  const deleteTokens = useDeleteTokens();

  const getContent = (tokensObj: Record<string, any>, path?: string): any => {
    if (!tokensObject) {
      return null;
    }
    return Object.keys(tokensObj).map((token) => {
      const tokenData = tokensObj[token];
      const key = path ? `${path}.${token}` : token;

      if (token === selectedTokenSet) {
        return getContent(tokenData, key);
      }

      if (!tokenData.type) {
        return (
          <Box key={key} css={{ display: 'flex', width: '100%', flexDirection: 'row', flexWrap: 'wrap', gap: '$3' }}>
            <TokensHeader path={key} />
            {getContent(tokenData, key)}
          </Box>
        );
      }

      const doNotationKey = replaceGlobally(key, '/', '.');

      return (
        <ContextMenu key={`${selectedTokenSet}-${key}`}>
          <ContextMenu.Trigger>
            <Token to={`${encodeURIComponent(doNotationKey)}?panel=1`} tokenData={tokenData} />
          </ContextMenu.Trigger>
          <ContextMenu.Portal>
            <ContextMenu.Content>
              <ContextMenu.Item
                onClick={() => {
                  deleteTokens([doNotationKey]);
                }}
              >
                Delete
              </ContextMenu.Item>
            </ContextMenu.Content>
          </ContextMenu.Portal>
        </ContextMenu>
      );
    });
  };
  return (
    <Box css={{ display: 'flex', width: '100%', flexDirection: 'row', flexWrap: 'wrap', gap: '$3' }}>
      {getContent(tokensObject)}
    </Box>
  );
}
