import { TokenData } from '../context/types';
import convertToTokenArray from '../ft-utils/convertToTokenArray';
import { supabase } from '../supabase';

export default async function updateTokenSet(tokenState: TokenData, selectedTokenSet: string, tokenSetContent: string) {
  const tokenArray = convertToTokenArray({ tokens: JSON.parse(tokenSetContent) });

  const newSets = {
    ...tokenState.synced_data.sets,
    [selectedTokenSet]: tokenArray,
  };

  const newData = {
    themes: tokenState.synced_data.themes,
    usedTokenSets: tokenState.synced_data.usedTokenSets,
    sets: newSets,
  };

  await supabase
    .from('tokens')
    .update({ synced_data: JSON.stringify(newData), last_updated_by: 'second-screen' })
    .eq('id', tokenState.id);
}
