import { ArrowRightIcon } from '@radix-ui/react-icons';
import { Tooltip } from '@tokens-studio/ui';
import { useTokens } from '../../../context/TokensContext';
import { Box, Flex, Label } from '../../../shared/components';
import { TokenTypes } from '../../../ft-utils/constants/TokenTypes';
import useResolvedTokenValue from '../../../hooks/useResolvedTokenValue';
import { getShadowTokenValues } from '../../../hooks/getShadowTokenValues';

interface Props {
  value: string;
  type: TokenTypes;
}

export default function TokenResolvedValue({ value, type }: Props) {
  const isColor = type === TokenTypes.COLOR;
  const { loading } = useTokens();

  const resolvedTokenValue = useResolvedTokenValue(value, type, true);

  if (!resolvedTokenValue || loading || isColor) {
    return null;
  }

  function getNotFoundBox() {
    return (
      <Box
        css={{
          fontWeight: '$sansRegular',
          fontSize: '$small',
          backgroundColor: '$dangerBg',
          padding: '$1 $2',
          border: '1px solid $dangerBorder',
          borderRadius: '$medium',
          color: '$dangerFg',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '$2',
          width: 'max-content',
        }}
      >
        <Label regular css={{ color: '$dangerFg' }}>
          Not found
        </Label>
      </Box>
    );
  }

  function getResolvedToken(): string | null {
    const valueType = typeof resolvedTokenValue;
    switch (true) {
      case valueType === 'string':
      case valueType === 'number':
        return String(resolvedTokenValue);
      case Array.isArray(resolvedTokenValue):
        if (resolvedTokenValue && Array.isArray(resolvedTokenValue)) {
          const { resolvedValues } = getShadowTokenValues(resolvedTokenValue);
          return Object.values(resolvedValues || {}).reduce(
            (acc, val, index) => `${acc}${val && index ? ',' : ''} ${val}`,
            ''
          ) as string;
        }
        return null;

      case resolvedTokenValue === null:
        return null;
      case valueType === 'object':
        return Object.values(resolvedTokenValue || {}).reduce(
          (acc, val, index) => `${acc}${val && index ? ',' : ''} ${val}`,
          ''
        ) as string;
      default:
        return null;
    }
  }

  return (
    <Flex
      css={{
        height: '100%',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '$3',
      }}
    >
      <ArrowRightIcon />
      {resolvedTokenValue !== null && resolvedTokenValue !== value ? (
        <Box
          css={{
            fontWeight: '$sansRegular',
            fontSize: '$small',
            backgroundColor: '$accentBg',
            padding: '$1 $2',
            border: '1px solid $accentBorder',
            borderRadius: '$medium',
            color: '$accentDefault',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '$2',
            width: 'max-content',
            maxWidth: '170px',
          }}
        >
          {isColor && (
            <Box
              css={{
                width: '16px',
                height: '16px',
                background: getResolvedToken() || '',
                borderRadius: '$small',
                border: '1px solid $borderMuted',
                flexShrink: 0,
              }}
            />
          )}
          <Tooltip side="bottom" label={getResolvedToken() || ''}>
            <Label
              regular
              css={{ color: '$accentDefault', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
            >
              {getResolvedToken()}
            </Label>
          </Tooltip>
        </Box>
      ) : (
        getNotFoundBox()
      )}
    </Flex>
  );
}
