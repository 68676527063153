import React from 'react';
import * as ToastPrimitive from '@radix-ui/react-toast';
import { InfoCircledIcon } from '@radix-ui/react-icons';
import { keyframes, styled } from '../../stitches.config';
import Flex from './Flex';
import { NotificationTypes, useNotifications } from '../../context/NotificationContext';

const VIEWPORT_PADDING = 30;

const hide = keyframes({
  '0%': {
    opacity: 1,
  },
  '100%': {
    opacity: 0,
  },
});

const slideIn = keyframes({
  from: {
    transform: `translateX(calc(100% + ${VIEWPORT_PADDING}px))`,
  },
  to: {
    transform: 'translateX(0)',
  },
});

const swipeOut = keyframes({
  from: {
    transform: 'translateX(var(--radix-toast-swipe-end-x))',
  },
  to: {
    transform: `translateX(calc(100% + ${VIEWPORT_PADDING}px))`,
  },
});

const Viewport = styled(ToastPrimitive.Viewport, {
  position: 'fixed',
  bottom: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  padding: VIEWPORT_PADDING,
  gap: 10,
  width: 390,
  maxWidth: '100vw',
  margin: 0,
  listStyle: 'none',
  zIndex: 2147483647,
  outline: 'none',
});

const ToastContainer = styled(ToastPrimitive.Root, {
  padding: '$5',
  alignItems: 'center',
  color: '$fgDefault',
  display: 'flex',
  fontSize: '$small',
  variants: {
    variant: {
      success: {
        backgroundColor: '$successBg',
        color: '$successFg',
        '& svg': {
          fill: '$successFg',
        },
      },
      error: {
        backgroundColor: '$dangerBg',
        color: '$dangerFg',
        '& svg': {
          fill: '$dangerFg',
        },
      },
    },
  },

  '@media (prefers-reduced-motion: no-preference)': {
    '&[data-state="open"]': {
      animation: `${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
    },
    '&[data-state="closed"]': {
      animation: `${hide} 100ms ease-in`,
    },
    '&[data-swipe="move"]': {
      transform: 'translateX(var(--radix-toast-swipe-move-x))',
    },
    '&[data-swipe="cancel"]': {
      transform: 'translateX(0)',
      transition: 'transform 200ms ease-out',
    },
    '&[data-swipe="end"]': {
      animation: `${swipeOut} 100ms ease-out`,
    },
  },
});

const ToastContent = styled(ToastPrimitive.Description, {
  display: 'flex',
  alignItems: 'center',
});

const NotificationToast = () => {
  const { notifications, removeNotification } = useNotifications();

  const onOpenChange = (visible: boolean, id: string) => {
    if (!visible) {
      removeNotification(id);
    }
  };
  return (
    <>
      {notifications.map(({ type, message, id }) => (
        <ToastContainer
          onOpenChange={(visible) => {
            onOpenChange(visible, id);
          }}
          variant={type === NotificationTypes.ERORR ? 'error' : 'success'}
          key={id}
          tabIndex={-1}
        >
          <ToastContent>
            <Flex
              css={{
                marginRight: '$4',
              }}
            >
              <InfoCircledIcon />
            </Flex>
            {message}
          </ToastContent>
        </ToastContainer>
      ))}
      <Viewport />
    </>
  );
};

export default NotificationToast;
