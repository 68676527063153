import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack } from '@tokens-studio/ui';
import { useAuth } from '../context/AuthContext';
import { TokensContextProvider } from '../context/TokensContext';
import DashboardLoading from '../components/DashboardLoading';
import Sidebar from '../components/Sidebar';
import PageContent from '../components/PageContent';
import { TokensTableContextProvider } from '../context/TokensTableContext';

export default function Dashboard() {
  const { loggedUser, fetchingUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loggedUser && !fetchingUser) {
      navigate('/login');
    }
  }, [loggedUser, navigate, fetchingUser]);

  return (
    <TokensContextProvider>
      <Stack
        direction="row"
        css={{
          alignItems: 'stretch',
          background: '$bgCanvas',
          color: '$fgDefault',
          width: '100vw',
          height: '100vh',
        }}
      >
        <Sidebar />
        <Stack direction="column" css={{ flexGrow: 1, overflow: 'hidden' }}>
          <TokensTableContextProvider>
            <PageContent />
          </TokensTableContextProvider>
          <DashboardLoading />
        </Stack>
      </Stack>
    </TokensContextProvider>
  );
}
