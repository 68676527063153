import { BoxShadowTypes } from '../../../ft-utils/constants/BoxShadowTypes';
import { BoxShadowValues } from '../../../ft-utils/constants/BoxShadowValues';
import { SingleBoxShadowToken } from '../../../ft-utils/types/tokens';
import { getShadowTokenValues } from '../../../hooks/getShadowTokenValues';
import { Box, Flex, InlineBox } from '../../../shared/components';
import TokenIcon from '../../TokenIcon';
import { ComplexTokenProp } from '../partials/ComplexTokenProp';

export default function BoxShadowValue(props: SingleBoxShadowToken) {
  const { value } = props;
  const { shadowRawValue } = getShadowTokenValues(value);

  if (typeof shadowRawValue === 'string') {
    return (
      <Box css={{ padding: '$3 $5', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
        {shadowRawValue}
      </Box>
    );
  }

  if (shadowRawValue && !Array.isArray(shadowRawValue)) {
    return (
      <Flex css={{ flexDirection: 'column', gap: '$2', alignItems: 'flex-start', padding: '$3 $5' }}>
        <Box>{shadowRawValue.type === BoxShadowTypes.DROP_SHADOW ? 'Drop shadow' : 'Inner shadow'}</Box>
        {Object.keys(shadowRawValue).map((key) => {
          const val = shadowRawValue[key];
          const icon = <TokenIcon propertyKey={key as BoxShadowValues} />;
          return (
            icon && (
              <ComplexTokenProp key={key}>
                {icon}
                <InlineBox css={{ color: '$fgSubtle' }}>{`${key}: `}</InlineBox>
                <InlineBox
                  css={{ color: '$fgDefault', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                >
                  {val}
                </InlineBox>
              </ComplexTokenProp>
            )
          );
        })}
      </Flex>
    );
  }

  return null;
}
