import React, { useRef } from 'react';
import { AliasRegex } from '../../ft-utils/constants/AliasRegex';
import { SingleSizingToken, SingleSpacingToken } from '../../ft-utils/types/tokens';
import useIsInView from '../../hooks/useIsInView';
import { Box, Flex } from '../../shared/components';
import TokenPreview from './partials/TokenPreview';
import useResolvedTokenValue from '../../hooks/useResolvedTokenValue';
import TokenName from './partials/TokenName';
import RawValue from './partials/RawValue';
import ResolvedValueBox from './partials/ResolvedValue';

export default function DimensionToken(props: SingleSpacingToken | SingleSizingToken) {
  const { type, value, name } = props;
  const tokenRef = useRef<HTMLDivElement>(null);
  const inView = useIsInView(tokenRef);
  const hasAlias = Boolean(typeof value === 'string' && value?.match(AliasRegex));

  const resolvedValue = (useResolvedTokenValue(value, type, inView) as string) ?? value;

  return (
    <>
      <TokenPreview>
        <Flex css={{ height: '20px', marginBottom: '$5', position: 'relative' }} ref={tokenRef}>
          <Box css={{ background: '$accentDefault', width: '1px', height: '12px' }} />
          <Box
            css={{
              background: '$accentDefault',
              height: '1px',

              width: `${Math.min(+resolvedValue, 100)}px`,
            }}
          />
          <Box css={{ background: '$accentDefault', width: '1px', height: '12px' }} />
          {+resolvedValue > 50 ? (
            <Box
              css={{
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
                backgroundColor: '$accentBg',
                padding: '$1 $2',
                borderRadius: '$medium',
                color: '$accentDefault',
                fontSize: '$small',
              }}
            >
              {resolvedValue}
            </Box>
          ) : null}
        </Flex>
      </TokenPreview>
      <Flex css={{ flexDirection: 'column', alignItems: 'flex-start', gap: '$2', overflow: 'hidden' }}>
        <TokenName name={name} />
        {hasAlias && value && <RawValue rawValue={value} />}
        <ResolvedValueBox isAccent={hasAlias} value={resolvedValue} />
      </Flex>
    </>
  );
}
