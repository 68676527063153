import React from 'react';
import { useParams } from 'react-router-dom';
import { TokenTypes } from '../../ft-utils/constants/TokenTypes';
import { ResolveTokenValuesResult } from '../../ft-utils/tokenHelpers';
import AssetResolvedValue from './Asset/AssetResolvedValue';
import BorderResolvedValue from './Border/BorderResolvedValue';
import ColorTokenResolvedValue from './Color/ColorTokenResolvedValue';
import CompositionResolvedValue from './Composition/CompositionResolvedValue';
import GenericResolvedValue from './Generic/GenericResolvedValue';
import BoxShadowResolvedValue from './Shadow/BoxShadowResolvedValue';
import TypographyResolvedValue from './Typography/TypographyResolvedValue';

export default function TokenResolvedValue(props: ResolveTokenValuesResult) {
  const { type } = props;
  const { selectedTokenSet } = useParams();

  switch (type) {
    case TokenTypes.BOX_SHADOW:
      return <BoxShadowResolvedValue {...props} key={selectedTokenSet} />;
    case TokenTypes.TYPOGRAPHY:
      return <TypographyResolvedValue {...props} key={selectedTokenSet} />;
    case TokenTypes.BORDER:
      return <BorderResolvedValue {...props} key={selectedTokenSet} />;
    case TokenTypes.COMPOSITION:
      return <CompositionResolvedValue {...props} key={selectedTokenSet} />;
    case TokenTypes.ASSET:
      return <AssetResolvedValue {...props} key={selectedTokenSet} />;
    case TokenTypes.COLOR:
      return <ColorTokenResolvedValue {...props} key={selectedTokenSet} />;
    // @ts-ignore
    case TokenTypes.SIZING:
    case TokenTypes.OPACITY:
    case TokenTypes.BORDER_RADIUS:
    case TokenTypes.BORDER_WIDTH:
    case TokenTypes.SPACING:
    default:
      return <GenericResolvedValue {...props} key={selectedTokenSet} />;
  }
}
