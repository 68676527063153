import { Textarea } from '@tokens-studio/ui';
import React from 'react';
import { Field } from 'react-final-form';
import { Box, Label } from '../../shared/components';

export default function TokenDescriptionInput() {
  return (
    <Box css={{ display: 'flex', flexDirection: 'column', gap: '$3' }}>
      <Label htmlFor="description">Description</Label>
      <Field
        name="description"
        render={(fieldProps) => (
          <Textarea {...fieldProps.input} border id="description" rows={4} placeholder="Add a description" />
        )}
      />
    </Box>
  );
}
