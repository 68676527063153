import { CellContext, createColumnHelper, Table } from '@tanstack/react-table';
import React, { useCallback, useMemo } from 'react';
import { Checkbox, DropdownMenu } from '@tokens-studio/ui';
import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import { useNavigate } from 'react-router-dom';
import { Token } from '../../context/types';
import { Box, Flex } from '../../shared/components';
import TokenName from './TokenName';
import TokenResolvedValue from './TokenResolvedValue';
import TokenValue from './TokenValue';
import { TableTokenItem } from '../../ft-utils/types/TableTokenItem';
import { useDeleteTokens } from '../../hooks/useDeleteTokens';
import TokenDescription from './TokenDescription';
import { ColumnId } from '../../types/table';
import getSortedSubRows from '../../hooks/getSortedSubRows';
import ColorModifier from './ColorModifier';
import { TokenTypes } from '../../ft-utils/constants/TokenTypes';
import { useTokensTableState } from '../../context/TokensTableContext';
import isEmptyObject from '../../utils/isEmptyObject';
import valueSortFn from '../../utils/table/valueSortFn';
import modifierSortFn from '../../utils/table/modifierSortFn';

export default () => {
  const columnHelper = useMemo(() => createColumnHelper<TableTokenItem>(), []);
  const navigate = useNavigate();
  const deleteTokens = useDeleteTokens();
  const { setBulkSelectionInitialRowId, rowSelection, setSelectedCells } = useTokensTableState();

  const renderResolvedValue = useCallback(
    (info: CellContext<TableTokenItem, any>) => <TokenResolvedValue {...info.row.original} />,
    []
  );

  const renderSelectHeader = useCallback(({ table }: { table: Table<Token> }) => {
    const selectAllHandler = table.getToggleAllRowsSelectedHandler();
    const clickHandler = (ev: React.MouseEvent<HTMLInputElement>): void => {
      selectAllHandler(ev);
    };
    const checkedProp = table.getIsSomeRowsSelected() ? 'indeterminate' : table.getIsAllRowsSelected();
    return (
      <Checkbox
        style={{ flexShrink: 0 }}
        id="header-selector"
        checked={checkedProp}
        onCheckedChange={selectAllHandler}
        // @ts-ignore
        onClick={clickHandler}
      />
    );
  }, []);

  const renderSelectCell = useCallback(
    ({ row, table }: CellContext<TableTokenItem, string>) => {
      const { rows } = table.getSortedRowModel();
      const sortedSubRows = getSortedSubRows(rows);
      const listingIndex = sortedSubRows.findIndex((itm) => itm.id === row.id);

      const selectionHandler = row.getToggleSelectedHandler();

      const onChecked = (e: unknown) => {
        selectionHandler(e);
        setSelectedCells([{ colId: ColumnId.NAME, rowId: row.id }]);
        if (isEmptyObject(rowSelection)) {
          setBulkSelectionInitialRowId(row.id);
        }
      };

      return (
        <Flex
          css={{
            gap: '$5',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            height: '100%',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            padding: '$3 $5',
            cursor: 'pointer',
          }}
          onClick={onChecked}
        >
          <Checkbox style={{ flexShrink: 0 }} id={row.id} onCheckedChange={onChecked} checked={row.getIsSelected()} />
          {listingIndex}
        </Flex>
      );
    },
    [rowSelection, setBulkSelectionInitialRowId, setSelectedCells]
  );

  const renderTokenName = useCallback(
    (info: CellContext<TableTokenItem, any>) => <TokenName {...info.row.original} />,
    []
  );

  const renderTokenValue = useCallback(
    (info: CellContext<TableTokenItem, any>) => <TokenValue {...info.row.original} />,
    []
  );

  const renderModifier = useCallback((info: CellContext<TableTokenItem, any>) => {
    if (info.row.original.type !== TokenTypes.COLOR) return null;
    return <ColorModifier {...info.row.original} />;
  }, []);

  const renderDescription = useCallback(
    (info: CellContext<TableTokenItem, any>) => <TokenDescription {...info.row.original} />,
    []
  );

  const renderType = useCallback((info: CellContext<TableTokenItem, any>) => {
    const type = info.row.getValue('type') as string;

    return (
      <Flex
        css={{
          padding: '$3 $5',
          height: '100%',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        <Box css={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{type}</Box>
      </Flex>
    );
  }, []);

  const renderOptions = useCallback(
    (info: CellContext<TableTokenItem, any>) => {
      const tokenName = info.row.getValue('name') as string;

      return (
        <Box css={{ padding: '$3 $5' }}>
          <DropdownMenu key={`dropdown-menu-${tokenName}`}>
            <DropdownMenu.Trigger asChild>
              <DotsHorizontalIcon cursor="pointer" width={16} height={16} />
            </DropdownMenu.Trigger>
            <DropdownMenu.Portal key={`dropdown-menu-portal-${tokenName}`}>
              <DropdownMenu.Content align="end" sideOffset={8}>
                <DropdownMenu.Item
                  onClick={() => {
                    navigate(`${encodeURIComponent(tokenName)}`);
                  }}
                >
                  Edit
                </DropdownMenu.Item>
                <DropdownMenu.Item
                  onClick={() => {
                    deleteTokens([tokenName]);
                  }}
                >
                  Delete
                </DropdownMenu.Item>
              </DropdownMenu.Content>
            </DropdownMenu.Portal>
          </DropdownMenu>
        </Box>
      );
    },
    [deleteTokens, navigate]
  );

  return useMemo(
    () => [
      {
        id: ColumnId.SELECT,
        minSize: 32,
        size: 80,
        enableResizing: false,
        header: renderSelectHeader,
        cell: renderSelectCell,
      },
      columnHelper.accessor(ColumnId.NAME, {
        id: ColumnId.NAME,
        header: () => 'Name',
        size: 300,
        cell: renderTokenName,
      }),
      columnHelper.accessor(ColumnId.RAW_VALUE, {
        id: ColumnId.RAW_VALUE,
        header: () => 'Value',
        sortingFn: valueSortFn,
        cell: renderTokenValue,
      }),
      columnHelper.accessor(ColumnId.MODIFIER, {
        id: ColumnId.MODIFIER,
        sortingFn: modifierSortFn,
        header: () => 'Modifier',
        cell: renderModifier,
      }),
      columnHelper.accessor(ColumnId.VALUE, {
        id: ColumnId.VALUE,
        header: () => 'Resolved value',
        enableSorting: false,
        cell: renderResolvedValue,
      }),
      columnHelper.accessor(ColumnId.TYPE, {
        id: ColumnId.TYPE,
        header: () => 'Type',
        cell: renderType,
      }),

      columnHelper.accessor(ColumnId.DESCRIPTION, {
        id: ColumnId.DESCRIPTION,
        header: () => 'Description',
        cell: renderDescription,
      }),

      columnHelper.accessor(ColumnId.PATH, {
        enableSorting: false,
        header: () => 'path',
      }),

      columnHelper.accessor(ColumnId.OPTIONS, {
        id: ColumnId.OPTIONS,
        enableResizing: false,
        enableSorting: false,
        header: () => '',
        maxSize: 48,
        cell: renderOptions,
      }),
    ],
    [
      columnHelper,
      renderResolvedValue,
      renderSelectCell,
      renderSelectHeader,
      renderModifier,
      renderType,
      renderOptions,
      renderTokenName,
      renderTokenValue,
      renderDescription,
    ]
  );
};
