import { Box, Tooltip } from '@tokens-studio/ui';
import React, { useMemo } from 'react';
import { useUIState } from '../../../context/UIState';

interface TokenNameProps {
  name: string;
}

export default function TokenName({ name }: TokenNameProps) {
  const { nestingLevel } = useUIState();

  const tokenName = useMemo(() => {
    const tokenPathArr = name.split('.');
    const tokenKeys = tokenPathArr.slice(Math.min(nestingLevel, tokenPathArr.length - 1), tokenPathArr.length);
    return tokenKeys.join('.');
  }, [name, nestingLevel]);

  return (
    <Tooltip label={tokenName} side="left">
      <Box
        css={{
          fontWeight: '$sansBold',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          fontSize: '$base',
          color: '$fgDefault',
        }}
      >
        {tokenName}
      </Box>
    </Tooltip>
  );
}
