import { GroupingState } from '@tanstack/react-table';
import React, { createContext, ReactNode, useContext, useMemo } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';
import { ColumnId } from '../types/table';

export enum ViewTypes {
  LIST = 'list',
  TABLE = 'table',
  JSON = 'json',
}

export interface UIContextType {
  tokenView: ViewTypes;
  setTokenView: React.Dispatch<React.SetStateAction<ViewTypes>>;
  nestingLevel: number;
  setNestingLevel: React.Dispatch<React.SetStateAction<number>>;
  sidebarCollapsed: boolean;
  setSidebarCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  tableGroupBy: GroupingState;
  setTableGroupBy: React.Dispatch<React.SetStateAction<GroupingState>>;
}

const UIContext = createContext<UIContextType | undefined>(undefined);

function UIContextProvider({ children }: { children: ReactNode }) {
  const [tokenView, setTokenView] = useLocalStorage('tokens_studio_token_view', ViewTypes.LIST);
  const [nestingLevel, setNestingLevel] = useLocalStorage('tokens_studio_list_nesting_level', '2');
  const [sidebarCollapsed, setSidebarCollapsed] = useLocalStorage('tokens_studio_sidebar_collapsed', false);
  const [tableGroupBy, setTableGroupBy] = useLocalStorage('tokens_studio_group_by', [ColumnId.PATH]);

  const providerValue = useMemo(
    () => ({
      tokenView,
      setTokenView,
      nestingLevel: +nestingLevel,
      sidebarCollapsed: Boolean(sidebarCollapsed),
      setNestingLevel,
      setSidebarCollapsed,
      tableGroupBy,
      setTableGroupBy,
    }),
    [
      tokenView,
      setTokenView,
      nestingLevel,
      sidebarCollapsed,
      setNestingLevel,
      setSidebarCollapsed,
      tableGroupBy,
      setTableGroupBy,
    ]
  );
  return <UIContext.Provider value={providerValue}>{children}</UIContext.Provider>;
}

function useUIState() {
  const context = useContext(UIContext);
  if (context === undefined) {
    throw new Error('useUIState must be used within a UIContextProvider');
  }
  return context;
}

export { UIContextProvider, useUIState };
