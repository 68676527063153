import { BorderValues } from '../../../ft-utils/constants/BorderValues';
import { SingleBorderToken } from '../../../ft-utils/types/tokens/SingleBorderToken';
import { Box, Flex, InlineBox } from '../../../shared/components';
import TokenIcon from '../../TokenIcon';
import { ComplexTokenProp } from '../partials/ComplexTokenProp';

export default function BorderValue(props: SingleBorderToken) {
  const { value } = props;

  if (typeof value === 'string') {
    return <Box css={{ padding: '$3 $5' }}>{value}</Box>;
  }
  if (value) {
    return (
      <Flex css={{ flexDirection: 'column', gap: '$2', alignItems: 'flex-start', padding: '$3 $5' }}>
        {Object.keys(value).map((key) => {
          const val = value[key];
          const icon = <TokenIcon propertyKey={key as BorderValues} />;
          return (
            icon && (
              <ComplexTokenProp key={key}>
                {icon}
                <InlineBox
                  css={{ color: '$fgSubtle', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                >
                  {`${key}: `}
                </InlineBox>
                <InlineBox
                  css={{ color: '$fgDefault', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                >
                  {val}
                </InlineBox>
              </ComplexTokenProp>
            )
          );
        })}
      </Flex>
    );
  }

  return null;
}
