import { CaretDownIcon, CaretUpIcon } from '@radix-ui/react-icons';
import { Cell, flexRender } from '@tanstack/react-table';
import { Box, IconButton } from '@tokens-studio/ui';
import { useMemo } from 'react';
import { useTokensTableClipboard } from '../../context/TokensTableClipboardContext';
import { useTokensTableState } from '../../context/TokensTableContext';
import { TableTokenItem } from '../../ft-utils/types/TableTokenItem';
import { Flex } from '../../shared/components';
import { ColumnId, editableCells, selectableCells } from '../../types/table';
import isEmptyObject from '../../utils/isEmptyObject';

interface TableCellProps {
  cell: Cell<TableTokenItem, unknown>;
}

const TableCell = ({ cell }: TableCellProps) => {
  const { selectedCells, setSelectedCells, rowSelection, setRowSelection, setBulkSelectionInitialRowId } =
    useTokensTableState();
  const { copiedItem } = useTokensTableClipboard();
  const columnId = cell.column.id as ColumnId;
  const rowId = cell.row.id;

  const isSelected = useMemo(
    () => !!selectedCells && selectedCells.some((itm) => itm.colId === columnId && itm.rowId === rowId),
    [columnId, rowId, selectedCells]
  );

  const isInBulkSelection = useMemo(
    () => isSelected && selectedCells && selectedCells?.length > 1,
    [isSelected, selectedCells]
  );

  const getCellContent = () => {
    if (cell.getIsGrouped()) {
      return (
        <Flex
          css={{
            padding: '$4',
            gap: '$4',
            fontSize: '$small',
            width: '100%',
            borderRight: '1px solid $borderMuted',
            justifyContent: 'flex-start',
            userSelect: 'none',
          }}
        >
          <IconButton
            icon={cell.row.getIsExpanded() ? <CaretUpIcon /> : <CaretDownIcon />}
            onClick={cell.row.getToggleExpandedHandler()}
            variant="invisible"
          />
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
          <Flex
            css={{
              borderRadius: '50%',
              background: '$bgSubtle',
              padding: '$2 $3',
              fontSize: '$xsmall',
              fontWeight: '$sansBold',
            }}
          >
            {cell.row.subRows.length}
          </Flex>
        </Flex>
      );
    }

    if (cell.getIsAggregated()) {
      return null;
    }

    const isRowSelected = rowSelection[cell.row.id];
    const { colId: copiedColumnId, rowId: copiedRowId } = copiedItem ?? {};
    const isCopied = copiedRowId === cell.row.id && cell.column.id === copiedColumnId;

    const isEditable = editableCells.includes(columnId);
    const isSelectable = selectableCells.includes(columnId);

    return (
      <Box
        css={{
          userSelect: 'none',
          display: 'inline-block',
          width: `${cell.column.getSize()}px`,
          flexGrow: 0,
          fontSize: '$xsmall',
          fontWeight: '$sansRegular',
          background: isRowSelected ? '$accentBg' : '$bgDefault',
          minHeight: '100%',
          borderRight: `1px solid ${isRowSelected ? '$accentBorder' : '$borderMuted'}`,
          cursor: isSelectable && !isEditable ? 'not-allowed' : 'inherit',
          '&:hover': {
            background: isSelectable && isEditable ? '$bgSubtle' : '',
          },
        }}
        tabIndex={0}
        onClick={() => {
          if (selectableCells.includes(columnId)) {
            setSelectedCells([{ colId: columnId, rowId }]);
            setBulkSelectionInitialRowId(rowId);
            if (!isEmptyObject(rowSelection)) {
              setRowSelection({});
            }
          }
        }}
      >
        <Box
          css={{
            width: '100%',
            height: '100%',
            background: isInBulkSelection ? '$accentBg' : 'transparent',
            border: isCopied
              ? '1px dashed $accentEmphasis'
              : `1px solid ${isSelected ? '$accentEmphasis' : 'transparent'}`,
          }}
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </Box>
      </Box>
    );
  };

  return getCellContent();
};

export default TableCell;
