import React from 'react';
import { Field, useField } from 'react-final-form';
import { TokenTypes } from '../../../ft-utils/constants/TokenTypes';
import useReferences from '../../../hooks/useReferences';
import { Box, Flex, Input, Label } from '../../../shared/components';
import MentionsInput from '../../../shared/components/MentionsInput';
import useResolvedTokenValue from '../../../hooks/useResolvedTokenValue';

interface ColorInputProps {
  showLabel?: boolean;
  valuePath?: string;
  autoFocus?: boolean;
}

export default function ColorInput({ showLabel = true, valuePath = 'value', autoFocus = false }: ColorInputProps) {
  const references = useReferences(TokenTypes.COLOR);
  const {
    input: { value: tokenValue },
  } = useField(valuePath);

  const resolvedColorValue = (useResolvedTokenValue(tokenValue, TokenTypes.COLOR, true) as string) ?? tokenValue;

  return (
    <Box css={{ display: 'flex', flexDirection: 'column', gap: '$3', width: '100%' }}>
      {showLabel && <Label htmlFor={`${valuePath}-color`}>Value</Label>}
      <Field
        name={valuePath}
        render={(fieldProps) => (
          <Flex
            css={{
              alignItems: 'center',
              justifyContent: 'flex-start',
              border: '1px solid $borderMuted',
              borderRadius: '$medium',
              backgroundColor: '$inputBg',
              padding: '0 $3',
              gap: '$2',
            }}
          >
            <Box
              css={{
                borderRadius: '$small',
                width: '24px',
                height: '24px',
                overflow: 'hidden',
                position: 'relative',
                border: '1px solid $borderMuted',
              }}
            >
              <Input
                {...fieldProps.input}
                css={{
                  position: 'absolute',
                  left: '-5px',
                  top: '-5px',
                  width: '32px',
                  height: '32px',
                  padding: 0,
                  outline: 'none',
                  appearance: 'none',
                  '&::-webkit-color-swatch': { border: 'none', borderRadius: '$medium', cursor: 'pointer' },
                }}
                variant="outline"
                id={`${valuePath}-color`}
                type="color"
                value={resolvedColorValue.trim()}
                spellCheck={false}
              />
            </Box>
            <Box css={{ flexGrow: 1 }}>
              <MentionsInput
                placeholder="#0000, hsla(), rgba() or {alias}"
                tokenType={TokenTypes.COLOR}
                autoFocus={autoFocus}
                data={references}
                {...fieldProps.input}
              />
            </Box>
          </Flex>
        )}
      />
    </Box>
  );
}
