import { SortingFn } from '@tanstack/react-table';
import { TableTokenItem } from '../../ft-utils/types/TableTokenItem';

const modifierSortFn: SortingFn<TableTokenItem> = (rowA, rowB) => {
  const extA = rowA.original.$extensions;
  const extB = rowB.original.$extensions;

  if (extA === undefined) {
    return 1; // Place undefined values at the end
  }
  if (extB === undefined) {
    return -1; // Place undefined values at the end
  }

  const typeA = extA['studio.tokens'].modify?.type;
  const typeB = extB['studio.tokens'].modify?.type;

  if (typeA === undefined) {
    return 1; // Place undefined values at the end
  }
  if (typeB === undefined) {
    return -1; // Place undefined values at the end
  }

  if (typeA < typeB) {
    return -1;
  }
  if (typeA > typeB) {
    return 1;
  }
  return 0;
};

export default modifierSortFn;
