import React, { useCallback } from 'react';
import { SingleToken } from '../../ft-utils/types/tokens';
import useInlineEdit from '../../hooks/useInlineEdit';
import { Box, Flex, Input } from '../../shared/components';
import { ColumnId } from '../../types/table';

export default function TokenDescription(props: SingleToken) {
  const { description } = props;
  const { ref, setNewValue, editMode, setIsEditMode, onInputKeyDown, newValue } = useInlineEdit(
    props,
    ColumnId.DESCRIPTION
  );

  const onDescriptionDoubleClick = useCallback(() => {
    setIsEditMode(true);
  }, [setIsEditMode]);

  return (
    <Flex
      css={{
        justifyContent: 'flex-start',
        gap: '$3',
        alignItems: 'flex-start',
        height: '100%',
        padding: '$3 $5',
        width: '100%',
        border: `1px solid ${editMode ? '$accentEmphasis' : 'transparent'}`,
      }}
      onDoubleClick={onDescriptionDoubleClick}
      ref={ref}
    >
      {editMode ? (
        <Input
          css={{ fontSize: '$xsmall', fontFamily: '$mono', border: 'none', height: '$5', paddingLeft: 0 }}
          value={newValue as string}
          autoFocus
          onKeyDown={onInputKeyDown}
          onChange={(e) => {
            setNewValue(e.target.value);
          }}
        />
      ) : (
        <Box css={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{description}</Box>
      )}
    </Flex>
  );
}
