import { Box, Tooltip } from '@tokens-studio/ui';
import React from 'react';

interface RawValueProps {
  rawValue: string;
}

export default function RawValue({ rawValue }: RawValueProps) {
  return (
    <Tooltip label={rawValue} side="left">
      <Box
        css={{
          fontWeight: '$sansRegular',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          fontSize: '$small',
          color: '$fgSubtle',
        }}
      >
        {rawValue}
      </Box>
    </Tooltip>
  );
}
