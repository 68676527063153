import { useMemo } from 'react';
import { useTokens } from '../context/TokensContext';
import { TokenTypeOrProp } from '../ft-utils/types/TokenTypeOrProp';
import { SuggestionDataItem } from '../shared/components/MentionsInput';
import { getTokenType } from './getTokenType';

export default (type: TokenTypeOrProp): SuggestionDataItem[] => {
  const tokenType = getTokenType(type);

  const { sets } = useTokens();

  const references = useMemo(() => {
    if (!tokenType || !sets) return [];
    return Object.keys(sets).reduce<string[]>((acc, val) => {
      const filteredValues = sets[val].filter((tkn) => tkn.type === tokenType).map((tkn) => tkn.name);

      return [...acc, ...filteredValues];
    }, []);
  }, [sets, tokenType]);

  const uniqueRefs = useMemo(() => [...new Set(references)], [references]);

  return useMemo(() => uniqueRefs.map((ref) => ({ id: `${ref}}`, display: ref })), [uniqueRefs]);
};
