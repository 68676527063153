import { Table } from '@tanstack/react-table';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTokensTableState } from '../context/TokensTableContext';
import { TokenTypes } from '../ft-utils/constants/TokenTypes';
import { TableTokenItem } from '../ft-utils/types/TableTokenItem';
import { ColumnId, editableCells, selectableCells } from '../types/table';
import isEmptyObject from '../utils/isEmptyObject';
import updateTableColumnSelection from '../utils/table/updateTableColumnSelection';
import updateTableRowSelection from '../utils/table/updateTableRowSelection';
import getSortedSubRows from './getSortedSubRows';
import useClipBoardActions from './useClipBoardActions';
import { useTokenSearchParams } from './useTokenSearchParams';

import shouldPreventTableNavigation from '../utils/table/shouldPreventTableNavigation';

interface UseTableKeyUp {
  table: Table<TableTokenItem>;
}

const useTableKeyUp = ({ table }: UseTableKeyUp) => {
  const {
    selectedCells,
    setSelectedCells,
    isEditMode,
    setIsEditMode,
    setRowSelection,
    rowSelection,
    bulkSelectionInitialRowId,
    setBulkSelectionInitialRowId,
  } = useTokensTableState();
  const navigate = useNavigate();

  const { onCellCopy, onCellPaste } = useClipBoardActions(table);
  const { isPanelOpen } = useTokenSearchParams();

  const { rows } = table.getSortedRowModel();
  const sortedSubRows = getSortedSubRows(rows);
  const selectedCell = useMemo(() => selectedCells && selectedCells[0], [selectedCells]);

  const onKeyDown = useCallback(
    (ev: KeyboardEvent) => {
      if (isPanelOpen || shouldPreventTableNavigation(ev.target)) {
        return;
      }
      const { key, shiftKey, ctrlKey, metaKey } = ev;
      if (selectedCells && !isEditMode) {
        const { colId: selectedColumnId, rowId: selectedRowId } = selectedCells[0];
        const currentSelectedColumnIndex = selectableCells.findIndex((cellId) => cellId === selectedColumnId);
        const currentSelectedRowIndex = sortedSubRows.findIndex(({ id }) => id === selectedRowId);

        const isLastInRow = currentSelectedColumnIndex + 1 === selectableCells.length;
        const isFirstInRow = currentSelectedColumnIndex === 0;

        const isLastRow = currentSelectedRowIndex + 1 === sortedSubRows.length;
        const isFirstRow = currentSelectedRowIndex === 0;

        switch (key) {
          case 'ArrowRight':
            if (isLastInRow) {
              return;
            }
            setSelectedCells([{ colId: selectableCells[currentSelectedColumnIndex + 1], rowId: selectedRowId }]);
            setRowSelection({});
            break;
          case 'ArrowLeft':
            if (isFirstInRow) {
              return;
            }
            setSelectedCells([{ colId: selectableCells[currentSelectedColumnIndex - 1], rowId: selectedRowId }]);
            setRowSelection({});
            break;
          case 'ArrowDown':
            // row selection
            if (shiftKey && !isEmptyObject(rowSelection)) {
              const newRowSelection = updateTableRowSelection(
                rowSelection,
                sortedSubRows,
                bulkSelectionInitialRowId,
                1
              );
              setRowSelection(newRowSelection);
            } else {
              // cell selection
              if (currentSelectedRowIndex + 1 === sortedSubRows.length) return;
              const rowId = sortedSubRows[currentSelectedRowIndex + 1].id;
              if (shiftKey) {
                // bulk selection
                const newCellSelection = updateTableColumnSelection(
                  selectedCells,
                  sortedSubRows,
                  bulkSelectionInitialRowId,
                  1
                );

                setSelectedCells(newCellSelection);
              } else {
                // single selection
                setSelectedCells([{ colId: selectedColumnId, rowId }]);
                setBulkSelectionInitialRowId(rowId);
                setRowSelection({});
              }
            }

            break;
          case 'ArrowUp':
            // row selection
            if (shiftKey && !isEmptyObject(rowSelection)) {
              const newRowSelection = updateTableRowSelection(
                rowSelection,
                sortedSubRows,
                bulkSelectionInitialRowId,
                -1
              );

              setRowSelection(newRowSelection);
            } else {
              // cell selection
              if (currentSelectedRowIndex === 0) return;
              if (shiftKey) {
                // bulk selection
                const newCellSelection = updateTableColumnSelection(
                  selectedCells,
                  sortedSubRows,
                  bulkSelectionInitialRowId,
                  -1
                );
                setSelectedCells(newCellSelection);
              } else {
                // single selection
                const rowId = sortedSubRows[currentSelectedRowIndex - 1].id;
                setSelectedCells([{ colId: selectedColumnId, rowId }]);
                setBulkSelectionInitialRowId(rowId);
                setRowSelection({});
              }
            }

            break;
          case 'Enter':
            if (editableCells.includes(selectedColumnId)) {
              if (!isEmptyObject(rowSelection)) return;
              if (selectedColumnId === ColumnId.MODIFIER) {
                const selectedRow = sortedSubRows.find((itm) => itm.id === selectedRowId);
                if (selectedRow?.original.type === TokenTypes.COLOR) {
                  setIsEditMode(true);
                }
              } else {
                setIsEditMode(true);
              }
            }
            break;
          case ' ':
            if (selectedCell) {
              navigate(`${encodeURIComponent(selectedCell.rowId)}?panel=1`);
            }
            break;
          case 'Tab':
            if (!isEmptyObject(rowSelection)) {
              return;
            }

            if (shiftKey) {
              if (isFirstInRow && isFirstRow) {
                return;
              }

              if (isFirstInRow) {
                setSelectedCells([
                  {
                    colId: selectableCells[selectableCells.length - 1],
                    rowId: sortedSubRows[currentSelectedRowIndex - 1].id,
                  },
                ]);
              } else {
                setSelectedCells([{ colId: selectableCells[currentSelectedColumnIndex - 1], rowId: selectedRowId }]);
              }
            } else {
              if (isLastInRow && isLastRow) {
                return;
              }

              if (isLastInRow) {
                setSelectedCells([{ colId: selectableCells[0], rowId: sortedSubRows[currentSelectedRowIndex + 1].id }]);
              } else {
                setSelectedCells([{ colId: selectableCells[currentSelectedColumnIndex + 1], rowId: selectedRowId }]);
              }
            }
            break;
          case 'a':
            if (ctrlKey || metaKey) {
              setRowSelection({ [selectedRowId]: true });
              setBulkSelectionInitialRowId(selectedRowId);
              setSelectedCells([{ colId: ColumnId.NAME, rowId: selectedRowId }]);
            }
            break;
          case 'c':
            if (ctrlKey || metaKey) {
              onCellCopy();
            }
            break;
          case 'v':
            if (ctrlKey || metaKey) {
              onCellPaste();
            }
            break;
          default:
            return;
        }
        ev.preventDefault();
        ev.stopPropagation();
      }
    },
    [
      isPanelOpen,
      selectedCells,
      isEditMode,
      sortedSubRows,
      setSelectedCells,
      setRowSelection,
      rowSelection,
      selectedCell,
      bulkSelectionInitialRowId,
      setBulkSelectionInitialRowId,
      setIsEditMode,
      navigate,
      onCellCopy,
      onCellPaste,
    ]
  );

  useEffect(() => {
    if ((selectedCell && sortedSubRows.find(({ id }) => id === selectedCell.rowId)) || !isEmptyObject(rowSelection)) {
      window.addEventListener('keydown', onKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown, sortedSubRows, rowSelection, selectedCell]);
};

export default useTableKeyUp;
