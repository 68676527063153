import React, { useRef } from 'react';
import { SingleBorderToken } from '../../../ft-utils/types/tokens/SingleBorderToken';
import { Box } from '../../../shared/components';
import useResolvedTokenValue from '../../../hooks/useResolvedTokenValue';
import { TokenTypes } from '../../../ft-utils/constants/TokenTypes';
import useIsInView from '../../../hooks/useIsInView';

export default function BorderResolvedValue(props: SingleBorderToken) {
  const { value } = props;
  const tokenRef = useRef<HTMLDivElement>(null);
  const inView = useIsInView<HTMLDivElement>(tokenRef);

  const resolvedBorderValue =
    (useResolvedTokenValue(value, TokenTypes.BORDER, inView) as SingleBorderToken['value']) ?? value;

  function getBorderValue() {
    return `${String(resolvedBorderValue.width).replace(/[^0-9]/g, '')}px ${resolvedBorderValue.style} ${
      resolvedBorderValue.color
    }`;
  }

  return (
    <Box ref={tokenRef} css={{ padding: '$3 $5', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
      {getBorderValue()}
    </Box>
  );
}
