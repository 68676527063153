import { AliasRegex } from '../constants/AliasRegex';
import { MathRegex } from '../constants/MathRegex';
import { TokenTypes } from '../constants/TokenTypes';
import { SingleToken } from '../types/tokens';

const hasMathOrAlias = (value: string) => Boolean(value.match(AliasRegex)) || Boolean(value.match(MathRegex));

export function tokenHasAliasOrMath(value: SingleToken['value'] | string, tokenType: TokenTypes): boolean {
  let hasAliasOrMath = false;
  if (value) {
    if (typeof value === 'string') {
      hasAliasOrMath = hasMathOrAlias(value);
    } else if (
      tokenType === TokenTypes.TYPOGRAPHY ||
      tokenType === TokenTypes.BOX_SHADOW ||
      tokenType === TokenTypes.BORDER
    ) {
      if (typeof value === 'string') {
        hasAliasOrMath = hasMathOrAlias(String(value));
      } else {
        const arrayValue = Array.isArray(value) ? value : [value];
        hasAliasOrMath = arrayValue.some((value) =>
          Object.values(value).some((singleValue) => hasMathOrAlias(singleValue?.toString()))
        );
      }
    } else if (tokenType === TokenTypes.COMPOSITION) {
      return Object.values(value).some((val) => hasMathOrAlias(val?.toString()));
    } else {
      hasAliasOrMath = hasMathOrAlias(value.toString());
    }
  }

  return hasAliasOrMath;
}
