import { CSS } from '@stitches/react';
import { Box, Tooltip } from '@tokens-studio/ui';

interface ResolvedValueBoxProps {
  value: string;
  isAccent?: boolean;
  withTooltip?: boolean;
}

export default function ResolvedValueBox({ value, isAccent, withTooltip = true }: ResolvedValueBoxProps) {
  let style: CSS = {
    fontWeight: '$sansRegular',
    fontSize: '$small',
    color: '$fgSubtle',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  };

  if (isAccent) {
    style = {
      ...style,
      backgroundColor: '$accentBg',
      padding: '$1 $2',
      borderRadius: '$medium',
      color: '$accentDefault',
    };
  }

  return withTooltip ? (
    <Tooltip label={value} side="left">
      <Box css={style}>{value}</Box>
    </Tooltip>
  ) : (
    <Box css={style}>{value}</Box>
  );
}
