import set from 'set-value';
import { Token } from '../../context/types';
import { TokenTypes } from '../../ft-utils/constants/TokenTypes';
import { SingleToken } from '../../ft-utils/types/tokens';
import { GroupBy } from '../../types/table';

export function transformName(name: string): TokenTypes {
  switch (name) {
    case 'color':
    case 'colors':
      return TokenTypes.COLOR;
    case 'space':
    case 'spacing':
      return TokenTypes.SPACING;
    case 'size':
    case 'sizing':
      return TokenTypes.SIZING;
    case 'boxShadow':
      return TokenTypes.BOX_SHADOW;
    case 'borderRadius':
      return TokenTypes.BORDER_RADIUS;
    case 'borderWidth':
      return TokenTypes.BORDER_WIDTH;
    case 'opacity':
      return TokenTypes.OPACITY;
    case 'fontFamilies':
      return TokenTypes.FONT_FAMILIES;
    case 'fontWeights':
      return TokenTypes.FONT_WEIGHTS;
    case 'fontSizes':
      return TokenTypes.FONT_SIZES;
    case 'lineHeights':
      return TokenTypes.LINE_HEIGHTS;
    case 'typography':
      return TokenTypes.TYPOGRAPHY;
    case 'letterSpacing':
      return TokenTypes.LETTER_SPACING;
    case 'paragraphSpacing':
      return TokenTypes.PARAGRAPH_SPACING;
    case 'composition':
      return TokenTypes.COMPOSITION;
    case 'asset':
      return TokenTypes.ASSET;
    default:
      return TokenTypes.OTHER;
  }
}

export function appendTypeToToken(token: Omit<SingleToken, 'type'> & { type?: TokenTypes }): SingleToken {
  const typeToSet = token.type ? token.type : transformName(token.name.split('.').slice(0, 1).toString());
  return {
    ...token,
    type: typeToSet,
  } as SingleToken;
}

// this will be updated
export function createTokensObject(
  tokens: Token[],
  level: 'default' | number = 'default',
  groupBy?: GroupBy
): Record<string, any> {
  if (groupBy && groupBy === GroupBy.TOKEN_TYPE) {
    return tokens.reduce((acc, token) => {
      const tokenType = token.type;
      if (!acc[tokenType]) {
        acc[tokenType] = [];
      }
      acc[tokenType].push(token);
      return acc;
    }, {});
  }

  if (tokens.length > 0) {
    return tokens.reduce((acc, token) => {
      if (level !== 'default') {
        const tokenPathArr = token.name.split('.');
        const tokenKeys = tokenPathArr.slice(Math.min(level, tokenPathArr.length - 1), tokenPathArr.length);
        const rootKeys = tokenPathArr.slice(0, level).join('.');

        const path =
          level < tokenPathArr.length ? `${rootKeys ? `${rootKeys}.` : ''}${tokenKeys.join('/')}` : token.name;
        set(acc, path, { ...token });
      } else {
        set(acc, token.name, { ...token });
      }
      return acc;
    }, {});
  }

  return {};
}
