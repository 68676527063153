import { useMemo } from 'react';
import { ColorModifierTypes } from '../ft-utils/constants/ColorModifierTypes';
import { TokenTypes } from '../ft-utils/constants/TokenTypes';
import { modifyColor } from '../ft-utils/modifyColor';
import { ColorModifier } from '../ft-utils/types/Modifier';
import { SingleColorToken, SingleOtherToken } from '../ft-utils/types/tokens';
import useResolvedTokenValue from './useResolvedTokenValue';

export default (color: SingleColorToken['value'], modifier: ColorModifier | undefined, inView?: boolean) => {
  const amountValue = useMemo(() => modifier?.value ?? '', [modifier]);

  const amountResolvedValue =
    (useResolvedTokenValue(amountValue, TokenTypes.OTHER, inView) as SingleOtherToken['value']) ?? amountValue;

  const mixColorValue = useMemo(() => {
    if (modifier?.type === ColorModifierTypes.MIX) {
      return modifier?.color;
    }
    return '';
  }, [modifier]);

  const mixColorResolvedValue =
    (useResolvedTokenValue(mixColorValue, TokenTypes.COLOR, inView) as SingleColorToken['value']) ?? mixColorValue;

  const modifiedColor = useMemo(() => {
    if (modifier) {
      try {
        if (color && amountResolvedValue) {
          if (modifier) {
            const { type } = modifier;
            if (
              type === ColorModifierTypes.LIGHTEN ||
              type === ColorModifierTypes.DARKEN ||
              type === ColorModifierTypes.ALPHA
            ) {
              return modifyColor(color, {
                ...modifier,
                value: amountResolvedValue,
              });
            }

            if (type === ColorModifierTypes.MIX && mixColorResolvedValue) {
              return modifyColor(color, {
                ...modifier,
                value: amountResolvedValue,
                color: mixColorResolvedValue,
              });
            }
            return color;
          }
          return color;
        }

        return null;
      } catch (e) {
        console.log(e);
        return null;
      }
    }

    return null;
  }, [modifier, amountResolvedValue, mixColorResolvedValue, color]);

  return modifiedColor;
};
