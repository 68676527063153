import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { InlineBox, Box, Flex } from '../shared/components';
import Logo from '../shared/svgs/Logo';

export default function EmailSuccess() {
  const { email } = useParams();

  return (
    <Flex
      css={{
        height: '100vh',
        background: '$bgDefault',
        textAlign: 'center',
      }}
    >
      <Flex
        css={{
          flexDirection: 'column',
          maxWidth: '320px',
          width: '100%',
          '& svg': {
            color: '$fgDefault',
          },
        }}
      >
        <Logo />
        <Box
          css={{
            color: '$fgDefault',
            fontSize: '$xxxlarge',
          }}
        >
          <Box
            css={{
              margin: '$5',
            }}
          >
            Awaiting confirmation
          </Box>
          <Box
            css={{
              fontSize: '$base',
            }}
          >
            <InlineBox
              css={{
                color: '$fgMuted',
              }}
            >
              An email has been sent to{' '}
              <InlineBox
                css={{
                  color: '$fgDefault',
                }}
              >
                {email}
              </InlineBox>
              , click the link in the email to login.
            </InlineBox>
          </Box>

          <Link to="/email-login">
            <InlineBox
              css={{
                fontSize: '$base',
                textDecoration: 'underline',
                marginTop: '$5',
                color: '$fgDefault',
              }}
            >
              Use a different email ?
            </InlineBox>
          </Link>
        </Box>
      </Flex>
    </Flex>
  );
}
