import React, { useCallback, useMemo } from 'react';
import * as Popover from '@radix-ui/react-popover';
import { Box, IconButton } from '@tokens-studio/ui';
import { TriangleDownIcon } from '@radix-ui/react-icons';
import { SingleToken } from '../../ft-utils/types/tokens';
import { Flex } from '../../shared/components';
import ModifierString from '../ModifierString';
import InlineEditForm from './InlineEditForm';
import { ColumnId } from '../../types/table';
import useInlineEdit from '../../hooks/useInlineEdit';

export default function ColorModifier(props: SingleToken) {
  const { editMode, ref, setIsEditMode } = useInlineEdit(props, ColumnId.MODIFIER);
  const { $extensions } = props;

  const setEditMode = useCallback(() => {
    setIsEditMode(true);
  }, [setIsEditMode]);

  const modifier = useMemo(() => {
    if ($extensions) {
      return $extensions['studio.tokens']?.modify;
    }
    return undefined;
  }, [$extensions]);

  return (
    <Flex
      css={{
        gap: '$2',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: '100%',
        position: 'relative',
      }}
      onDoubleClick={setEditMode}
    >
      <Popover.Root open>
        <Popover.Anchor style={{ height: '100%' }}>
          <ModifierString modifier={modifier} withTooltip={false} />
        </Popover.Anchor>
        {editMode && (
          <Popover.Portal>
            <Popover.Content sideOffset={5}>
              <Box
                css={{
                  background: '$bgDefault',
                  border: '1px solid $borderMuted',
                  borderRadius: '$medium',
                  padding: '$5',
                  boxShadow: '$contextMenu',
                  color: '$fgDefault',
                }}
                ref={ref}
              >
                <InlineEditForm {...props} />
              </Box>
            </Popover.Content>
          </Popover.Portal>
        )}
      </Popover.Root>
      <Flex
        css={{
          position: 'absolute',
          right: 0,
          top: 0,
          height: '100%',
        }}
      >
        <IconButton
          css={{ background: 'transparent' }}
          icon={<TriangleDownIcon />}
          variant="invisible"
          onClick={setEditMode}
        />
      </Flex>
    </Flex>
  );
}
