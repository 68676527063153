const selectRoles = ['option', 'menu', 'menuitem'];

function shouldPreventTableNavigation(evTarget: EventTarget | null) {
  if (!evTarget) return false;

  const element = evTarget as HTMLElement;
  const tagName = element.tagName.toUpperCase();
  const elementRole = element.getAttribute('role');

  return (
    tagName === 'INPUT' ||
    tagName === 'TEXTAREA' ||
    (tagName === 'DIV' && !!elementRole && selectRoles.includes(elementRole))
  );
}

export default shouldPreventTableNavigation;
