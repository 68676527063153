import { Spinner } from '@tokens-studio/ui';
import React from 'react';
import { useTokens } from '../context/TokensContext';
import { Flex } from '../shared/components';
import { SIDEBAR_WIDTH } from './Sidebar';

export default function DashboardLoading() {
  const { loading } = useTokens();

  if (!loading) {
    return null;
  }

  return (
    <Flex
      css={{
        position: 'fixed',
        width: `calc(100% - ${SIDEBAR_WIDTH})`,
        height: '100%',
        top: 0,
        left: SIDEBAR_WIDTH,
        zIndex: 1,
        backdropFilter: 'blur(2px)',
      }}
    >
      <Spinner size="large" />
    </Flex>
  );
}
