import React, { useRef } from 'react';
import { SingleAssetToken } from '../../../ft-utils/types/tokens';
import { Box } from '../../../shared/components';
import useResolvedTokenValue from '../../../hooks/useResolvedTokenValue';
import { TokenTypes } from '../../../ft-utils/constants/TokenTypes';
import useIsInView from '../../../hooks/useIsInView';

export default function AssetResolvedValue(props: SingleAssetToken) {
  const { value } = props;

  const tokenRef = useRef<HTMLDivElement>(null);
  const inView = useIsInView<HTMLDivElement>(tokenRef);

  const resolvedAssetValue = (useResolvedTokenValue(value, TokenTypes.ASSET, inView) as string) ?? value;

  if (value) {
    return (
      <Box
        ref={tokenRef}
        css={{
          backgroundImage: `url(${resolvedAssetValue})`,
          backgroundSize: 'contain',
          height: '200px',
          width: '200px',
          backgroundRepeat: 'no-repeat',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          padding: '$3 $5',
        }}
      />
    );
  }

  return null;
}
