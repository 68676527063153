import { KeyboardEvent, useCallback, useMemo, useState } from 'react';
import { NotificationTypes, useNotifications } from '../context/NotificationContext';
import { useTokensTableState } from '../context/TokensTableContext';
import { Token } from '../context/types';
import { SingleToken } from '../ft-utils/types/tokens';
import { ColumnId } from '../types/table';
import useClickOutside from './useClickOutside';
import useSelectedSetTokens from './useSelectedSetTokens';
import { useUpdateTokens } from './useUpdateTokens';

export default (token: SingleToken, property: ColumnId) => {
  const { isEditMode, setIsEditMode, selectedCells, setSelectedCells } = useTokensTableState();

  const selectedCell = useMemo(() => selectedCells?.[0], [selectedCells]);
  const { addNotification } = useNotifications();
  const { updateTokens } = useUpdateTokens();
  const [newValue, setNewValue] = useState<Token['value']>(token[property]);
  const selectedSetTokens = useSelectedSetTokens();

  const tokens = useMemo(() => selectedSetTokens || [], [selectedSetTokens]);

  const onInputKeyDown = async (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const currentValue = token[property];

      if (JSON.stringify(currentValue) !== JSON.stringify(newValue)) {
        if (property === ColumnId.NAME && tokens.find((tkn) => tkn.name === newValue)) {
          addNotification(NotificationTypes.ERORR, 'Token name must be unique');
          setNewValue(token[property]);
        } else {
          const newToken = structuredClone(token);
          newToken[property] = newValue;
          await updateTokens([token.name], newToken);
        }
      }

      setIsEditMode(false);

      if (selectedCell && property === ColumnId.NAME) {
        setSelectedCells([{ colId: selectedCell?.[0], rowId: newValue as string }]);
      }
    } else if (e.key === 'Escape') {
      setIsEditMode(false);
      setNewValue(token[property]);
    } else if (e.key === 'Tab') {
      setIsEditMode(false);
    }

    // e.preventDefault();
    // e.stopPropagation();
  };

  const editMode = useMemo(() => {
    if (selectedCell) {
      const { colId, rowId } = selectedCell;

      return isEditMode && token.name === rowId && colId === property;
    }
    return false;
  }, [selectedCell, isEditMode, property, token.name]);

  const handleClickOutside = useCallback(() => {
    setIsEditMode(false);
  }, [setIsEditMode]);

  const ref = useClickOutside<HTMLDivElement>(handleClickOutside, editMode);

  return { ref, onInputKeyDown, editMode, setNewValue, setIsEditMode, newValue };
};
