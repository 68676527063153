import { TokenData } from '../context/types';
import { supabase } from '../supabase';

export default async function updateRemoteData(newData: TokenData | null) {
  if (newData) {
    await supabase
      .from('tokens')
      .update({
        synced_data: JSON.stringify(newData.synced_data),
        last_updated_by: 'second-screen',
      })
      .eq('id', newData.id);
  }
}
