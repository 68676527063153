import { useNavigate } from 'react-router-dom';
import React from 'react';
import { PlusIcon } from '@radix-ui/react-icons';
import { Heading, IconButton, Stack } from '@tokens-studio/ui';
import { styled } from '../../stitches.config';

const Plus = styled(PlusIcon, {});

interface TokensHeaderProps {
  path: string;
}

export default function TokensHeader({ path }: TokensHeaderProps) {
  const navigate = useNavigate();

  const onAddTokenClick = () => {
    navigate(`?new=1&panel=1&prefix=${encodeURIComponent(`${path}.`)}`);
  };

  return (
    <Stack direction="row" align="center" gap={1} css={{ width: '100%', marginTop: '$5' }}>
      <Heading size="large" bold>
        {path}
      </Heading>
      <IconButton icon={<Plus />} variant="invisible" size="medium" onClick={onAddTokenClick} />
    </Stack>
  );
}
