import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Box } from '@tokens-studio/ui';
import Login from './pages/Login';
import EmailSuccessMessage from './pages/EmailSuccessMessage';
import { Dashboard } from './components';
import { useTheme } from './hooks/useTheme';
import { lightThemeMode, darkThemeMode } from './stitches.config';
import Signup from './pages/Signup';
import PasswordRecovery from './pages/PasswordRecovery';
import ResetPassword from './pages/ResetPassword';

export default function App() {
  const {
    state: { theme },
  } = useTheme();

  useEffect(() => {
    document.body.className = `${theme}-theme`;
  }, [theme]);

  return (
    <Box
      css={{
        height: '100%',
        color: '$fgDefault',
        ':focus': {
          outline: '1px solid $inputBorderFocus',
        },
      }}
      className={theme === 'dark' ? darkThemeMode : lightThemeMode}
    >
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/password-recovery" element={<PasswordRecovery />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/email-success/:email" element={<EmailSuccessMessage />} />
        <Route path="/" element={<Dashboard />} />
        <Route path="/:selectedTokenSet" element={<Dashboard />} />
        <Route path="/:selectedTokenSet/:selectedToken" element={<Dashboard />} />
      </Routes>
    </Box>
  );
}
