import { BoxShadowTypes } from '../ft-utils/constants/BoxShadowTypes';
import { SingleBoxShadowToken } from '../ft-utils/types/tokens';
import { TokenBoxshadowValue } from '../ft-utils/types/values';

export const getValuePx = (val: string | number) => `${String(val).replace(/[^0-9]/g, '')}px`;

export function getShadowTokenValues(value: SingleBoxShadowToken['value']) {
  if (!value) return {};

  const isArr = Array.isArray(value);
  const showRawValue = typeof value === 'string' || isArr;

  const getResolvedValue = ({ type, x, y, blur, spread, color }: TokenBoxshadowValue) =>
    `${type === BoxShadowTypes.INNER_SHADOW ? ' inset ' : ''} ${getValuePx(x)} ${getValuePx(y)} ${getValuePx(
      blur
    )} ${getValuePx(spread)} ${color}`;

  const resolvedValues: string[] = [];

  if (typeof value === 'string') {
    resolvedValues.push(value);
  } else if (Array.isArray(value)) {
    value.forEach((val) => {
      resolvedValues.push(getResolvedValue(val));
    });
  } else {
    resolvedValues.push(getResolvedValue(value));
  }

  const getRawValue = () => {
    if (!isArr) {
      return value;
    }

    const { dropShadows, innerShadows } = value.reduce(
      (acc, val) => {
        if (val.type === BoxShadowTypes.DROP_SHADOW) {
          acc.dropShadows += 1;
        } else {
          acc.innerShadows += 1;
        }
        return acc;
      },
      { dropShadows: 0, innerShadows: 0 }
    );

    const dropShadowsStr = dropShadows ? `${dropShadows} drop shadow${dropShadows > 1 ? 's' : ''}` : '';
    const innerShadowsStr = innerShadows ? `${innerShadows} inline shadow${innerShadows > 1 ? 's' : ''}` : '';

    return `${dropShadowsStr}${dropShadowsStr && innerShadowsStr ? ', ' : ''}${innerShadowsStr}`;
  };

  return {
    showRawValue,
    resolvedValues,
    shadowRawValue: getRawValue(),
  };
}
