import React, { useRef } from 'react';
import { TokenTypes } from '../../ft-utils/constants/TokenTypes';
import { SingleBorderRadiusToken, SingleBorderWidthToken } from '../../ft-utils/types/tokens';
import useIsInView from '../../hooks/useIsInView';
import useResolvedTokenValue from '../../hooks/useResolvedTokenValue';
import { Box } from '../../shared/components';
import GenericToken from './GenericToken';
import TokenPreview from './partials/TokenPreview';

export default function BorderRadiusToken(props: SingleBorderRadiusToken | SingleBorderWidthToken) {
  const { type, value } = props;

  const tokenRef = useRef<HTMLDivElement>(null);
  const inView = useIsInView<HTMLDivElement>(tokenRef);
  const resolvedBorderValue = useResolvedTokenValue(value, type, inView) as
    | SingleBorderRadiusToken
    | SingleBorderWidthToken;

  function getBorderStyle() {
    if (resolvedBorderValue) {
      const valuePx = `${String(resolvedBorderValue).replace(/[^0-9]/g, '')}px`;
      switch (type) {
        case TokenTypes.BORDER_RADIUS:
          return { borderRadius: valuePx };
        case TokenTypes.BORDER_WIDTH:
          return { borderWidth: valuePx };
        default:
          return {};
      }
    }

    return {};
  }

  return (
    <>
      <TokenPreview>
        <Box
          ref={tokenRef}
          css={{
            width: '32px',
            height: '32px',
            border: '1px solid $accentDefault',
            background: '$accentBg',
            marginBottom: '$5',
            borderRadius: '$medium',
            ...getBorderStyle(),
          }}
        />
      </TokenPreview>
      <GenericToken {...props} />
    </>
  );
}
