import { Box } from '@tokens-studio/ui';
import React, { useMemo } from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { useParams } from 'react-router-dom';
import { SingleToken } from '../../ft-utils/types/tokens';
import { Flex } from '../../shared/components';
import BorderInput from '../AddEditToken/TokenValue/BorderInput';
import ColorInput from '../AddEditToken/TokenValue/ColorInput';
import CompositionInput from '../AddEditToken/TokenValue/CompositionInput';
import GenericInput from '../AddEditToken/TokenValue/GenericInput';
import TypographyInput from '../AddEditToken/TokenValue/TypographyInput';
import BoxShadowInput from '../AddEditToken/TokenValue/BoxShadowInput';
import Scroll from '../../shared/components/Scroll';
import { ColumnId } from '../../types/table';
import { TokenTypes } from '../../ft-utils/constants/TokenTypes';
import SubmitFooter from './edit/SubmitFooter';
import { useTokensTableState } from '../../context/TokensTableContext';
import { TokenBoxshadowValue } from '../../ft-utils/types/values';
import { useTokens } from '../../context/TokensContext';
import { NotificationTypes, useNotifications } from '../../context/NotificationContext';
import { useUpdateTokens } from '../../hooks/useUpdateTokens';
import useKeyDown from '../../hooks/useKeyDown';
import ModifierInputs from '../AddEditToken/ModifierInputs';

export default function InlineEditForm(token: SingleToken) {
  const { type } = token;
  const { tokenData } = useTokens();
  const { addNotification } = useNotifications();
  const { selectedTokenSet } = useParams();
  const { updateTokens } = useUpdateTokens();
  const { selectedCells, setIsEditMode } = useTokensTableState();

  const editedProperty = useMemo(() => {
    if (selectedCells?.length === 1) {
      const { colId } = selectedCells[0];
      return colId;
    }
    return null;
  }, [selectedCells]);

  useKeyDown('Escape', () => setIsEditMode(false));

  const onSubmit = async (values: SingleToken) => {
    if (tokenData && selectedTokenSet) {
      await updateTokens([values.name], values);
      setIsEditMode(false);
    } else {
      addNotification(NotificationTypes.ERORR, 'Something went wrong. Please refresh the page and try again');
    }
  };

  const initialValues = useMemo(() => {
    if (token) {
      const { rawValue, value, type: tokenType } = token;
      let tokenValue: any = rawValue || value;

      if (tokenType === TokenTypes.BOX_SHADOW) {
        // Final form works with fieldArray values and the value must always be an array
        tokenValue = Array.isArray(tokenValue) ? tokenValue : [tokenValue as TokenBoxshadowValue];
      } else if (tokenType === TokenTypes.COMPOSITION) {
        tokenValue = Object.keys(tokenValue).map((key) => ({ property: key, value: tokenValue[key] }));
      }

      return {
        ...token,
        rawValue: undefined,
        value: tokenValue,
      };
    }

    return {};
  }, [token]);

  const getInputs = () => {
    if (editedProperty === ColumnId.RAW_VALUE && type) {
      switch (type) {
        case TokenTypes.BOX_SHADOW:
          return (
            <Scroll height="100%">
              <Box css={{ paddingRight: '$3' }}>
                <BoxShadowInput />
              </Box>
            </Scroll>
          );
        case TokenTypes.TYPOGRAPHY:
          return (
            <Box css={{ width: '100%' }}>
              <TypographyInput />
            </Box>
          );
        case TokenTypes.BORDER:
          return (
            <Box css={{ width: '100%' }}>
              <BorderInput />
            </Box>
          );
        case TokenTypes.COMPOSITION:
          return (
            <Scroll height="100%">
              <Box css={{ paddingRight: '$3' }}>
                <Box css={{ fontSize: '$small', fontWeight: '$sansBold', marginBottom: '$5' }}>Composition values</Box>
                <CompositionInput />
              </Box>
            </Scroll>
          );
        case TokenTypes.COLOR:
          return (
            <Box css={{ width: '100%' }}>
              <Box css={{ fontSize: '$small', fontWeight: '$sansBold', marginBottom: '$5' }}>Color value</Box>
              <ColorInput showLabel={false} autoFocus />
            </Box>
          );
        case TokenTypes.SIZING:
        case TokenTypes.OPACITY:
        case TokenTypes.BORDER_RADIUS:
        case TokenTypes.BORDER_WIDTH:
        case TokenTypes.SPACING:
        default:
          return (
            <Box css={{ width: '100%', maxWidth: '600px' }}>
              <Box css={{ fontSize: '$small', fontWeight: '$sansBold', marginBottom: '$5' }}>Value</Box>
              <GenericInput showLabel={false} tokenTypeOrProp={type} />
            </Box>
          );
      }
    }

    if (editedProperty === ColumnId.MODIFIER) {
      return (
        <Box css={{ width: '100%' }}>
          <Box css={{ fontSize: '$small', fontWeight: '$sansBold', marginBottom: '$5' }}>Modifier</Box>
          <ModifierInputs />
        </Box>
      );
    }

    return null;
  };

  return (
    <Form
      onSubmit={onSubmit}
      mutators={{ ...arrayMutators }}
      initialValues={initialValues}
      keepDirtyOnReinitialize
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Flex
            css={{
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '$4',
              paddingBottom: '$5',
              minWidth: '450px',
            }}
          >
            {getInputs()}
          </Flex>
          <SubmitFooter onCancel={() => setIsEditMode(false)} />
        </form>
      )}
    />
  );
}
