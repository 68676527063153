export enum ColumnId {
  SELECT = 'select',
  NAME = 'name',
  RAW_VALUE = 'rawValue',
  MODIFIER = '$extensions',
  VALUE = 'value',
  TYPE = 'type',
  DESCRIPTION = 'description',
  OPTIONS = 'options',
  PATH = 'path',
}

export const selectableCells = [
  ColumnId.NAME,
  ColumnId.RAW_VALUE,
  ColumnId.MODIFIER,
  ColumnId.VALUE,
  ColumnId.TYPE,
  ColumnId.DESCRIPTION,
];

export const editableCells = [ColumnId.NAME, ColumnId.RAW_VALUE, ColumnId.DESCRIPTION, ColumnId.MODIFIER];
export const copiableCells = [ColumnId.RAW_VALUE, ColumnId.DESCRIPTION, ColumnId.MODIFIER];

export enum GroupBy {
  TOKEN_TYPE = 'type',
  TOKEN_PATH = 'path',
  NO_GROUPING = 'none',
}
