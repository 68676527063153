import React from 'react';
import { useUIState } from '../../context/UIState';
import { Select } from '../../shared/components';
import { SelectItemValue } from '../../shared/components/Select';

const selectItems: SelectItemValue[] = [
  { value: '1', label: '1', id: 1 },
  { value: '2', label: '2', id: 2 },
  { value: '3', label: '3', id: 3 },
  { value: '4', label: '4', id: 4 },
];

export default function LevelsSelector() {
  const { nestingLevel, setNestingLevel } = useUIState();

  const onLevelChange = (value: string) => {
    setNestingLevel(+value);
  };

  return (
    <Select
      items={selectItems}
      label="Nesting level"
      placeHolder=""
      value={`${nestingLevel}`}
      onChange={onLevelChange}
    />
  );
}
