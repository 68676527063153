import React from 'react';
import { Box } from '@tokens-studio/ui';
import { TypographyValues } from '../../../ft-utils/constants/TypographyValues';
import { SingleTypographyToken } from '../../../ft-utils/types/tokens';
import { Flex, InlineBox } from '../../../shared/components';
import TokenIcon from '../../TokenIcon';
import { ComplexTokenProp } from '../partials/ComplexTokenProp';

export default function TypographyValue(props: SingleTypographyToken) {
  const { value } = props;

  if (typeof value === 'string') {
    return <Box css={{ padding: '$3 $5' }}>{value}</Box>;
  }

  return (
    <Flex css={{ flexDirection: 'column', gap: '$2', alignItems: 'flex-start', padding: '$3 $5' }}>
      {Object.keys(value).map((key) => {
        const val = value[key];

        const icon = <TokenIcon propertyKey={key as TypographyValues} />;
        return (
          icon && (
            <ComplexTokenProp key={key}>
              {icon}
              <InlineBox css={{ color: '$fgSubtle' }}>{`${key}: `}</InlineBox>
              <InlineBox css={{ color: '$fgDefault' }}>{val}</InlineBox>
            </ComplexTokenProp>
          )
        );
      })}
    </Flex>
  );
}
