import { PlusCircledIcon, PlusIcon } from '@radix-ui/react-icons';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, EmptyState } from '@tokens-studio/ui';
import { Box } from '../shared/components';

export default function TokensEmptyScreen() {
  const navigate = useNavigate();

  const onAddTokenClick = () => {
    navigate('?new=1&prefix=');
  };

  return (
    <Box css={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
      <EmptyState icon={<PlusCircledIcon width={24} height={24} />} title="Create your first token" description="There’s no tokens, yet. Create individual tokens, or edit JSON.">
        <Button icon={<PlusIcon />} variant="primary" onClick={onAddTokenClick}>
          Create new token
        </Button>
      </EmptyState>
    </Box>
  );
}
