import React, { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { EditableTableColumns } from '../types/EditableTableColumns';
import { Token } from './types';

interface EditRowData {
  tokenName?: string;
  property?: EditableTableColumns;
  newValue?: Token['value'];
}

export interface InlineEditContextType {
  editRowData: EditRowData;
  setEditRowData: React.Dispatch<React.SetStateAction<EditRowData>>;
}

const TokensTableContext = createContext<InlineEditContextType | undefined>(undefined);

function InlineEditContext({ children }: { children: ReactNode }) {
  const [editRowData, setEditRowData] = useState<EditRowData>({});

  const providerValue = useMemo(
    () => ({
      editRowData,
      setEditRowData,
    }),
    [editRowData, setEditRowData]
  );
  return <TokensTableContext.Provider value={providerValue}>{children}</TokensTableContext.Provider>;
}

function useInlineEditState() {
  const context = useContext(TokensTableContext);
  if (context === undefined) {
    throw new Error('useInlineEditState must be used within a InlineEditContext');
  }
  return context;
}

export { InlineEditContext, useInlineEditState };
