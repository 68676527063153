import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { NotificationTypes, useNotifications } from '../context/NotificationContext';
import { useSync } from '../context/SyncContext';
import { useTokens } from '../context/TokensContext';
import { Mixpanel } from '../Mixpanel';
import { supabase } from '../supabase';
import useConfirmModal from './useConfirmModal';

export function useDeleteTokens() {
  const { selectedTokenSet } = useParams();
  const { tokenData, setTokenData } = useTokens();
  const { addNotification } = useNotifications();
  const { pluginIsConnected, allowEdits } = useSync();
  const { confirm } = useConfirmModal();

  const deleteTokens = useCallback(
    async (tokenNames: string[]) => {
      if (!pluginIsConnected && !allowEdits) {
        addNotification(
          NotificationTypes.ERORR,
          'Cannot delete token. Please make sure tokens studio plugin is opened and sync is turned on'
        );
        return;
      }

      const userConfirmation = await confirm({
        title: `Are you sure you want to delete ${tokenNames.length > 1 ? 'these tokens' : tokenNames[0]} ?`,
        description: 'This action cannot be undone',
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Delete',
        visible: true,
      });

      if (userConfirmation && tokenData && selectedTokenSet) {
        const newTokenData = { ...tokenData };

        const newSets = { ...(tokenData.synced_data.sets ?? {}) };

        newSets[selectedTokenSet] = newSets[selectedTokenSet].filter((tkn) => !tokenNames.includes(tkn.name));

        newTokenData.synced_data.sets = newSets;

        const { error, status } = await supabase
          .from('tokens')
          .update({
            synced_data: JSON.stringify(newTokenData.synced_data),
            last_updated_by: 'second-screen',
          })
          .eq('id', tokenData.id);

        setTokenData(newTokenData);

        if (error) {
          addNotification(
            NotificationTypes.ERORR,
            `Error deleting token: '${tokenNames.length > 1 ? 'tokens' : tokenNames[0]}'`
          );
        }

        if (status === 204) {
          Mixpanel.track('Delete token');
          const successMessage =
            tokenNames.length > 1
              ? `Succesfully deleted ${tokenNames.length} tokens`
              : `Succesfully deleted token: ${tokenNames[0]}`;
          addNotification(NotificationTypes.SUCCESS, successMessage);
        }
      }
    },
    // do not add addNotification as dependency as this will cause the table columns to re-render and the the inline edit input will lose focus
    [confirm, pluginIsConnected, selectedTokenSet, tokenData, setTokenData, addNotification, allowEdits]
  );

  return deleteTokens;
}
