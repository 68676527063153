import { IconButton } from '@tokens-studio/ui';
import { PinLeftIcon, PinRightIcon } from '@radix-ui/react-icons';
import { useUIState } from '../context/UIState';
import Box from '../shared/components/Box';

interface CollapseSidebarProps {
  isCollapsed?: boolean;
}

export default function CollapseSidebar({ isCollapsed = false }: CollapseSidebarProps) {
  const { setSidebarCollapsed } = useUIState();

  return (
    <Box>
      <Box css={{ marginRight: isCollapsed ? '$5' : '0', padding: isCollapsed ? '0' : '$5 $2' }}>
        <IconButton
          tooltip={isCollapsed ? 'Expand sidebar' : 'Collapse sidebar'}
          tooltipSide="top"
          icon={isCollapsed ? <PinRightIcon /> : <PinLeftIcon />}
          variant="invisible"
          size="medium"
          onClick={() => setSidebarCollapsed(!isCollapsed)}
        />
      </Box>
    </Box>
  );
}
