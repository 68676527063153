import { PlusIcon } from '@radix-ui/react-icons';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@tokens-studio/ui';

export default function CreateNewTokenButton() {
  const navigate = useNavigate();

  const onAddTokenClick = () => {
    navigate('?new=1&panel=1&prefix=');
  };

  return (
    <Button size="medium" variant="secondary" icon={<PlusIcon />} onClick={onAddTokenClick}>
      <Box css={{ minWidth: '80px' }}>New token</Box>
    </Button>
  );
}
