import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSelectedSetTokens from '../hooks/useSelectedSetTokens';
import { useTokenSearchParams } from '../hooks/useTokenSearchParams';
import Sidepanel from '../shared/components/Sidepanel';
import TokenForm from './AddEditToken/TokenForm';
import TokenList from './TokenListing';

export default function PageContent() {
  const { selectedToken, selectedTokenSet } = useParams();
  const selectedSetTokens = useSelectedSetTokens();
  const token = useMemo(
    () => selectedSetTokens?.find((tkn) => tkn.name === selectedToken),
    [selectedSetTokens, selectedToken]
  );

  const { isNewToken, isPanelOpen } = useTokenSearchParams();
  const navigate = useNavigate();

  if (!isPanelOpen && (isNewToken || selectedToken)) {
    if (isNewToken) {
      return <TokenForm />;
    }

    if (selectedToken) {
      return <TokenForm token={token} />;
    }
  }

  if (isPanelOpen && (isNewToken || selectedToken)) {
    return (
      <>
        <TokenList />
        <Sidepanel
          onClose={() => {
            if (selectedTokenSet) {
              navigate(`/${encodeURIComponent(selectedTokenSet)}`);
            }
          }}
        >
          <TokenForm token={selectedToken && token ? token : undefined} />
        </Sidepanel>
      </>
    );
  }

  return <TokenList />;
}
