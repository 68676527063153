import { Box, Flex } from '../../../shared/components';

interface GenericValueProps {
  value: string;
}

export default function GenericValue(props: GenericValueProps) {
  const { value } = props;

  return (
    <Flex
      title={value}
      css={{
        justifyContent: 'start',
        flexGrow: 1,
        height: '100%',
        padding: '$3 $5',
      }}
    >
      <Box css={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{value}</Box>
    </Flex>
  );
}
