import {
  BlendingModeIcon,
  BorderDashedIcon,
  BorderWidthIcon,
  FontFamilyIcon,
  FontSizeIcon,
  FontStyleIcon,
  LineHeightIcon,
} from '@radix-ui/react-icons';
import React, { ReactElement } from 'react';
import { AliasRegex } from '../../../ft-utils/constants/AliasRegex';
import { TokenTypes } from '../../../ft-utils/constants/TokenTypes';
import { SingleTypographyToken } from '../../../ft-utils/types/tokens';
import { SingleBorderToken } from '../../../ft-utils/types/tokens/SingleBorderToken';
import { TokenTypographyValue } from '../../../ft-utils/types/values';
import { TokenBorderValue } from '../../../ft-utils/types/values/TokenBorderValue';
import { Box, Flex } from '../../../shared/components';
import RawValue from './RawValue';

export default function RawValueObj({ type, value }: SingleTypographyToken | SingleBorderToken) {
  if (!value) {
    return null;
  }

  const hasAlias = typeof value === 'string' && Boolean(value?.match(AliasRegex));

  if (hasAlias && value) {
    return <RawValue rawValue={value} />;
  }

  function getRawValueIcon(valueType: keyof TokenTypographyValue | keyof TokenBorderValue) {
    const iconStyle = { color: 'var(--colors-fgDefault)' };
    switch (valueType) {
      case 'fontFamily':
        return <FontFamilyIcon style={iconStyle} />;
      case 'fontWeight':
        return <FontStyleIcon style={iconStyle} />;
      case 'fontSize':
        return <FontSizeIcon style={iconStyle} />;
      case 'lineHeight':
        return <LineHeightIcon style={iconStyle} />;
      case 'width':
        return <BorderWidthIcon style={iconStyle} />;
      case 'style':
        return <BorderDashedIcon style={iconStyle} />;
      case 'color':
        return <BlendingModeIcon style={iconStyle} />;
      default:
        return null;
    }
  }

  const arrayKeys = type === TokenTypes.TYPOGRAPHY ? Object.keys(value) : ['width', 'style', 'color'];

  return (
    <Flex css={{ gap: '$1', flexDirection: 'column', alignItems: 'flex-start' }}>
      {arrayKeys.reduce((acc: ReactElement[], valueKey: string) => {
        const resolvedRawValue = value[valueKey];

        if (type === TokenTypes.BORDER || resolvedRawValue.match(AliasRegex)) {
          const newRawValue = (
            <Flex
              key={`rawValueAlias-${name}-${valueKey}`}
              css={{ flexDirection: 'row', justifyContent: 'flex-start', gap: '$3' }}
            >
              <Box css={{ minWidth: '15px', height: '15px' }}>
                {getRawValueIcon(valueKey as keyof TokenTypographyValue)}
              </Box>
              <RawValue rawValue={value[valueKey]} />
            </Flex>
          );
          return [...acc, newRawValue];
        }

        return acc;
      }, [])}
    </Flex>
  );
}
