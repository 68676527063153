import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IconButton } from '@tokens-studio/ui';
import { Cross2Icon } from '@radix-ui/react-icons';
import { useUIState } from '../../context/UIState';
import { useTokenSearchParams } from '../../hooks/useTokenSearchParams';
import { Box, Flex } from '../../shared/components';
import CollapseSidebar from '../CollapseSidebar';

export default function TokenFormHeader() {
  const { isNewToken, isPanelOpen } = useTokenSearchParams();
  const { selectedToken, selectedTokenSet } = useParams();
  const { sidebarCollapsed } = useUIState();
  const navigate = useNavigate();

  const getHeaderText = () => {
    if (isNewToken) {
      return 'Create new token';
    }

    if (isPanelOpen) {
      return 'Edit token';
    }

    return selectedToken || 'Edit token';
  };

  return (
    <Flex
      css={{
        justifyContent: 'space-between',
        width: '100%',
        padding: isPanelOpen ? '$7 $7 0' : '$7',
        borderBottom: isPanelOpen ? 'none' : '1px solid $borderMuted',
      }}
    >
      <Flex
        css={{
          width: '100%',
          justifyContent: 'flex-start',
        }}
      >
        {sidebarCollapsed && !isPanelOpen && <CollapseSidebar isCollapsed />}
        <Box
          css={{
            fontSize: '$xxlarge',
            textAlign: 'left',
            fontWeight: '$sansBold',
            color: '$fgDefault',
          }}
        >
          {getHeaderText()}
        </Box>
      </Flex>
      {isPanelOpen && (
        <IconButton
          variant="invisible"
          icon={<Cross2Icon />}
          size="medium"
          onClick={() => {
            if (selectedTokenSet) {
              navigate(`/${encodeURIComponent(selectedTokenSet)}`);
            }
          }}
        />
      )}
    </Flex>
  );
}
