import { Box, Tooltip } from '@tokens-studio/ui';
import React, { useMemo } from 'react';
import { ColorModifierTypes } from '../ft-utils/constants/ColorModifierTypes';
import { ColorModifier } from '../ft-utils/types/Modifier';

interface ModifierStringProps {
  modifier: ColorModifier | undefined;
  withTooltip?: boolean;
}

export default function ModifierString({ modifier, withTooltip = true }: ModifierStringProps) {
  const modifierString = useMemo(() => {
    if (!modifier) return '';

    return modifier.type === ColorModifierTypes.MIX
      ? `mix(${modifier.color}, ${modifier.value}) / ${modifier.space}`
      : `${modifier.type}(${modifier.value}) / ${modifier.space}`;
  }, [modifier]);

  return withTooltip ? (
    <Tooltip label={modifierString} side="left">
      <Box css={{ padding: '$3 $5' }}>{modifierString}</Box>
    </Tooltip>
  ) : (
    <Box css={{ padding: '$3 $5' }}>{modifierString}</Box>
  );
}
