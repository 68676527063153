import * as LabelPrimitive from '@radix-ui/react-label';
import { styled } from '../../stitches.config';

const Label = styled(LabelPrimitive.Root, {
  fontSize: '$base',
  fontWeight: '$sansBold',
  color: '$fgDefault',
  userSelect: 'none',
  variants: {
    small: {
      true: {
        fontSize: '$small',
        fontWeight: '$sansRegular',
      },
    },
    regular: {
      true: {
        fontWeight: '$sansRegular',
      },
    },
    bold: {
      true: {
        fontWeight: '$sansBold',
      },
    },
  },
});

export default Label;
