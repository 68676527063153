import { TokenSets } from '../context/types';

const renameTokenSet = (oldObj: TokenSets, oldKey: string, newKey: string): TokenSets => {
  const keys = Object.keys(oldObj);
  const newObj = keys.reduce((acc, val) => {
    if (val === oldKey) {
      acc[newKey] = oldObj[oldKey];
    } else {
      acc[val] = oldObj[val];
    }
    return acc;
  }, {});

  return newObj;
};

export default renameTokenSet;
