import { Box } from '@tokens-studio/ui';
import React from 'react';
import { SingleToken } from '../../../ft-utils/types/tokens';
import useResolvedTokenValue from '../../../hooks/useResolvedTokenValue';

export default function GenericResolvedValue({ value, type }: SingleToken) {
  const resolvedValue = (useResolvedTokenValue(value, type, true) as string) ?? value;

  if (!resolvedValue) return null;

  return (
    <Box
      css={{
        padding: '$3 $5',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        alignItems: 'flex-start',
      }}
    >
      {resolvedValue}
    </Box>
  );
}
