import { useFormState } from 'react-final-form';
import { useParams } from 'react-router-dom';
import { TokenTypes } from '../../../ft-utils/constants/TokenTypes';
import { SingleToken } from '../../../ft-utils/types/tokens';
import BorderInput from './BorderInput';
import BoxShadowInput from './BoxShadowInput';
import ColorInput from './ColorInput';
import CompositionInput from './CompositionInput';
import GenericInput from './GenericInput';
import TypographyInput from './TypographyInput';

export default function TokenValueInput() {
  const { selectedToken } = useParams();
  const { values } = useFormState<SingleToken>();

  switch (values.type) {
    case TokenTypes.BOX_SHADOW:
      return <BoxShadowInput />;
    case TokenTypes.TYPOGRAPHY:
      return <TypographyInput key={selectedToken} />;
    case TokenTypes.BORDER:
      return <BorderInput key={selectedToken} />;
    case TokenTypes.COMPOSITION:
      return <CompositionInput key={selectedToken} />;
    case TokenTypes.COLOR:
      return <ColorInput key={selectedToken} />;
    // @ts-ignore
    case TokenTypes.SIZING:
    case TokenTypes.OPACITY:
    case TokenTypes.BORDER_RADIUS:
    case TokenTypes.BORDER_WIDTH:
    case TokenTypes.SPACING:
    default:
      return <GenericInput tokenTypeOrProp={values.type} key={selectedToken} />;
  }
}
