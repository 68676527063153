import Color from 'colorjs.io';
import * as Sentry from '@sentry/react';
import { transparentize } from './color/transparentize';
import { mix } from './color/mix';
import { darken } from './color/darken';
import { lighten } from './color/lighten';
import { ColorModifier } from './types/Modifier';
import { ColorModifierTypes } from './constants/ColorModifierTypes';

export function modifyColor(baseColor: string, modifier: ColorModifier) {
  if (baseColor) {
    const color = new Color(baseColor);
    let returnedColor = color;
    try {
      switch (modifier.type) {
        case ColorModifierTypes.LIGHTEN:
          returnedColor = lighten(color, modifier.space, Number(modifier.value));
          break;
        case ColorModifierTypes.DARKEN:
          returnedColor = darken(color, modifier.space, Number(modifier.value));
          break;
        case ColorModifierTypes.MIX:
          returnedColor = mix(color, modifier.space, Number(modifier.value), new Color(modifier.color));
          break;
        case ColorModifierTypes.ALPHA: {
          returnedColor = transparentize(color, Number(modifier.value));
          break;
        }
        default:
          returnedColor = color;
          break;
      }
      returnedColor = returnedColor.to(modifier.space);
      return returnedColor.toString({ inGamut: true, precision: 3 });
    } catch (e) {
      Sentry.captureException(e);
      return baseColor;
    }
  }

  return '';
}
