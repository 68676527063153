import { SingleColorToken } from '../../../ft-utils/types/tokens';
import { Box, Flex } from '../../../shared/components';

export default (props: SingleColorToken) => {
  const { value } = props;

  return (
    <Flex
      title={value}
      css={{
        height: '100%',
        flexGrow: 1,
        padding: '$3 $5',
        justifyContent: 'flex-start',
        gap: '$2',
      }}
    >
      <Box css={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}> {value}</Box>
    </Flex>
  );
};
