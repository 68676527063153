import { Stack } from '@tokens-studio/ui';
import React, { useMemo } from 'react';
import { Field, useField } from 'react-final-form';
import { ColorModifierTypes } from '../../ft-utils/constants/ColorModifierTypes';
import { ColorSpaceTypes } from '../../ft-utils/constants/ColorSpaceTypes';
import { TokenTypes } from '../../ft-utils/constants/TokenTypes';
import { Select } from '../../shared/components';
import ModifiedColor from './ModifiedColor';
import ColorInput from './TokenValue/ColorInput';
import GenericInput from './TokenValue/GenericInput';

const MODIFIER_TYPE_PATH = '$extensions.[studio.tokens].modify.type';

export default function ModifierInputs() {
  const {
    input: { value: modifierType },
  } = useField(MODIFIER_TYPE_PATH);

  const modifierTypes = useMemo(
    () => Object.values(ColorModifierTypes).map((val) => ({ value: val, label: val, id: val })),
    []
  );

  const colorSpaceTypes = useMemo(
    () => Object.values(ColorSpaceTypes).map((val) => ({ value: val, label: val, id: val })),
    []
  );

  const getValueInputLabel = () => {
    switch (modifierType) {
      case ColorModifierTypes.ALPHA:
        return 'Opacity';
      case ColorModifierTypes.MIX:
        return 'Ratio';
      default:
        return 'Amount';
    }
  };

  return (
    <Stack
      direction="column"
      align="start"
      gap={4}
      css={{
        padding: '$5',
        borderRadius: '$medium',
      }}
    >
      <Stack direction="row" gap={4}>
        <Field
          name={MODIFIER_TYPE_PATH}
          render={(fieldProps) => <Select {...fieldProps.input} items={modifierTypes || []} label="Type:" />}
        />
        <Field
          name="$extensions.[studio.tokens].modify.space"
          render={(fieldProps) => <Select {...fieldProps.input} items={colorSpaceTypes || []} label="Space:" />}
        />
      </Stack>
      {modifierType === ColorModifierTypes.MIX && (
        <ColorInput showLabel={false} valuePath="$extensions.[studio.tokens].modify.color" />
      )}
      <GenericInput
        label={getValueInputLabel()}
        tokenTypeOrProp={TokenTypes.OTHER}
        valuePath="$extensions.[studio.tokens].modify.value"
      />
      <ModifiedColor />
    </Stack>
  );
}
