import { TokensIcon } from '@radix-ui/react-icons';
import { IconButton } from '@tokens-studio/ui';
import React, { useCallback, useEffect, useState } from 'react';
import { useField } from 'react-final-form';
import { AliasRegex } from '../../../ft-utils/constants/AliasRegex';
import { BorderValues } from '../../../ft-utils/constants/BorderValues';
import { TokenTypes } from '../../../ft-utils/constants/TokenTypes';
import { Box, Flex } from '../../../shared/components';
import ColorInput from './ColorInput';
import GenericInput from './GenericInput';

export default function BorderInput() {
  const valueField = useField('value');
  const {
    input: { value },
  } = valueField;

  const [useReference, setUseReference] = useState(false);

  useEffect(() => {
    if (value) {
      setUseReference(
        typeof value === 'string' || (typeof value === 'string' && Boolean(value.trim().match(AliasRegex)))
      );
    }
  }, [value]);

  const toggleUseReference = useCallback(() => {
    valueField.input.onChange('');
    setUseReference(!useReference);
  }, [useReference, valueField]);

  const borderInputs = (
    <>
      <Box
        css={{
          marginBottom: '$3',
          fontSize: '$base',
          fontWeight: '$sansMedium',
          color: '$fgDefault',
          userSelect: 'none',
        }}
      >
        Border values
      </Box>
      {Object.values(BorderValues).map((key) => (
        <Box key={key} css={{ display: 'flex', alignItems: 'center', gap: '$5', padding: '$3 0' }}>
          {key === BorderValues.BORDER_COLOR ? (
            <ColorInput showLabel={false} valuePath={`value.${key}`} autoFocus />
          ) : (
            <GenericInput showLabel={false} valuePath={`value.${key}`} tokenTypeOrProp={key} />
          )}
        </Box>
      ))}
    </>
  );

  return (
    <Flex css={{ flexDirection: 'column', alignItems: 'stretch' }}>
      <Flex css={{ alignSelf: 'flex-end' }}>
        <IconButton
          variant="invisible"
          size="medium"
          tooltip={useReference ? 'Add values' : 'Use reference'}
          icon={<TokensIcon />}
          selected={useReference}
          onClick={toggleUseReference}
        />
      </Flex>

      {useReference ? <GenericInput tokenTypeOrProp={TokenTypes.BORDER} /> : borderInputs}
    </Flex>
  );
}
