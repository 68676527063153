import { Cross1Icon, Pencil2Icon } from '@radix-ui/react-icons';
import { KeyboardEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, TextInput } from '@tokens-studio/ui';
import { NotificationTypes, useNotifications } from '../context/NotificationContext';
import { useSync } from '../context/SyncContext';
import { useTokens } from '../context/TokensContext';
import { Flex } from '../shared/components';
import renameTokenSet from '../utils/renameKey';
import updateRemoteData from '../utils/updateRemoteData';

interface TokenSetNameProps {
  tokenSetName: string;
}

export default function TokenSetName({ tokenSetName }: TokenSetNameProps) {
  const { pluginIsConnected, allowEdits } = useSync();
  const navigate = useNavigate();
  const { tokenData, setTokenData } = useTokens();
  const { addNotification } = useNotifications();
  const [renaming, setRenaming] = useState(false);
  const [name, setName] = useState(tokenSetName);

  const resetInput = useCallback(() => {
    setRenaming(false);
    setName(tokenSetName);
  }, [tokenSetName]);

  useEffect(() => {
    resetInput();
  }, [tokenSetName, resetInput]);

  const onButtonClick = () => {
    if (renaming) {
      resetInput();
    } else {
      setRenaming(true);
      setName(tokenSetName);
    }
  };

  const onInputKeyUp = async (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (tokenData?.synced_data.sets) {
        if (!pluginIsConnected && !allowEdits) {
          addNotification(
            NotificationTypes.ERORR,
            'Cannot rename the set. Please make sure Tokens Studio plugin is opened and sync is turned on'
          );
        } else if (!name) {
          addNotification(NotificationTypes.ERORR, 'Set name is required');
        } else if (Object.keys(tokenData?.synced_data.sets).includes(name)) {
          addNotification(NotificationTypes.ERORR, 'Set name must be unique');
        } else {
          try {
            const newSets = renameTokenSet(tokenData.synced_data.sets, tokenSetName, name);

            const newData = {
              ...tokenData,
              synced_data: {
                ...tokenData.synced_data,
                sets: newSets,
              },
            };
            await updateRemoteData(newData);
            setTokenData(newData);
            navigate(`/${encodeURIComponent(name)}`);
            resetInput();
          } catch (err) {
            const error = err as Error;
            addNotification(NotificationTypes.ERORR, error.message);
          }
        }
      }
    } else if (e.key === 'Escape') {
      resetInput();
    }
  };

  return (
    <Flex css={{ gap: '$2' }}>
      {renaming ? (
        <TextInput autoFocus value={name} onKeyUp={onInputKeyUp} onChange={(e) => setName(e.target.value)} />
      ) : (
        tokenSetName
      )}
      <IconButton
        icon={renaming ? <Cross1Icon /> : <Pencil2Icon />}
        tooltip={renaming ? 'Cancel' : 'Rename set'}
        tooltipSide="top"
        variant="invisible"
        size="medium"
        onClick={onButtonClick}
      />
    </Flex>
  );
}
