import { useRef } from 'react';
import { SingleColorToken } from '../../../ft-utils/types/tokens';
import { Box, Flex } from '../../../shared/components';
import ColorSwatch from '../../ColorSwatch';
import useResolvedTokenValue from '../../../hooks/useResolvedTokenValue';
import { TokenTypes } from '../../../ft-utils/constants/TokenTypes';
import useIsInView from '../../../hooks/useIsInView';

export default (props: SingleColorToken) => {
  const { value, $extensions } = props;
  const tokenRef = useRef<HTMLDivElement>(null);
  const inView = useIsInView<HTMLDivElement>(tokenRef);

  const resolvedColorValue = (useResolvedTokenValue(value, TokenTypes.COLOR, inView) as string) ?? value;

  return (
    <Flex
      title={resolvedColorValue}
      ref={tokenRef}
      css={{
        flexGrow: 1,
        padding: '$3 $5',
        justifyContent: 'flex-start',
        gap: '$2',
      }}
    >
      <ColorSwatch value={resolvedColorValue} $extensions={$extensions} key={value} />
      <Box css={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}> {resolvedColorValue}</Box>
    </Flex>
  );
};
