import { PlusIcon } from '@radix-ui/react-icons';
import React, { KeyboardEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TextInput } from '@tokens-studio/ui';
import { NotificationTypes, useNotifications } from '../../context/NotificationContext';
import { useSync } from '../../context/SyncContext';
import { useTokens } from '../../context/TokensContext';
import { Mixpanel } from '../../Mixpanel';
import updateRemoteData from '../../utils/updateRemoteData';
import { validateTokenSetName } from '../../ft-utils/validateTokensetName';

export default function CreateNewSet() {
  const { pluginIsConnected, allowEdits } = useSync();
  const navigate = useNavigate();
  const { tokenData, setTokenData } = useTokens();
  const { addNotification } = useNotifications();
  const [name, setName] = useState('Untitled set');
  const [inputVisible, setInputVisibility] = useState(false);

  const resetInput = () => {
    setInputVisibility(false);
    setName('');
  };

  const onInputKeyUp = async (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (tokenData?.synced_data.sets) {
        if (!pluginIsConnected && !allowEdits) {
          addNotification(
            NotificationTypes.ERORR,
            'Cannot create a new set. Please make sure Tokens Studio plugin is opened and sync is turned on'
          );
        } else if (!name) {
          addNotification(NotificationTypes.ERORR, 'Set name is required');
        } else {
          const { valid, error: setNameError } = validateTokenSetName(name, Object.keys(tokenData.synced_data.sets));
          if (valid) {
            resetInput();
            try {
              const newData = {
                ...tokenData,
                synced_data: {
                  ...tokenData.synced_data,
                  sets: {
                    ...tokenData?.synced_data.sets,
                    [name]: [],
                  },
                },
              };
              await updateRemoteData(newData);
              Mixpanel.track('Create new token set');
              setTokenData(newData);
              navigate(`/${encodeURIComponent(name)}`);
            } catch (err) {
              const error = err as Error;
              addNotification(NotificationTypes.ERORR, error.message);
            }
          } else if (setNameError) {
            addNotification(NotificationTypes.ERORR, setNameError);
          }
        }
      }
    } else if (e.key === 'Escape') {
      resetInput();
    }
  };

  let content;
  if (inputVisible) {
    content = <TextInput onKeyUp={onInputKeyUp} autoFocus value={name} onChange={(e) => setName(e.target.value)} />;
  } else {
    content = (
      <Button icon={<PlusIcon />} variant="invisible" onClick={() => setInputVisibility(true)}>
        Create new set
      </Button>
    );
  }

  return <Box css={{ padding: '$3' }}>{content}</Box>;
}
