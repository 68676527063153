import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  ColumnSizingState,
  getGroupedRowModel,
  getExpandedRowModel,
  ExpandedState,
} from '@tanstack/react-table';
import { useParams } from 'react-router-dom';
import { useVirtual } from 'react-virtual';
import { Box } from '@tokens-studio/ui';
import TableHeader from './TableHeader';
import useTokenTableData from '../../hooks/useTokenTableData';
import { Token } from '../../context/types';
import useColumns from './useColumns';
import { useTokensTableState } from '../../context/TokensTableContext';
import useLocalStorage from '../../hooks/useLocalStorage';
import getColumnSizing from '../../utils/table/calculateColumnSizing';
import useTableKeyUp from '../../hooks/useTableKeyUp';
import { Flex } from '../../shared/components';
import TableCell from './TableCell';
import { ColumnId } from '../../types/table';
import { useUIState } from '../../context/UIState';

interface TokensTableProps {
  getParentRef: () => React.RefObject<HTMLDivElement>;
}

export default ({ getParentRef }: TokensTableProps) => {
  const { selectedTokenSet } = useParams();
  const tableTokens = useTokenTableData();
  const columns: any = useColumns();
  const { nestingLevel, tableGroupBy } = useUIState();
  const { rowSelection, setRowSelection, setSelectedCells } = useTokensTableState();
  const [storedValue, setStoredValue] = useLocalStorage('columnSizing', {});
  const [columnSizing, setColumnSizing] = useState<ColumnSizingState>(storedValue);
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const parentRef = useMemo(() => getParentRef(), [getParentRef]);

  const getRowId = (originalRow: Token) => originalRow.name;

  const table = useReactTable<Token>({
    data: tableTokens,
    columns,
    state: {
      rowSelection,
      columnSizing,
      grouping: tableGroupBy,
      expanded,
    },
    onColumnSizingChange: (sizing) => {
      setColumnSizing(sizing);
      setStoredValue(sizing);
    },
    onExpandedChange: setExpanded,
    getRowId,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    enableColumnResizing: true,
    autoResetExpanded: false,
    enableSortingRemoval: true,
    filterFromLeafRows: false,
  });

  useTableKeyUp({ table });

  useEffect(() => {
    const [{ name }] = tableTokens;
    setSelectedCells([{ colId: ColumnId.NAME, rowId: name }]);
    // Do not update the deps array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTokenSet]);

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtual({
    parentRef,
    size: rows.length,
    overscan: 15,
    estimateSize: useCallback(() => 40, []),
  });

  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;
  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom = virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;

  // set column sizing if user did not interact with sizing handlers
  useEffect(() => {
    if (parentRef && parentRef.current) {
      const parentWidth = parentRef.current.offsetWidth;
      const cols = table.getAllColumns();
      const info = getColumnSizing(parentWidth, storedValue, cols);
      setColumnSizing(info);
    }
    // this is intended, do not add storedValue as dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getParentRef, table, parentRef]);

  useEffect(() => {
    table.resetRowSelection();
  }, [selectedTokenSet, table]);

  // open the groups by default
  React.useMemo(() => {
    const { rows: expandedRows } = table.getExpandedRowModel();
    const state = expandedRows.reduce((acc, row) => {
      acc[row.id] = true;
      return acc;
    }, {});

    setExpanded(state);
    // we need nesting level and selected token set as deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTokenSet, table, nestingLevel, tableGroupBy]);

  return (
    <Box css={{ marginBottom: '$7' }}>
      <TableHeader table={table} />
      <Box
        style={{
          background: '$borderMuted',
          borderSpacing: '1px',
          fontFamily: '$sans',
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <Box
          css={{
            width: '100%',
            position: 'relative',
            paddingBottom,
            paddingTop,
          }}
        >
          {virtualRows.map((virtualRow) => {
            const row = rows[virtualRow.index];
            const isSelected = rowSelection[row.id];

            return (
              <Flex
                key={row.id}
                ref={virtualRow.measureRef}
                css={{
                  borderBottom: isSelected ? '1px solid $accentBorder' : '1px solid $borderMuted',
                  alignItems: 'stretch',
                  justifyContent: 'flex-start',
                }}
              >
                {table.getAllColumns().map((col) => {
                  const cells = row.getVisibleCells();
                  const cell = cells.find((tableCell) => tableCell.column.id === col.id);

                  if (cell?.column.id === ColumnId.PATH && !cell.getIsGrouped()) return null;

                  return cell ? <TableCell key={cell?.id} cell={cell} /> : null;
                })}
              </Flex>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
