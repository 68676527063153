import { Row, RowSelectionState } from '@tanstack/react-table';
import { TableTokenItem } from '../../ft-utils/types/TableTokenItem';

export default (
  rowSelection: RowSelectionState,
  sortedSubRows: Row<TableTokenItem>[],
  initialRowId: string,
  direction: number
) => {
  const newRowSelection = structuredClone(rowSelection);
  const rowSelectionKeys = Object.keys(newRowSelection);
  const lastSelectedRowId = rowSelectionKeys[rowSelectionKeys.length - 1];
  const currentRowIndex = sortedSubRows.findIndex(({ id }) => id === lastSelectedRowId);
  const selectionStart = sortedSubRows.findIndex((itm) => itm.id === initialRowId);

  let nextRowId = '';
  const nextRowIndex = currentRowIndex + direction;

  if (sortedSubRows[nextRowIndex]) {
    nextRowId = sortedSubRows[nextRowIndex].id;
  }

  // deselect last item if selection is above/below starting point
  if ((direction === 1 && nextRowIndex <= selectionStart) || (direction === -1 && nextRowIndex >= selectionStart)) {
    delete newRowSelection[lastSelectedRowId];
  }

  if (nextRowId) {
    return { ...newRowSelection, [nextRowId]: true };
  }
  return newRowSelection;
};
