import { useCallback, useEffect, useMemo } from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { useNavigate, useParams } from 'react-router-dom';
import { Token } from '../../context/types';
import { TokenTypes } from '../../ft-utils/constants/TokenTypes';
import { SingleCompositionToken, SingleToken } from '../../ft-utils/types/tokens';
import { Box, Flex } from '../../shared/components';
import Scroll from '../../shared/components/Scroll';
import TokenDescription from './TokenDescriptionInput';
import TokenFormFooter from './TokenFormFooter';
import TokenFormHeader from './TokenFormHeader';
import TokenName from './TokenNameInput';
import TokenValueInput from './TokenValue/TokenValueInput';
import { useTokenSearchParams } from '../../hooks/useTokenSearchParams';
import { TokenBoxshadowValue } from '../../ft-utils/types/values';
import { useTokens } from '../../context/TokensContext';
import { useSaveToken } from '../../hooks/useSaveToken';
import { NotificationTypes, useNotifications } from '../../context/NotificationContext';
import TokenTypeSelector from './TokenTypeSelector';
import ColorModifier from './ColorModifier';
import { ResolveTokenValuesResult } from '../../ft-utils/tokenHelpers';
import useSelectedSetTokens from '../../hooks/useSelectedSetTokens';
import { trimStrings } from '../../utils/trimString';

interface TokenFormProps {
  token?: Token;
}

interface TokenFormComponentProps {
  handleSubmit: () => void;
  selectedToken?: string;
  dirty: boolean;
}
function TokenFormComponent({ handleSubmit, selectedToken, dirty }: TokenFormComponentProps) {
  const navigate = useNavigate();

  const onKeyDown = useCallback(
    (ev: KeyboardEvent) => {
      if (ev.key === 'Escape') {
        if (!dirty || confirm('You will lose unsaved changes.')) {
          navigate(-1);
        }
      }
    },
    [dirty, navigate]
  );

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);

  return (
    <Box css={{ width: '100%', maxWidth: '800px', padding: '$7' }}>
      <form onSubmit={handleSubmit}>
        <Flex css={{ flexDirection: 'column', gap: '$7' }}>
          <Box css={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '$6' }}>
            <Flex css={{ width: '100%', gap: '$2', alignItems: 'center' }}>
              <Box css={{ flexGrow: 1 }}>
                <TokenName key={selectedToken} />
              </Box>
              <Box css={{ marginTop: '$2' }}>
                <TokenTypeSelector />
              </Box>
            </Flex>
            <TokenValueInput />
            <ColorModifier />
            <TokenDescription />
          </Box>
        </Flex>
        <TokenFormFooter />
      </form>
    </Box>
  );
}

export default function TokenForm({ token }: TokenFormProps) {
  const { tokenPrefix, isNewToken } = useTokenSearchParams();
  const { saveToken } = useSaveToken();
  const { selectedToken } = useParams();
  const { selectedTokenSet } = useParams();
  const { tokenData } = useTokens();
  const { addNotification } = useNotifications();
  const selectedSetTokens = useSelectedSetTokens();

  const onSubmit = async (values: ResolveTokenValuesResult) => {
    if (tokenData && selectedTokenSet) {
      const safeValues = trimStrings(values) as ResolveTokenValuesResult;
      saveToken(safeValues);
    } else {
      addNotification(NotificationTypes.ERORR, 'Something went wrong. Please refresh the page and try again');
    }
  };

  const initialValues = useMemo(() => {
    if (token) {
      const { value, type } = token;
      let tokenValue: SingleToken['value'] = value;

      if (tokenValue) {
        // Final form works with fieldArray values and the value must always be an array
        if (type === TokenTypes.BOX_SHADOW) {
          if (typeof tokenValue !== 'string') {
            tokenValue = Array.isArray(tokenValue) ? tokenValue : [tokenValue as TokenBoxshadowValue];
          }
        } else if (type === TokenTypes.COMPOSITION) {
          tokenValue = Object.keys(tokenValue).map((key) => ({
            property: key,
            value: tokenValue ? tokenValue[key] : '',
          })) as SingleCompositionToken['value'];
        }

        return {
          ...token,
          value: tokenValue,
        };
      }

      return {};
    }
    // create new
    return {
      name: tokenPrefix || 'new-token-name',
      type: TokenTypes.COLOR,
      value: '',
      description: '',
    };
  }, [tokenPrefix, token]);

  const validateTokenForm = (values: SingleToken) => {
    const errors = {} as SingleToken;
    if (!values.name) {
      errors.name = 'Token name is required';
    } else if (isNewToken || values.name !== selectedToken) {
      const nameExists = selectedSetTokens?.some((itm) => itm.name === values.name);

      if (nameExists) {
        errors.name = 'Token name must be unique';
      }
    } else {
      return undefined;
    }

    return errors;
  };

  return (
    <Scroll height="100%">
      <Flex
        css={{
          width: '100%',
          height: '100%',
          flexDirection: 'column',
          alignItems: 'start',
        }}
      >
        <TokenFormHeader />
        <Form
          onSubmit={onSubmit}
          validate={validateTokenForm}
          mutators={{ ...arrayMutators }}
          validateOnBlur
          initialValues={initialValues}
          render={({ handleSubmit, dirty }) => (
            <TokenFormComponent handleSubmit={handleSubmit} selectedToken={selectedToken} dirty={dirty} />
          )}
        />
      </Flex>
    </Scroll>
  );
}
