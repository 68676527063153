import React, { useRef } from 'react';
import { SingleTypographyToken } from '../../ft-utils/types/tokens';
import { Box, Flex } from '../../shared/components';
import TokenName from './partials/TokenName';
import TokenPreview from './partials/TokenPreview';
import ResolvedValueBox from './partials/ResolvedValue';
import RawValueObj from './partials/RawValueObj';
import useResolvedTokenValue from '../../hooks/useResolvedTokenValue';
import useIsInView from '../../hooks/useIsInView';
import { TokenTypographyValue } from '../../ft-utils/types/values';

export default function TypographyToken(props: SingleTypographyToken) {
  const { name, value, type } = props;
  const tokenRef = useRef<HTMLDivElement>(null);
  const inView = useIsInView<HTMLDivElement>(tokenRef);

  const resolvedTokenValue = useResolvedTokenValue(value, type, inView) as TokenTypographyValue;

  function getResolvedValue() {
    if (!resolvedTokenValue) return '';

    return `${resolvedTokenValue.fontFamily} ${resolvedTokenValue.fontWeight} ${resolvedTokenValue.fontSize} / ${resolvedTokenValue.lineHeight}`;
  }

  return (
    <>
      <TokenPreview>
        <Box
          ref={tokenRef}
          css={{
            width: '100%',
            height: '24px',
            color: '$fgDefault',
            lineHeight: '24px',
            marginBottom: '$3',
            fontSize: '32px',
            fontWeight: '$sansBold',
          }}
        >
          aA
        </Box>
      </TokenPreview>
      <Flex css={{ flexDirection: 'column', alignItems: 'flex-start', gap: '$2', overflow: 'hidden' }}>
        <TokenName name={name} />
        <RawValueObj {...props} />
        <ResolvedValueBox isAccent value={getResolvedValue()} />
      </Flex>
    </>
  );
}
