import { Box } from '@tokens-studio/ui';
import React from 'react';

interface EditPopoverProps {
  children: React.ReactNode;
}

export default function EditPopoverContent({ children }: EditPopoverProps) {
  return (
    <Box
      css={{
        background: '$bgDefault',
        border: '1px solid $borderMuted',
        borderRadius: '$medium',
        padding: '$5',
        boxShadow: '$contextMenu',
      }}
    >
      {children}
    </Box>
  );
}
