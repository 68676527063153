import { CodeIcon, ListBulletIcon, TableIcon } from '@radix-ui/react-icons';
import { ToggleGroup } from '@tokens-studio/ui';
import { useUIState, ViewTypes } from '../../context/UIState';
import { Mixpanel } from '../../Mixpanel';

export default function ViewTypesSelector() {
  const { tokenView, setTokenView } = useUIState();

  const setView = (value: ViewTypes) => {
    setTokenView(value);
    Mixpanel.track(`Token view: ${value}`);
  };

  return (
    <ToggleGroup type="single" value={tokenView}>
      <ToggleGroup.Item aria-label="List view" value={ViewTypes.LIST} onClick={() => setView(ViewTypes.LIST)}>
        <ListBulletIcon />
      </ToggleGroup.Item>
      <ToggleGroup.Item aria-label="Table view" value={ViewTypes.TABLE} onClick={() => setView(ViewTypes.TABLE)}>
        <TableIcon />
      </ToggleGroup.Item>
      <ToggleGroup.Item aria-label="JSON view" value={ViewTypes.JSON} onClick={() => setView(ViewTypes.JSON)}>
        <CodeIcon />
      </ToggleGroup.Item>
    </ToggleGroup>
  );
}
