import { useMemo } from 'react';
import { useTokens } from '../context/TokensContext';
import { TokenSetStatus } from '../ft-utils/constants/TokenSetStatus';

export const useTokenSetsStatus = () => {
  const { sets, activeTheme, themes, usedTokenSets } = useTokens();

  return useMemo(() => {
    const selectedTokenSets: Record<string, TokenSetStatus> = usedTokenSets || {};

    if (themes && sets) {
      const activeThemeObjectList = themes.filter((theme) =>
        Object.values(activeTheme).some((themeId) => themeId === theme.id)
      );

      activeThemeObjectList.forEach((themeData) => {
        const usedSets = themeData.selectedTokenSets;
        Object.entries(usedSets).forEach(([tokenSet, status]) => {
          if (status !== TokenSetStatus.DISABLED) {
            selectedTokenSets[tokenSet] = status;
          }
        });
      });

      return selectedTokenSets;
    }

    return selectedTokenSets;
  }, [themes, activeTheme, usedTokenSets, sets]);
};
