import { TextInput } from '@tokens-studio/ui';
import React from 'react';
import { Field } from 'react-final-form';
import { Box, Label } from '../../shared/components';

export default function TokenNameInput() {
  return (
    <Box css={{ display: 'flex', flexDirection: 'column', gap: '$3' }}>
      <Label htmlFor="tokenName">Unique name</Label>
      <Field
        name="name"
        render={({ input, meta }) => (
          <>
            <TextInput
              autoFocus
              {...input}
              id="tokenName"
              type="text"
              placeholder="Add a name"
              validationStatus={meta.error ? 'error' : undefined}
              spellCheck={false}
            />
            <Box css={{ height: '15px', fontSize: '$small', color: '$dangerFg', opacity: meta.error ? 1 : 0 }}>
              {meta.error}
            </Box>
          </>
        )}
      />
    </Box>
  );
}
