import { MinusIcon, PlusIcon } from '@radix-ui/react-icons';
import { FieldArray } from 'react-final-form-arrays';
import React from 'react';
import { Field, useForm } from 'react-final-form';
import { IconButton } from '@tokens-studio/ui';
import { Properties } from '../../../ft-utils/constants/Properties';
import { Box, Flex, Select } from '../../../shared/components';
import { filterValidCompositionTokenTypes } from '../../../utils/filterValidCompositionTypes';
import CompositionTokenValue from './CompositionTokenValue';

export default function CompositionInput() {
  const {
    mutators: { push },
  } = useForm();

  const propertiesMenuItems = React.useMemo(
    () =>
      filterValidCompositionTokenTypes(Object.keys(Properties)).map((key: string) => ({
        value: key,
        label: key,
        id: key,
      })),
    []
  );

  return (
    <Box css={{ paddingBottom: '$5' }}>
      <Flex css={{ justifyContent: 'flex-end', marginBottom: '$3' }}>
        <IconButton
          tooltip="Add style"
          variant="invisible"
          size="medium"
          icon={<PlusIcon />}
          onClick={() => push('value', undefined)}
        />
      </Flex>
      <Flex
        css={{
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: '$5',
          alignItems: 'stretch',
        }}
      >
        <FieldArray name="value">
          {({ fields }) =>
            fields.map((name, index) => (
              <Flex key={name} css={{ gap: '$4' }}>
                <Box css={{}}>
                  <Field
                    name={`${name}.property`}
                    render={(fieldProps) => <Select items={propertiesMenuItems} label="Prop: " {...fieldProps.input} />}
                  />
                </Box>
                <Box css={{ flexGrow: 1 }}>
                  <CompositionTokenValue
                    autoFocus={index === 0}
                    valuePath={`${name}.value`}
                    property={fields.value[index] && fields.value[index].property}
                  />
                </Box>
                <IconButton
                  tooltip="Remove this style"
                  variant="invisible"
                  size="medium"
                  icon={<MinusIcon />}
                  onClick={() => {
                    fields.remove(index);
                  }}
                />
              </Flex>
            ))
          }
        </FieldArray>
      </Flex>
    </Box>
  );
}
