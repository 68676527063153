import React, { useRef } from 'react';
import { Flex } from '../../shared/components';
import ResolvedValueBox from './partials/ResolvedValue';
import RawValue from './partials/RawValue';
import TokenName from './partials/TokenName';
import { AliasRegex } from '../../ft-utils/constants/AliasRegex';
import useResolvedTokenValue from '../../hooks/useResolvedTokenValue';
import useIsInView from '../../hooks/useIsInView';
import { SingleToken } from '../../ft-utils/types/tokens';

export default function GenericToken(props: SingleToken) {
  const { name, type, value } = props;
  const hasAlias = Boolean(typeof value === 'string' && value?.match(AliasRegex));
  const tokenRef = useRef<HTMLDivElement>(null);
  const inView = useIsInView(tokenRef);

  const resolvedValue = (useResolvedTokenValue(value, type, inView) as string) ?? value;

  if (typeof value !== 'string') return null;

  return (
    <Flex css={{ flexDirection: 'column', alignItems: 'flex-start', gap: '$2', overflow: 'hidden' }} ref={tokenRef}>
      <TokenName name={name} />
      {hasAlias && value && <RawValue rawValue={value} />}
      <ResolvedValueBox isAccent={hasAlias} value={resolvedValue} />
    </Flex>
  );
}
