import { MinusIcon, PlusIcon, TokensIcon } from '@radix-ui/react-icons';
import React, { useCallback, useEffect, useState } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { Field, useField, useForm } from 'react-final-form';
import { IconButton } from '@tokens-studio/ui';
import { BoxShadowValues } from '../../../ft-utils/constants/BoxShadowValues';
import { BoxShadowTypes } from '../../../ft-utils/constants/BoxShadowTypes';
import { Box, Flex, Select } from '../../../shared/components';
import ColorInput from './ColorInput';
import GenericInput from './GenericInput';
import TokenIcon from '../../TokenIcon';
import { TokenTypes } from '../../../ft-utils/constants/TokenTypes';
import { AliasRegex } from '../../../ft-utils/constants/AliasRegex';

export default function BoxShadowInput() {
  const valueField = useField('value');

  const {
    input: { value },
  } = valueField;

  const [useReference, setUseReference] = useState(false);

  useEffect(() => {
    if (value) {
      setUseReference(
        typeof value === 'string' || (typeof value === 'string' && Boolean(value.trim().match(AliasRegex)))
      );
    }
  }, [value]);

  const {
    mutators: { push },
  } = useForm();

  const toggleUseReference = useCallback(() => {
    valueField.input.onChange('');
    setUseReference(!useReference);
  }, [useReference, valueField]);

  const getValueInput = (key: string, shadowIndex: number) => {
    switch (key) {
      case BoxShadowValues.COLOR:
        return <ColorInput showLabel={false} valuePath={`value[${shadowIndex}].${key}`} autoFocus />;
      case BoxShadowValues.X:
      case BoxShadowValues.Y:
      case BoxShadowValues.BLUR:
      case BoxShadowValues.SPREAD:
      case BoxShadowValues.BLEND_MODE:
        return (
          <GenericInput
            showLabel={false}
            showIcon={false}
            valuePath={`value[${shadowIndex}].${key}`}
            tokenTypeOrProp={key}
          />
        );

      case 'type': {
        const items = Object.values(BoxShadowTypes).map((val) => ({ value: val, id: val, label: val }));

        return (
          <Field
            name={`value[${shadowIndex}].type`}
            render={({ input }) => <Select {...input} label="Type:" items={items} />}
          />
        );
      }
      default:
        return null;
    }
  };

  const boxShadowPropsInputs = (
    <FieldArray name="value">
      {({ fields }) =>
        fields.map((name, index) => (
          <Box key={name} css={{ marginTop: '$5' }}>
            <Box
              css={{
                marginBottom: '$3',
                fontSize: '$base',
                fontWeight: '$sansMedium',
                color: '$fgDefault',
                userSelect: 'none',
              }}
            >
              {index > 1 ? `Box shadow ${index + 1}` : 'Box shadow values'}
            </Box>
            <Flex css={{ flexDirection: 'column', alignItems: 'stretch' }}>
              <Box css={{ alignSelf: 'flex-end' }}>
                <IconButton
                  variant="invisible"
                  size="medium"
                  tooltip="Remove shadow"
                  icon={<MinusIcon />}
                  onClick={() => fields.remove(index)}
                />
              </Box>
              {Object.values(BoxShadowValues).map((key) => (
                <Box key={key} css={{ display: 'flex', alignItems: 'center', gap: '$5', padding: '$3 0' }}>
                  <TokenIcon propertyKey={key} />
                  <Box css={{ flexGrow: 1 }}>{getValueInput(key, index)}</Box>
                </Box>
              ))}
            </Flex>
          </Box>
        ))
      }
    </FieldArray>
  );

  return (
    <Flex css={{ flexDirection: 'column', alignItems: 'stretch', padding: '$3' }}>
      <Flex css={{ alignSelf: 'flex-end' }}>
        <IconButton
          variant="invisible"
          size="medium"
          tooltip={useReference ? 'Add values' : 'Use reference'}
          icon={<TokensIcon />}
          selected={useReference}
          onClick={toggleUseReference}
        />
        {!useReference && (
          <IconButton
            variant="invisible"
            size="medium"
            tooltip="Add shadow"
            tooltipSide="bottom"
            icon={<PlusIcon />}
            onClick={() => push('value', undefined)}
          />
        )}
      </Flex>
      {useReference ? <GenericInput tokenTypeOrProp={TokenTypes.BOX_SHADOW} /> : boxShadowPropsInputs}
    </Flex>
  );
}
