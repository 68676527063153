import { Box, Stack } from '@tokens-studio/ui';
import { useParams } from 'react-router-dom';
import { useUIState } from '../context/UIState';
import { useTokenSearchParams } from '../hooks/useTokenSearchParams';
import Scroll from '../shared/components/Scroll';
import TokenNav from './Sidebar/TokenNav';
import TokenSetsNav from './Sidebar/TokenSetsNav';
import UserConnectionArea from './UserConnectionArea';

export const SIDEBAR_WIDTH = '286px';

export default function Sidebar() {
  const { selectedToken } = useParams();
  const { isNewToken, isPanelOpen } = useTokenSearchParams();
  const { sidebarCollapsed } = useUIState();

  return (
    <Stack
      direction="column"
      css={{
        width: SIDEBAR_WIDTH,
        background: '$bgDefault',
        flexShrink: 0,
        borderRight: '1px solid $borderMuted',
        marginLeft: sidebarCollapsed ? `-${SIDEBAR_WIDTH}` : 0,
        transition: 'margin-left 0.2s ease-out',
        height: '100vh',
      }}
    >
      <Scroll height="100%">
        <Box css={{ flexGrow: 1, padding: '$1' }}>
          {(selectedToken || isNewToken) && !isPanelOpen ? <TokenNav /> : <TokenSetsNav />}
        </Box>
      </Scroll>
      <UserConnectionArea />
    </Stack>
  );
}
