import { Field } from 'react-final-form';
import { Button, TextInput } from '@tokens-studio/ui';
import { Box, Flex, Label } from '../../shared/components';

interface SignupFormProps {
  onSubmit: () => void;
  loading: boolean;
}

export default function SignupForm({ onSubmit, loading }: SignupFormProps): JSX.Element {
  return (
    <form
      onSubmit={onSubmit}
      style={{
        width: '100%',
      }}
    >
      <Flex
        css={{
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Box
          css={{
            marginBottom: '$5',
            width: '100%',
          }}
        >
          <Field name="email">
            {(inputProps) => (
              <>
                <Box
                  css={{
                    marginBottom: '$3',
                  }}
                >
                  <Label htmlFor="email-input">E-mail</Label>
                </Box>
                <TextInput
                  placeholder="Your email address"
                  type="email"
                  {...inputProps.input}
                  id="email-input"
                  required
                />
              </>
            )}
          </Field>
        </Box>
        <Box
          css={{
            marginBottom: '$5',
            width: '100%',
          }}
        >
          <Field name="password">
            {(inputProps) => (
              <>
                <Box
                  css={{
                    marginBottom: '$3',
                  }}
                >
                  <Label htmlFor="password-input">Password</Label>
                </Box>
                <TextInput
                  placeholder="Your password"
                  type="password"
                  {...inputProps.input}
                  id="password-input"
                  required
                />
              </>
            )}
          </Field>
        </Box>
        <Box css={{ width: '100%' }}>
          <Button variant="primary" type="submit" loading={loading}>
            Sign up
          </Button>
        </Box>
      </Flex>
    </form>
  );
}
