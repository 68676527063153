export const validateTokenSetName = (name: string, existingNames: string[]): { valid: boolean; error?: string } => {
  // Check if the new file name already exists in the list of existing files
  if (existingNames.includes(name)) {
    return { valid: false, error: 'A set with that name already exists' };
  }

  if (name.startsWith('/') || name.endsWith('/')) {
    return {
      valid: false,
      error: 'Invalid set name. Set name cannot start or end with a slash',
    };
  }

  // Check if the new file name conflicts with an existing folder structure
  for (const existingName of existingNames) {
    if (existingName.startsWith(`${name}/`) || name.startsWith(`${existingName}/`)) {
      return {
        valid: false,
        error: 'File name conflicts with existing folder structure',
      };
    }
  }

  return {
    valid: true,
  };
};
