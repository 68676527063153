import { Box } from '@tokens-studio/ui';
import { PropsWithChildren } from 'react';

type SidepanelProps = PropsWithChildren & {
  onClose: () => void;
};

export default function Sidepanel({ children, onClose }: SidepanelProps) {
  return (
    <Box
      css={{ position: 'fixed', background: '$modalBackdrop', top: '0', right: '0', height: '100%', width: '100%' }}
      onClick={onClose}
    >
      <Box
        css={{
          background: '$bgDefault',
          border: '1px solid $borderMuted',
          borderRadius: '12px 0 0 12px',
          boxShadow: '$small',
          position: 'fixed',
          top: '0',
          right: '0',
          height: '100%',
          minWidth: '560px',
          maxWidth: '1000px',
        }}
        onClick={(ev) => {
          ev.stopPropagation();
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
