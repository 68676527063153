import { RowSelectionState } from '@tanstack/react-table';
import React, { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { EditableTableColumns } from '../types/EditableTableColumns';
import { ColumnId } from '../types/table';

export interface SelectedCell {
  colId: ColumnId;
  rowId: string;
}

export interface TokensTableContextType {
  rowSelection: RowSelectionState;
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>;
  bulkEditPopoverVisible: boolean;
  setBulkEditPopoverVisible: (visible: boolean, property?: EditableTableColumns) => void;
  editedProperty: EditableTableColumns | undefined;
  selectedCells: SelectedCell[] | undefined;
  setSelectedCells: React.Dispatch<React.SetStateAction<SelectedCell[] | undefined>>;
  isEditMode: boolean;
  setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  bulkSelectionInitialRowId: string;
  setBulkSelectionInitialRowId: React.Dispatch<React.SetStateAction<string>>;
}

const TokensTableContext = createContext<TokensTableContextType | undefined>(undefined);

function TokensTableContextProvider({ children }: { children: ReactNode }) {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [editedProperty, setEditedProperty] = useState<EditableTableColumns>();
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedCells, setSelectedCells] = useState<SelectedCell[]>();
  const [bulkSelectionInitialRowId, setBulkSelectionInitialRowId] = useState<string>('');

  const setBulkEditPopoverVisible = useCallback((visible: boolean, property?: EditableTableColumns) => {
    setEditedProperty(property);
    setPopoverVisible(visible);
  }, []);

  const providerValue = useMemo(
    () => ({
      rowSelection,
      setRowSelection,
      bulkEditPopoverVisible: popoverVisible,
      setBulkEditPopoverVisible,
      editedProperty,
      isEditMode,
      setIsEditMode,
      selectedCells,
      setSelectedCells,
      bulkSelectionInitialRowId,
      setBulkSelectionInitialRowId,
    }),
    [
      rowSelection,
      popoverVisible,
      setBulkEditPopoverVisible,
      editedProperty,
      selectedCells,
      isEditMode,
      setIsEditMode,
      bulkSelectionInitialRowId,
      setBulkSelectionInitialRowId,
    ]
  );
  return <TokensTableContext.Provider value={providerValue}>{children}</TokensTableContext.Provider>;
}

function useTokensTableState() {
  const context = useContext(TokensTableContext);
  if (context === undefined) {
    throw new Error('useTokensTableState must be used within a TokensTableContextProvider');
  }
  return context;
}

export { TokensTableContextProvider, useTokensTableState };
