import { Box, Textarea } from '@tokens-studio/ui';
import { Field } from 'react-final-form';
import React from 'react';
import { Flex } from '../../../shared/components';

export default function DescriptionForm() {
  return (
    <Field
      name="description"
      render={({ input }) => (
        <Flex
          css={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '$4',
          }}
        >
          <Box css={{ fontSize: '$small', fontWeight: '$sansBold' }}>Description</Box>
          <Textarea {...input} rows={5} placeholder="Enter new description" />
        </Flex>
      )}
    />
  );
}
