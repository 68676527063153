import React from 'react';
import { Select as SelectInternal } from '@tokens-studio/ui';

export type SelectItemValue = {
  value: string;
  label: string;
  id: string | number;
};

interface SelectProps {
  items: SelectItemValue[];
  onChange?: (value: any) => void;
  label?: string;
  value?: string;
  placeHolder?: string;
}

const Select = ({ items, onChange, label, value, placeHolder = 'Select value' }: SelectProps) => {
  const selectedValue = items.find((item) => item.value === value)?.label;

  return (
    items && (
      <SelectInternal onValueChange={onChange} value={value}>
        <SelectInternal.Trigger label={label} value={selectedValue || placeHolder} />
        <SelectInternal.Content>
          <>
            {items.map((item) => (
              <SelectInternal.Item value={item.value} key={item.id}>
                {item.label}
              </SelectInternal.Item>
            ))}
          </>
        </SelectInternal.Content>
      </SelectInternal>
    )
  );
};

export default Select;
