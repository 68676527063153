import { Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { Heading, Stack } from '@tokens-studio/ui';
import { Box, Flex } from '../shared/components';
import { useAuth } from '../context/AuthContext';
import { NotificationTypes, useNotifications } from '../context/NotificationContext';
import ResetPasswordForm from '../components/Auth/ResetPasswordForm';

export default function ResetPassword() {
  const { updatePassword, authError, loggedUser, fetchingUser } = useAuth();
  const navigate = useNavigate();
  const { addNotification } = useNotifications();

  useEffect(() => {
    if (!fetchingUser && !loggedUser) {
      addNotification(NotificationTypes.ERORR, 'Something went wrong!');
      navigate('/login');
    }
  }, [addNotification, fetchingUser, loggedUser, navigate]);

  const onResetPasswordSubmit = async (values: { password: string }) => {
    const { password } = values;

    if (password) {
      const success = await updatePassword(password);

      if (success) {
        addNotification(NotificationTypes.SUCCESS, 'Password was successfully updated!');
        navigate('/');
      }
    }
  };

  const error = authError && (
    <Box
      css={{
        background: '$dangerBg',
        border: '1px solid $dangerBorder',
        fontSize: '$xsmall',
        borderRadius: '$medium',
        color: '$dangerFg',
        padding: '$5',
      }}
    >
      {authError}
    </Box>
  );

  return (
    <Flex
      css={{
        height: '100vh',
        background: '$bgCanvas',
      }}
    >
      <Stack
        direction="column"
        align="start"
        gap={5}
        css={{
          background: '$bgSurface',
          borderRadius: '$medium',
          border: '1px solid $borderSubtle',
          padding: '48px',
          maxWidth: '500px',
          width: '100%',
        }}
      >
        <Stack
          direction="column"
          align="start"
          gap={3}
        >
          <Heading bold size="large">Reset password</Heading>
        </Stack>
        {error}
        <Form
          onSubmit={onResetPasswordSubmit}
          render={({ handleSubmit }) => <ResetPasswordForm onSubmit={handleSubmit} loading={false} />}
        />
      </Stack>
    </Flex>
  );
}
