import { Row } from '@tanstack/react-table';
import { TableTokenItem } from '../ft-utils/types/TableTokenItem';

export default (rows: Row<TableTokenItem>[]) => {
  const subRows: Row<TableTokenItem>[] = [];

  const iterateRows = (rowsArray: Row<TableTokenItem>[]) => {
    rowsArray.forEach((row) => {
      if (row.subRows && row.subRows.length > 0) {
        iterateRows(row.subRows);
      } else {
        subRows.push(row);
      }
    });
  };

  iterateRows(rows);

  return subRows;
};
