import { useMemo } from 'react';
import { useUIState } from '../context/UIState';
import useSelectedSetTokens from './useSelectedSetTokens';

export default () => {
  const { nestingLevel } = useUIState();
  const tokens = useSelectedSetTokens();

  return useMemo(() => {
    if (tokens) {
      return tokens.map((token) => {
        const nameParts = token.name.split('.');
        const path = nameParts.slice(0, nestingLevel).join('.');
        return { ...token, path };
      });
    }
    return [];
  }, [tokens, nestingLevel]);
};
