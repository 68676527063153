import { SingleToken } from '../ft-utils/types/tokens';
import { TokenSetStatus } from '../ft-utils/constants/TokenSetStatus';
import { UsedTokenSetsMap } from '../ft-utils/types';

export type Token = SingleToken;

export type TokenSets = Record<string, Token[]>;

export enum Clients {
  PLUGIN = 'plugin',
  SECOND_SCREEN = 'second-screen',
}

export interface TokenData {
  id: string | number | null;
  owner_email?: string;
  created_at?: string;
  synced_data: {
    sets: TokenSets | null;
    themes: Theme[];
    usedTokenSets: UsedTokenSetsMap;
    activeTheme: Record<string, string>;
  };
  last_updated_by: Clients;
}

export interface Theme {
  id: string;
  figmaStyleReferences: Record<string, any>;
  group?: string;
  name: string;
  selectedTokenSets: Record<string, TokenSetStatus>;
}
