import { TokenData } from '../context/types';
import { TokenSetStatus } from '../ft-utils/constants/TokenSetStatus';
import { supabase } from '../supabase';

export default async function updateUsedTokenSets(tokenState: TokenData, tokenSet: string, newState: TokenSetStatus) {
  const newSyncedData = {
    themes: tokenState.synced_data.themes,
    sets: tokenState.synced_data.sets,
    usedTokenSets: {
      ...tokenState.synced_data.usedTokenSets,
      [tokenSet]: newState,
    },
    activeTheme: {},
  } as TokenData['synced_data'];

  try {
    await supabase
      .from('tokens')
      .update({ synced_data: JSON.stringify(newSyncedData), last_updated_by: 'second-screen' })
      .eq('id', tokenState.id);
  } catch (error) {
    console.log(error);
  }
}
