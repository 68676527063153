import { useParams } from 'react-router-dom';
import { NotificationTypes, useNotifications } from '../context/NotificationContext';
import { useSync } from '../context/SyncContext';
import { useTokens } from '../context/TokensContext';
import { TokenTypes } from '../ft-utils/constants/TokenTypes';
import { SingleToken } from '../ft-utils/types/tokens';
import { Mixpanel } from '../Mixpanel';
import { supabase } from '../supabase';
import { computeCompositionInputForSave } from '../utils/computeCompositionInputForSave';
import { updateTokenPayloadToSingleToken } from './useSaveToken';
import { trimStrings } from '../utils/trimString';

export function useUpdateTokens() {
  const { selectedTokenSet } = useParams();
  const { tokenData, setTokenData } = useTokens();
  const { addNotification } = useNotifications();
  const { pluginIsConnected, allowEdits } = useSync();

  const updateTokens = async (tokenIds: string[], tokenValues: SingleToken) => {
    const safeTokenValues = trimStrings(tokenValues) as SingleToken;

    if (!pluginIsConnected && !allowEdits) {
      addNotification(
        NotificationTypes.ERORR,
        'Cannot edit token set. Please make sure Tokens Studio plugin is opened and sync is turned on'
      );
      return;
    }

    const newData = structuredClone(tokenData);
    if (selectedTokenSet && newData && newData.synced_data.sets) {
      const newSet = newData.synced_data.sets[selectedTokenSet].map((token) => {
        if (tokenIds.includes(token.name)) {
          const values = structuredClone(safeTokenValues);
          if (token.type === TokenTypes.COMPOSITION && Array.isArray(values.value)) {
            // @ts-ignore
            values.value = computeCompositionInputForSave(values.value);
          }

          const newTokenValues = { ...token, ...values } as SingleToken;

          return updateTokenPayloadToSingleToken(newTokenValues);
        }

        return token;
      });

      // @ts-ignore
      newData.synced_data.sets[selectedTokenSet] = newSet;

      setTokenData(newData);

      const { error, status } = await supabase
        .from('tokens')
        .update({
          synced_data: JSON.stringify(newData.synced_data),
          last_updated_by: 'second-screen',
        })
        .eq('id', newData.id);

      Mixpanel.track('Bulk edit tokens');

      if (error) {
        addNotification(NotificationTypes.ERORR, 'Error updating tokens');
      }

      if (status === 204) {
        addNotification(
          NotificationTypes.SUCCESS,
          `Succesfully updated ${tokenIds.length} ${tokenIds.length > 1 ? 'tokens' : 'token'} .`
        );
      }
    }
  };

  return { updateTokens };
}
