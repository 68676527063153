import React from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { styled, keyframes } from '../../stitches.config';

const StyledHeader = styled(Accordion.Header, {
  all: 'unset',
  display: 'flex',
});

const StyledTrigger = styled(Accordion.Trigger, {
  all: 'unset',
  backgroundColor: 'transparent',
  borderRadius: '$medium',
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: '$small',
  fontWeight: '$sansBold',
  color: '$fgDefault',
  cursor: 'pointer',
  padding: '$3 $5',
  gap: '$4',

  '&:hover': {
    backgroundColor: '$bgSubtle',
  },
  '&:focus-visible': {
    boxShadow: '$focus',
  },
});

const StyledChevron = styled(ChevronDownIcon, {
  color: '$fgSubtle',
  transition: 'transform 300ms cubic-bezier(0.87, 0, 0.13, 1)',
  '[data-state=open] &': { transform: 'rotate(180deg)' },
});

const slideDown = keyframes({
  from: { height: 0 },
  to: { height: 'var(--radix-accordion-content-height)' },
});

const slideUp = keyframes({
  from: { height: 'var(--radix-accordion-content-height)' },
  to: { height: 0 },
});

const StyledContent = styled(Accordion.Content, {
  '&[data-state="open"]': {
    animation: `${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
  '&[data-state="closed"]': {
    animation: `${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
});

export const AccordionRoot = Accordion.Root;

export const AccordionItem = styled(Accordion.Item, {
  overflow: 'hidden',
  padding: '$1',
  marginTop: 1,

  '&:first-child': {
    marginTop: 0,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },

  '&:last-child': {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },

  '&:focus-within': {
    position: 'relative',
  },
});

export const AccordionTrigger = React.forwardRef(
  ({ children, level, ...props }: { children: React.ReactNode, level?: number }, forwardedRef: any) => (
    <StyledHeader>
      <StyledTrigger {...props} ref={forwardedRef} css={{ paddingLeft: level && `${level * 12}px` }}>
        {children}
        <StyledChevron aria-hidden />
      </StyledTrigger>
    </StyledHeader>
  )
);

export const AccordionContent = React.forwardRef(
  ({ children, ...props }: { children: React.ReactNode }, forwardedRef: any) => (
    <StyledContent {...props} ref={forwardedRef}>
      {children}
    </StyledContent>
  )
);
