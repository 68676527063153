import React, { useRef } from 'react';
import { SingleBorderToken } from '../../ft-utils/types/tokens/SingleBorderToken';
import useIsInView from '../../hooks/useIsInView';
import useResolvedTokenValue from '../../hooks/useResolvedTokenValue';
import { Box, Flex } from '../../shared/components';
import RawValueObj from './partials/RawValueObj';
import ResolvedValueBox from './partials/ResolvedValue';
import TokenName from './partials/TokenName';
import TokenPreview from './partials/TokenPreview';

export default function BorderToken(props: SingleBorderToken) {
  const { name, value, type } = props;

  const tokenRef = useRef<HTMLDivElement>(null);
  const inView = useIsInView<HTMLDivElement>(tokenRef);

  const resolvedTokenValue = useResolvedTokenValue(value, type, inView) as SingleBorderToken['value'];

  function getBorderValue() {
    if (!resolvedTokenValue) return '';

    return `${String(resolvedTokenValue.width).replace(/[^0-9]/g, '')}px ${resolvedTokenValue.style} ${
      resolvedTokenValue.color
    }`;
  }

  return (
    <>
      <TokenPreview>
        <Box
          ref={tokenRef}
          css={{
            width: '32px',
            height: '32px',
            border: getBorderValue(),
            background: '$accentBg',
            marginBottom: '$5',
            borderRadius: '$medium',
          }}
        />
      </TokenPreview>
      <Flex css={{ flexDirection: 'column', alignItems: 'flex-start', gap: '$2', overflow: 'hidden' }}>
        <TokenName name={name} />
        <RawValueObj {...props} />
        <ResolvedValueBox value={getBorderValue()} isAccent />
      </Flex>
    </>
  );
}
